import { useMemo, useState } from "react";
import { VStack } from "@chakra-ui/react";
import InsightsChartVideo from "./InsightsChartVideo";
import { RivrSearch } from "../../../models/rivrSearch";
import { durationToSeconds } from "../../../utils/time";
import "./InsightsChart.css";

// import InsightsChartMoments from "./InsightsChartMoments";
// import colorPalette from "../../../utils/colorPalette";

type InsightsChartsProps = {
  searches: RivrSearch[];
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: any;
  isMoments?: boolean;
}

const InsightsCharts: React.FC<InsightsChartsProps> = ({ searches }) => {
  const [barVisibility, setBarVisibility] = useState({
    videos: true,
    channels: true,
    hours: true,
    views: true,
    hover: null as string | null,
  });

  const formatDateLabel = (date: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    return formattedDate.replace(/(\w+)\s/, "$1. ");
  };

  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, isMoments }) => {
    if (active && payload && payload.length) {
      let displayPayload = payload;

      if (isMoments) {
        displayPayload = payload
          .map((item: any) => ({
            name: item.name,
            value: item.value,
            color: item.color,
          }))
          .filter(
            (item: { name: string; value: number; color: string }) =>
              typeof item.value === "number" && item.value > 0
          )
          .sort((a, b) => b.value - a.value)
          .slice(0, 10);
      } else {
        displayPayload = payload.filter((data: any) => {
          const value = Math.ceil(data.value ?? 0);
          return value > 0;
        });
      }

      return (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            border: "1px solid #2D3748",
            padding: "8px",
            borderRadius: "4px",
            color: "#ffffff",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
            width: "fit-content",
          }}
        >
          <p>{formatDateLabel(label)}</p>
          <div>
            {displayPayload.map((dataItem: any, index: number) => (
              <p key={index} style={{ color: dataItem.color }}>
                {dataItem.name}: {dataItem.value.toLocaleString()}
              </p>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  const chartData = useMemo(() => {
    if (searches.length === 0) return [];

    const videoStats = searches.reduce(
      (
        acc: {
          [key: string]: {
            videos: number;
            hours: number;
            views: number;
            channels: Set<string>;
          };
        },
        search
      ) => {
        const publishedDate = search.metadata?.publishedAt
          ? new Date(search.metadata.publishedAt)
          : null;
        if (publishedDate) {
          const localDate = publishedDate.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });

          if (!acc[localDate]) {
            acc[localDate] = {
              videos: 0,
              hours: 0,
              views: 0,
              channels: new Set<string>(),
            };
          }
          acc[localDate].videos += 1;
          acc[localDate].hours += Math.ceil(durationToSeconds(search.videoDuration) / 3600);
          acc[localDate].views += search.metadata?.viewCount || 0;
          if (search.metadata?.userName) {
            acc[localDate].channels.add(search.metadata.userName);
          }
        }
        return acc;
      },
      {}
    );

    const sortedData = Object.keys(videoStats)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      .map((date) => ({
        date,
        videos: videoStats[date].videos,
        hours: videoStats[date].hours,
        views: videoStats[date].views,
        channels: videoStats[date].channels.size,
      }));

    return sortedData;
  }, [searches]);

  /* const channelData = useMemo(() => {
    if (searches.length === 0) return [];

    const allChannels = new Set<string>();

    const channelStats: {
      [key: string]: {
        [channelName: string]: number;
      };
    } = searches.reduce((acc, search) => {
      const publishedDate = search.metadata?.publishedAt
        ? new Date(search.metadata.publishedAt)
        : null;

      if (publishedDate) {
        const localDate = publishedDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        if (!acc[localDate]) {
          acc[localDate] = {};
        }

        if (search.metadata?.userName) {
          const channelName = search.metadata.userName;
          allChannels.add(channelName);

          if (!acc[localDate][channelName]) {
            acc[localDate][channelName] = 0;
          }

          acc[localDate][channelName] += search.userMoments.length;
        }
      }
      return acc;
    }, {} as { [key: string]: { [channelName: string]: number } });

    return Object.keys(channelStats)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      .map((date) => {
        const dayData = channelStats[date];

        allChannels.forEach((channel) => {
          if (!dayData[channel]) {
            dayData[channel] = 0;
          }
        });

        return {
          date,
          ...dayData,
        };
      });
  }, [searches]); */

  return (
    <VStack w={"100%"} h={"100%"} align={"start"} spacing={6} className="stat-video-wrapper">
      <InsightsChartVideo
        syncId={"insightsSync"}
        data={chartData}
        formatDateLabel={formatDateLabel}
        CustomTooltip={CustomTooltip}
        barVisibility={barVisibility}
        setBarVisibility={setBarVisibility}
      />
      {/*       <InsightsChartMoments
        syncId={"insightsSync"}
        data={channelData}
        formatDateLabel={formatDateLabel}
        colorPalette={colorPalette}
        CustomTooltip={CustomTooltip}
      /> */}
    </VStack>
  );
};

export default InsightsCharts;
