import captainDylan from "./captain-dylan.png";
import danielTheDemon from "./danielthedemon.png";
import kaijuKittens from "./kaiju-kittens.png";
import deejay from "./deejay.png";
import mikeTheBard from "./mike-the-bard.png";
import nateSheriff from "./nate-sherriff.png";
import pt from "./pt.png";
import rightURKen from "./right-u-r-ken.png";
import thormungandr from "./thormungandr.png";

const creatorImages = [
  captainDylan,
  danielTheDemon,
  kaijuKittens,
  deejay,
  mikeTheBard,
  nateSheriff,
  pt,
  rightURKen,
  thormungandr,
];

export default creatorImages;
