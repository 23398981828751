import { gql } from "@apollo/client";

// Queries
export const SpeakerMentionsQuery = gql`
  query SpeakerMentions($query: String!, $campaign_id: uuid!) {
    speakerMentions(query: $query, campaign_id: $campaign_id) {
      rank
      search {
        id
        video_title
        url
        channel: metadata(path: "$.channel")
        user_name: metadata(path: "$.user_name")
        upload_date: metadata(path: "$.upload_date")
        published_at: metadata(path: "$.published_at")
      }
      start_time
      line
      sentiment
    }
  }
`;
