import { Box, Text } from "@chakra-ui/react";
import { Position, Rnd } from "react-rnd";
import { ContentBox, Dimensions } from "../../models/action";
import { absoluteToRelative, changeRatio, relativeToAbsolute } from "../../utils/contentBox";
import { AlignmentControls } from "./AlignmentControls";

type Props = {
  contentBox: ContentBox;
  setContentBox: (box: ContentBox) => void;
  previewContentBox: ContentBox;
  setPreviewContentBox: (box: ContentBox) => void;
  sourceDims: Dimensions;
  previewDims: Dimensions;
  color: string;
  title: string;
  locked: boolean;
};

export const SourceSelector = (props: Props) => {
  const {
    contentBox,
    setContentBox,
    previewContentBox,
    setPreviewContentBox,
    sourceDims,
    previewDims,
    color,
    title,
    locked,
  } = props;

  const handleContentResize = (dim: Dimensions, pos: Position) => {
    setContentBox(absoluteToRelative({ ...dim, ...pos }, sourceDims));
    setPreviewContentBox(changeRatio(previewContentBox, dim.width / dim.height, previewDims));
  };

  const absoluteContentBox = relativeToAbsolute(contentBox, sourceDims);
  const handle = <Box bg={color} w="10px" h="10px" m="5px"></Box>;
  const resizeHandleComponent = locked
    ? {
        bottomRight: handle,
      }
    : {
        topLeft: handle,
        topRight: handle,
        bottomLeft: handle,
        bottomRight: handle,
      };

  return (
    <Rnd
      key={sourceDims.height}
      bounds="parent"
      lockAspectRatio={locked}
      size={absoluteContentBox}
      position={absoluteContentBox}
      onDragStop={(e, d) =>
        setContentBox({
          ...contentBox,
          x: d.x / sourceDims.width,
          y: d.y / sourceDims.height,
        })
      }
      onResizeStop={(e, direction, ref, delta, position) =>
        handleContentResize({ width: ref.offsetWidth, height: ref.offsetHeight }, position)
      }
      style={{
        border: `2px dashed ${color}`,
      }}
      resizeHandleComponent={resizeHandleComponent}
      className={"selector"}
    >
      <AlignmentControls contentBox={contentBox} setContentBox={setContentBox} />
      <Text
        pos="absolute"
        top="-25px"
        left="50%"
        transform="translateX(-50%)"
        color="gray.900"
        bg={color}
        px={2}
        textTransform={"uppercase"}
        fontWeight={"bold"}
        borderRadius={"md"}
        fontSize={"xs"}
      >
        {title}
      </Text>
    </Rnd>
  );
};
