import { Box } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ChartLine, Endocrine } from "@icon-park/react";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import { Text, Link, UnorderedList, ListItem } from "../../components/KnowledgeBase/KbElements";

const KbResults: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"results"} py={2}>
          Results
        </LinkedHeading>

        <LinkedHeading as={"h2"} id={"results-page"} pt={6} pb={4}>
          Results page
        </LinkedHeading>
        <Text>The Results page is the core of your Rivr experience, featuring:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>Media player: Plays back your video.</ListItem>
          <ListItem>
            <Link
              onClick={() =>
                navigate(
                  `${RivrLocations.KnowledgeBase}/${KBSubPages.Timeline}?${searchParams.toString()}`
                )
              }
            >
              The Timeline
            </Link>
            : Visualizes your video with Signals and lets you mark Moments.
          </ListItem>
          <ListItem>
            <Link
              onClick={() =>
                navigate(
                  `${RivrLocations.KnowledgeBase}/${
                    KBSubPages.ChatSpeech
                  }?${searchParams.toString()}`
                )
              }
            >
              Chat Search
            </Link>
            : Quickly find any message sent through chat.
          </ListItem>
          <ListItem>
            <Link
              onClick={() =>
                navigate(
                  `${RivrLocations.KnowledgeBase}/${
                    KBSubPages.ChatSpeech
                  }?${searchParams.toString()}`
                )
              }
            >
              Speech Search
            </Link>
            : Search any words spoken in the video by you or others.
          </ListItem>
          <ListItem>
            <Link
              onClick={() =>
                navigate(
                  `${RivrLocations.KnowledgeBase}/${KBSubPages.Moments}?${searchParams.toString()}`
                )
              }
            >
              My Moments
            </Link>
            : Where you can edit, download, or reformat your Moments.
          </ListItem>
          <ListItem>
            <Link
              onClick={() =>
                navigate(
                  `${RivrLocations.KnowledgeBase}/${
                    KBSubPages.Moments
                  }?${searchParams.toString()}#results-moments-recommended`
                )
              }
            >
              Recommended Moments
            </Link>
            : Discover key Moments we suggest based on unique patterns in Signal data.
          </ListItem>
        </UnorderedList>

        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 12, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "The Timeline",
              description: "How to navigate and understand Signals",
              icon: ChartLine,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Timeline}`,
            },
            {
              title: "Moments",
              description: "How to analyze your VODs for your best Moments",
              icon: Endocrine,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Moments}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbResults;
