import {
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Close } from "@icon-park/react";
import { useState } from "react";
import { checkUrl } from "../../models/urls";
import Cookies from "js-cookie";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { SearchMutation } from "../../api/actions";
import { useMutation } from "@apollo/client";
import { CampaignURLParams } from "../../models/navigation";
import { useSearchParams } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

interface Props {
  selectedCampaignId: string;
  fetchSearches: any;
  onClose: () => void;
}

export const CampaignSearchBar = ({ selectedCampaignId, fetchSearches, onClose }: Props) => {
  const toast = useToast();

  const { getFlag } = useKindeAuth();
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const liveTwitchUrlFlag = isAdmin || (getFlag("live-twitch-url", false).value as boolean);
  const youtubeUrlFlag = isAdmin || (getFlag("youtube_url", false).value as boolean);

  const [input, setInput] = useState("");
  const [inputInvalid, setInputInvalid] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const isButtonDisabled = input.trim() === "";

  const [useSpeech, setUseSpeech] = useState(true);
  const [useSpeechSentiment, setUseSpeechSentiment] = useState(true);

  const flags = new Map([
    ["live-twitch-url", liveTwitchUrlFlag],
    ["youtube-url", youtubeUrlFlag],
  ]);
  const [searchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";

  const [searchInsertAPI] = useMutation(SearchMutation, {
    onCompleted() {
      setInput("");
      fetchSearches({ campaign: selectedCampaign });
      setIsButtonLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsButtonLoading(false);
    },
  });

  const fixUrl = (url: string) => {
    const newUrl = url.trim();
    return newUrl.startsWith("www.")
      ? "https://" + newUrl
      : newUrl.startsWith("twitch.tv/") || newUrl.startsWith("youtube.com/")
      ? "https://www." + newUrl
      : newUrl;
  };

  const splitInput = (inputStr: string) => {
    return inputStr.split(/[\s,]+/).filter((url) => url.length > 0);
  };

  const onSubmit = () => {
    setIsButtonLoading(true);
    const urls = splitInput(input);
    urls.forEach((url) => {
      const parsedUrl = checkUrl(fixUrl(url), flags);
      if (typeof parsedUrl === "string") {
        toast({
          title: "Error.",
          description: parsedUrl,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsButtonLoading(false);
        setInputInvalid(true);
        return;
      }

      setInputInvalid(false);
      searchInsertAPI({
        variables: { searchRequest: { url: parsedUrl.cleanUrl, campaignId: selectedCampaignId } },
      });
    });
    onClose();
    handleAmplitudeTrack("Campaign Videos Submitted", {
      Count: urls.length,
      URLs: urls.join(", "),
    });
  };

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <VStack w={"100%"} alignItems={"flex-start"} spacing={0} my={2}>
      <VStack justifyContent={"center"} w={"100%"}>
        <VStack w={"100%"}>
          <InputGroup size={"sm"}>
            <FormControl isInvalid={inputInvalid}>
              <Input
                className={"amp-unmask"}
                as={"textarea"}
                variant={"filled"}
                size={"sm"}
                borderRadius={"md"}
                type={"text"}
                value={input}
                placeholder={"https://twitch.tv/videos/12345, https://twitch.tv/videos/67890"}
                resize={"vertical"}
                minH={16}
                maxH={80}
                overflowY={"auto"}
                lineHeight={2}
                w={"100% !important"}
                style={{ scrollbarWidth: "thin" }}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    onSubmit();
                  }
                }}
              />
            </FormControl>
          </InputGroup>
          <Button
            onClick={onSubmit}
            colorScheme={"green"}
            isLoading={isButtonLoading}
            isDisabled={isButtonDisabled}
            size={"lg"}
            w={"full"}
          >
            Go
          </Button>
        </VStack>
        {isAdmin && (
          <HStack spacing={4} pt={2}>
            <HStack>
              <Text>ASR</Text>
              <Switch
                isChecked={useSpeech}
                onChange={(event) => setUseSpeech(event.target.checked)}
              />
            </HStack>
            <HStack>
              <Text>ASR Sentiment</Text>
              <Switch
                isChecked={useSpeechSentiment}
                onChange={(event) => setUseSpeechSentiment(event.target.checked)}
              />
            </HStack>
          </HStack>
        )}
      </VStack>
    </VStack>
  );
};
