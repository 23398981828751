import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useMemo } from "react";
import MentionsPerDay from "./MentionsPerDay";
import MentionsPerChannel from "./MentionsPerChannel";
import MentionsSummary from "./MentionsSummary";
import { RivrMention } from "../../../../../models/mention";
import { RivrSearch } from "../../../../../models/rivrSearch";
import { WarningIcon } from "@chakra-ui/icons";
import { Loading } from "../../../../Loading/Loading";
import * as amplitude from "@amplitude/analytics-browser";

interface MentionsReportProps {
  mentions: RivrMention[];
  searches: RivrSearch[];
  mentionsLoading: boolean;
}

const MentionsReport: React.FC<MentionsReportProps> = ({ mentions, searches, mentionsLoading }) => {
  // Calculate unique channels
  const uniqueChannels = useMemo(() => {
    const channelSet = new Set(mentions.map((mention) => mention.channel));
    return channelSet.size;
  }, [mentions]);

  // Calculate unique videos
  const uniqueVideos = useMemo(() => {
    const videoSet = new Set(mentions.map((mention) => mention.searchId));
    return videoSet.size;
  }, [mentions]);

  const totalViews = useMemo(() => {
    // Create a set of search IDs from the mentions
    const mentionedSearchIds = new Set<number>(
      mentions
        .map((mention) => mention.searchId)
        .filter((id): id is number => id !== undefined && id !== null)
    );

    // Sum up the view counts from the searches for the mentioned videos
    const total = searches.reduce((sum, search) => {
      if (mentionedSearchIds.has(search.id)) {
        return sum + (search.metadata?.viewCount || 0);
      }
      return sum;
    }, 0);

    return total.toLocaleString();
  }, [mentions, searches]);

  const mentionsMax = 2000;

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <Box w={"100%"} pt={2}>
      <Accordion allowToggle w={"100%"} bg={"whiteAlpha.100"} borderRadius={"md"}>
        <AccordionItem
          borderRadius={"md"}
          borderWidth={"0 !important"}
          isDisabled={mentions.length === 0}
        >
          <AccordionButton
            _hover={{ bg: "whiteAlpha.200" }}
            borderRadius={"md"}
            px={3}
            py={1.5}
            _expanded={{ borderBottomRadius: 0 }}
            onClick={() => handleAmplitudeTrack("Mentions Reporting Toggled")}
          >
            <HStack
              flex={"1"}
              textAlign={"left"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <HStack>
                <Text fontSize={"sm"}>Mentions Reporting</Text>
                <Badge colorScheme={"blue"} letterSpacing={1}>
                  Beta
                </Badge>
              </HStack>
              <HStack pr={3} spacing={6}>
                <HStack spacing={1}>
                  <Text
                    fontSize={"xs"}
                    color={"gray.300"}
                    fontWeight={"semibold"}
                    textTransform={"uppercase"}
                    letterSpacing={"wider"}
                  >
                    Mentions:
                  </Text>
                  <Text fontSize={"sm"}>{mentions.length.toLocaleString()}</Text>
                  {mentions.length === mentionsMax && (
                    <Tooltip
                      label={`Showing the top ${mentionsMax.toLocaleString()} mentions to prioritize relevancy.`}
                      maxW={"fit-content"}
                    >
                      <WarningIcon color={"yellow.300"} boxSize={4} />
                    </Tooltip>
                  )}
                </HStack>
                <HStack spacing={1}>
                  <Text
                    fontSize={"xs"}
                    color={"gray.300"}
                    fontWeight={"semibold"}
                    textTransform={"uppercase"}
                    letterSpacing={"wider"}
                  >
                    Channels:
                  </Text>
                  <Text fontSize={"sm"}>{uniqueChannels.toLocaleString()}</Text>
                </HStack>
                <HStack spacing={1}>
                  <Text
                    fontSize={"xs"}
                    color={"gray.300"}
                    fontWeight={"semibold"}
                    textTransform={"uppercase"}
                    letterSpacing={"wider"}
                  >
                    Videos:
                  </Text>
                  <Text fontSize={"sm"}>{uniqueVideos.toLocaleString()}</Text>
                </HStack>

                <Tooltip
                  label={"Number of views for videos with mentions"}
                  placement={"bottom-end"}
                >
                  <HStack spacing={1}>
                    <Text
                      fontSize={"xs"}
                      color={"gray.300"}
                      fontWeight={"semibold"}
                      textTransform={"uppercase"}
                      letterSpacing={"wider"}
                    >
                      Views:
                    </Text>
                    <Text fontSize={"sm"}>{totalViews}</Text>
                  </HStack>
                </Tooltip>
              </HStack>
            </HStack>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel
            pb={4}
            pt={0}
            px={0}
            minH={"fit-content"}
            overflowY={"auto"}
            sx={{ scrollbarWidth: "thin" }}
          >
            <Tabs isFitted w={"100%"}>
              <TabList>
                <Tab
                  _hover={{ bg: "whiteAlpha.100" }}
                  _selected={{
                    bg: "whiteAlpha.100",
                    color: "blue.300",
                    borderBottomColor: "blue.300",
                  }}
                  onClick={() =>
                    handleAmplitudeTrack("Mentions Reporting Tab Clicked", { Tab: "Summary" })
                  }
                >
                  Summary
                </Tab>
                <Tab
                  _hover={{ bg: "whiteAlpha.100" }}
                  _selected={{
                    bg: "whiteAlpha.100",
                    color: "blue.300",
                    borderBottomColor: "blue.300",
                  }}
                  onClick={() =>
                    handleAmplitudeTrack("Mentions Reporting Tab Clicked", { Tab: "Per Day" })
                  }
                >
                  Per Day
                </Tab>
                <Tab
                  _hover={{ bg: "whiteAlpha.100" }}
                  _selected={{
                    bg: "whiteAlpha.100",
                    color: "blue.300",
                    borderBottomColor: "blue.300",
                  }}
                  onClick={() =>
                    handleAmplitudeTrack("Mentions Reporting Tab Clicked", { Tab: "Per Channel" })
                  }
                >
                  Per Channel
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel pb={0}>
                  {mentionsLoading ? (
                    <Loading minHeight={96} />
                  ) : (
                    <MentionsSummary mentions={mentions} />
                  )}
                </TabPanel>
                <TabPanel pb={0}>
                  {mentionsLoading ? (
                    <Loading minHeight={96} />
                  ) : (
                    <MentionsPerDay mentions={mentions} />
                  )}
                </TabPanel>
                <TabPanel pb={0}>
                  {mentionsLoading ? (
                    <Loading minHeight={96} />
                  ) : (
                    <MentionsPerChannel mentions={mentions} />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default MentionsReport;
