import { Center, VStack, HStack, Image, Text, Heading, Button } from "@chakra-ui/react";
import RivrBot from "../../assets/rivr-bot.png";

export const HasuraError = () => {
  return (
    <Center w="100vw" h="100vh" p={6}>
      <VStack spacing={8} justifyContent={"center"}>
        <HStack spacing={3} flexWrap={"nowrap"} h={28} justifyContent={"center"}>
          <Text color="rivr.red" fontSize={"9xl"} fontWeight={"extrabold"}>
            5
          </Text>
          <Image src={RivrBot} height="7rem" borderRadius={"full"} />
          <Text color="rivr.red" fontSize={"9xl"} fontWeight={"extrabold"}>
            0
          </Text>
        </HStack>
        <VStack>
          <Heading as="h1">Oops! Something went wrong</Heading>
          <Text color="gray.300">Please try again later</Text>
        </VStack>
        <Button colorScheme="gray" size="lg" as="a" href="/">
          Reload
        </Button>
      </VStack>
    </Center>
  );
};
