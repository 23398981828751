import { getLanguageDisplayName, RivrLanguage } from "./rivrLanguage";

export type RivrChannel = {
  name: string;
  languages: RivrLanguage[];
  searchCount: number;
};

export function channelsFromSearches(searches: any): RivrChannel[] {
  const channels: RivrChannel[] = [];

  for (let i = 0; i < searches.length; i++) {
    const name = searches[i].channel || searches[i].user_name || "";
    if (name === "") continue;
    const channel = channels.find((c) => c.name === name);
    if (!channel) {
      channels.push({ name: name, languages: [], searchCount: 0 });
      i--;
      continue;
    }
    if (searches[i].language === null && !channel.languages.some((l) => l.code === "N/A")) {
      channel.languages.push({ name: "none", code: "N/A" });
    } else if (!channel.languages.some((l) => l.code === searches[i].language)) {
      channel.languages.push({
        name: getLanguageDisplayName(searches[i].language),
        code: searches[i].language,
      });
    }
    channel.searchCount++;
  }

  return channels;
}
