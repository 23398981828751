import { useContext, useEffect, useMemo, useState } from "react";
import { HStack } from "@chakra-ui/react";
import { CampaignSearches } from "../components/Campaigns/Analyze/CampaignSearches";
import { CampaignChannels } from "../components/Campaigns/CampaignChannels";
import { useSearchParams, Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CampaignSearchesQuery, OrgCampaignsQuery } from "../api/campaign";

import { CampaignSubPages, CampaignURLParams, updateURLParams } from "../models/navigation";
import { RivrSearch, searchFromQueryResult } from "../models/rivrSearch";
import { AccountContext } from "../context/AccountContext";
import CampaignManagement from "../components/Campaigns/Manage/CampaignManagement";
import { RivrCampaign, campaignFromQueryResult } from "../models/rivrCampaign";
import { RivrChannel } from "../models/rivrChannel";
import { OrganizationMembership } from "../models/rivrOrganization";
import { Loading } from "../components/Loading/Loading";
import CampaignDiscoverMoments from "../components/Campaigns/Discover/Moments/CampaignDiscoverMoments";
import CampaignDiscoverMentions from "../components/Campaigns/Discover/Mentions/CampaignDiscoverMentions";
import Cookies from "js-cookie";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { RivrLanguage } from "../models/rivrLanguage";
import CampaignInsights from "../components/Campaigns/Insights/CampaignInsights";

const Campaigns = () => {
  const { account, memberships } = useContext(AccountContext);
  const { getFlag } = useKindeAuth();
  const isOrgMember = account.memberships.length > 0;
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const mentionsAllowed = getFlag("allow-mentions", false).value as boolean;

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPage = searchParams.get(CampaignURLParams.SubPage) || CampaignSubPages.Campaigns;
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";
  const sortOption = searchParams.get(CampaignURLParams.ChannelSortOption) || "channel";
  const sortDirection = searchParams.get(CampaignURLParams.ChannelSortDirection) || "asc";
  const selectedDiscoverView = searchParams.get(CampaignURLParams.DiscoverSelectedView) || "moment";

  const [searches, setSearches] = useState<RivrSearch[]>([]);
  const [campaignChannels, setCampaignChannels] = useState<RivrChannel[]>([]);
  const [campaignLanguages, setCampaignLanguages] = useState<RivrLanguage[]>([]);

  const selectedMembership = useMemo(() => {
    return selectedOrg !== ""
      ? memberships.find((m: OrganizationMembership) => m.organization.id === selectedOrg)
      : memberships[0];
  }, [selectedOrg, memberships]);

  const {
    data: campaignsData,
    loading: campaignsLoading,
    refetch: fetchCampaigns,
  } = useQuery(OrgCampaignsQuery, {
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
    variables: { org_id: selectedMembership?.organization.id },
    // poll to update channels when new searches are added
    pollInterval: parseInt(process.env.REACT_APP_ACTIVE_SEARCHES_POLL_TIME || "10000"),
  });

  const campaigns: RivrCampaign[] | undefined = useMemo(() => {
    if (campaignsData) {
      return campaignsData.campaign.map((c: any) => campaignFromQueryResult(c));
    }
  }, [campaignsData]);

  const { loading: searchesLoading, refetch: fetchSearches } = useQuery(CampaignSearchesQuery, {
    onCompleted(data) {
      setSearches(data.search.map((s: any) => searchFromQueryResult(s)));
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
    variables: { campaign: selectedCampaign },
    pollInterval: parseInt(process.env.REACT_APP_ACTIVE_SEARCHES_POLL_TIME || "10000"),
  });

  const handleCampaignSelect = (campaign: string) => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.SelectedCampaign, campaign],
      [CampaignURLParams.SelectedChannels, []],
      [CampaignURLParams.ChannelSortOption, sortOption],
      [CampaignURLParams.ChannelSortDirection, sortDirection],
      [
        CampaignURLParams.SelectedStatuses,
        ["analyzing", "analyzed", "reviewing", "completed", "error"],
      ],
      [CampaignURLParams.MomentTypeFilter, "all"],
      [CampaignURLParams.MomentStatusFilter, ["favorited"]],
      [CampaignURLParams.SelectedLanguages, []],
    ];

    if (selectedPage === CampaignSubPages.Campaigns) {
      newParams.push([CampaignURLParams.SubPage, CampaignSubPages.Analyze]);
    }

    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: false });
  };

  useEffect(() => {
    if (campaigns !== undefined) {
      const campaign = campaigns.find((campaign) => campaign.id === selectedCampaign);
      setCampaignChannels(campaign === undefined ? [] : campaign.channels);
      setCampaignLanguages(campaign === undefined ? [] : campaign.languages);
      if (selectedCampaign !== "") fetchSearches({ campaign: selectedCampaign });
      else setSearches([]);
    }
  }, [campaigns, selectedCampaign]);

  return isAdmin || isOrgMember ? (
    !campaignsLoading ? (
      <HStack
        spacing={0}
        w={"100%"}
        height={"100%"}
        overflowY={"hidden"}
        className={"campaigns"}
        alignItems={"flex-start"}
        position={"relative"}
      >
        {selectedPage !== CampaignSubPages.Campaigns && selectedMembership && (
          <CampaignChannels
            channels={campaignChannels}
            selectedMembership={selectedMembership}
            fetchSearches={fetchSearches}
          />
        )}
        {!searchesLoading ? (
          selectedPage === CampaignSubPages.Analyze ? (
            <CampaignSearches
              searches={searches}
              fetchSearches={fetchSearches}
              languages={campaignLanguages}
            />
          ) : selectedPage === CampaignSubPages.Campaigns ? (
            <>
              {campaigns && (
                <CampaignManagement
                  campaigns={campaigns}
                  orgId={selectedMembership?.organization.id}
                  fetchCampaigns={fetchCampaigns}
                  handleCampaignSelect={handleCampaignSelect}
                />
              )}
            </>
          ) : selectedPage === CampaignSubPages.Discover ? (
            <>
              {selectedDiscoverView === "mention" && mentionsAllowed ? (
                <CampaignDiscoverMentions searches={searches} />
              ) : (
                <CampaignDiscoverMoments
                  searches={searches}
                  fetchSearches={fetchSearches}
                  languages={campaignLanguages}
                  channels={campaignChannels}
                  isOrgMember={isOrgMember}
                />
              )}
            </>
          ) : selectedPage === CampaignSubPages.Insights ? (
            <CampaignInsights searches={searches} />
          ) : null
        ) : (
          <Loading variant={"large"} />
        )}
      </HStack>
    ) : (
      <Loading variant={"large"} />
    )
  ) : (
    <Navigate replace to="/" />
  );
};

export default Campaigns;
