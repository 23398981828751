import { Box } from "@chakra-ui/react";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import { SmallAddIcon } from "@chakra-ui/icons";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import { Analysis, Filter, Lightning, MoreOne } from "@icon-park/react";

import { Text, Link } from "../../components/KnowledgeBase/KbElements";

const KbOrgCampaigns: React.FC = () => {
  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"org-campaigns"} py={2}>
          Campaigns
        </LinkedHeading>
        <Text>Campaigns help organize large amount of videos and their associated data.</Text>

        <LinkedHeading as={"h2"} id={"org-campaigns-create"} pt={6} pb={4}>
          Creating a campaign
        </LinkedHeading>
        <Text>
          To create a new campaign, navigate to the Campaigns page and click the{" "}
          <KbButton
            isIconButton={false}
            text={"Create campaign"}
            colorScheme={"green"}
            leftIcon={<SmallAddIcon />}
          />{" "}
          button. You’ll need to add a title, start date, and end date. The dates are for
          organizational purposes only and do not affect the content of your campaign. Optionally,
          you can provide a description and image.
        </Text>

        <LinkedHeading as={"h3"} id={"org-campaigns-manage"} pt={6} pb={4}>
          Managing a campaign
        </LinkedHeading>
        <Text>
          You can manage a campaign by clicking the menu{" "}
          <KbButton isIconButton={true} icon={<MoreOne size={"20px"} />} colorScheme={"gray"} />{" "}
          button on a campaign. Here, you can manage campaign workflows by marking a campaign as
          Paused, Active, Completed, or Archived. Archived campaigns are not visible by default but
          can be accessed by clicking the filter{" "}
          <KbButton isIconButton={true} icon={<Filter theme="filled" />} colorScheme={"gray"} />{" "}
          button and enabling the visibility of archived campaigns. Changing the status of a
          campaign is for organizational purposes only and do not affect the content of your
          campaign.
        </Text>
        <Text>
          In this menu, you can also edit the details of a campaign and delete it. Deleted campaigns
          cannot be recovered.
        </Text>

        <LinkedHeading as={"h3"} id={"org-campaigns-switcher"} pt={6} pb={4}>
          Quick switcher
        </LinkedHeading>
        <Text>
          You can quickly switch between campaigns by using the dropdown menu located at the
          top-right corner of Rivr.
        </Text>

        <LinkedHeading as={"h2"} id={"org-campaigns-keys"} pt={6} pb={4}>
          Campaign keys
        </LinkedHeading>
        <Text>
          Campaign keys allow organizations to grant creators temporary access to Rivr while
          ensuring that all information related to analyzed videos and Moments created by
          participating creators remains within the organization's campaign.
        </Text>
        <Text>
          Currently, campaign keys are managed by your account manager. Contact them to learn more.
        </Text>

        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 12, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "Analyze",
              description: "How to analyze videos at scale",
              icon: Analysis,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Analyze}`,
            },
            {
              title: "Discover",
              description: "How to find Moments and search Mentions at scale",
              icon: Lightning,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Discover}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbOrgCampaigns;
