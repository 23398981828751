import {
  Flex,
  Text,
  Checkbox,
  IconButton,
  Badge,
  Wrap,
  Tooltip,
  Box,
  Tag,
  HStack,
  Editable,
  EditablePreview,
  EditableInput,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  WrapItem,
  PopoverHeader,
  PopoverCloseButton,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Divider,
  VStack,
} from "@chakra-ui/react";
import { Plus, ScreenRotation, Undo } from "@icon-park/react";
import { secondsToHMSDuration } from "../../../utils/time";
import { CheckIcon, CloseIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import { Moment, RecommendedMoment, recommendedMomentSettings } from "../../../models/moment";
import { pillOptions } from "../../../models/tags";
import "./MomentItem.css";
import * as amplitude from "@amplitude/analytics-browser";
import { DeleteConfirmation } from "../DeleteConfirmation/DeleteConfirmation";
import { TriangleAlert } from "lucide-react";

export type UserMomentInputs = {
  kind: "UserMoment";
  moment: Moment;
  downloadCallback: () => void;
  deleteMoments: (ids: string[]) => void;
  updateMoment: (moment: Moment, update: Partial<Moment>) => void;
  verticalReformat: () => void;
};
export type RecommendedMomentInputs = {
  kind: "RecommendedMoment";
  moment: RecommendedMoment;
  accept: () => void;
  toggle: () => void;
  restore: () => void;
};
export type MomentInputs = UserMomentInputs | RecommendedMomentInputs;

interface Props {
  inputs: MomentInputs;
  checkCallback: (id: string) => void;
  focusedCallback: (id: string | null) => void;
  isChecked: boolean;
  isFocused: boolean;
  isUpdated?: boolean;
  features: string[];
  isOrgMember: boolean;
}

export const MomentItem = ({
  inputs,
  checkCallback,
  focusedCallback,
  isChecked,
  isFocused,
  isUpdated,
  features,
  isOrgMember,
}: Props) => {
  const maxTags = 2;

  const ref = useRef<HTMLDivElement>(null);
  const data = inputs.moment;

  const [tags, setTags] = useState(data.tags);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showRestoreConfirmation, setShowRestoreConfirmation] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [originalTitle, setOriginalTitle] = useState(data.title);
  const [currentTitle, setcurrentTitle] = useState(data.title);
  const toast = useToast();

  const selectMoment = () => {
    if (inputs.kind === "UserMoment" || !inputs.moment.rejected)
      focusedCallback(isFocused ? null : data.id);
  };
  const toggle = () => {
    if (inputs.kind === "RecommendedMoment") {
      if (isFocused) focusedCallback(null);
      inputs.toggle();
    }
  };

  const scrollIntoView = (div: HTMLDivElement) => {
    if (!div.parentElement) return;
    const rect = div.getBoundingClientRect();
    const parentRect = div.parentElement.getBoundingClientRect();
    if (rect.bottom < parentRect.top || rect.top > parentRect.bottom) div.scrollIntoView();
  };

  const momentFeatureTag = (label: string, index: number) => (
    <Tooltip key={index} label={(recommendedMomentSettings as any)[label].title}>
      <Tag
        colorScheme={(recommendedMomentSettings as any)[label].colorScheme}
        variant={"subtle"}
        size={"xl"}
        py={0.5}
        borderRadius={"sm"}
        minW={"24px"}
        justifyContent={"center"}
        className={
          (recommendedMomentSettings as any)[label].hyperThreshold &&
          data.annotations[label] >= (recommendedMomentSettings as any)[label].hyperThreshold
            ? "hyper-hype"
            : ""
        }
      >
        {(recommendedMomentSettings as any)[label].icon}
      </Tag>
    </Tooltip>
  );

  useEffect(() => {
    if (isFocused && ref.current) scrollIntoView(ref.current);
  }, [isFocused]);

  const handleTagToggle = (tag: string) => {
    if (inputs.kind === "RecommendedMoment") return;

    if (tags.includes(tag)) {
      setTags(tags.filter((t) => t !== tag));
    } else {
      setTags([...tags, tag]);
    }
  };

  const updateTags = () => {
    if (inputs.kind === "RecommendedMoment") return;
    if (inputs.moment.tags === tags) return;

    handleAmplitudeTrack("Tags Updated", {
      Added: tags.filter((tag) => !inputs.moment.tags.includes(tag)),
      Removed: inputs.moment.tags.filter((tag) => !tags.includes(tag)),
      TotalTags: tags.length,
      MomentType: inputs.kind,
      MomentId: inputs.moment.id,
    });

    inputs.updateMoment(inputs.moment, { tags: tags });
  };

  const userTags = pillOptions
    .filter((tag) => tag.type === "user")
    .sort((a, b) => a.label.localeCompare(b.label));

  const orgTags = pillOptions
    .filter((tag) => tag.type === "org")
    .sort((a, b) => a.label.localeCompare(b.label));

  const displayedTags = isOrgMember
    ? tags
    : tags.filter((tag) => userTags.some((ut) => ut.label === tag));

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <>
      <Flex
        borderRadius={"md"}
        w={"100%"}
        _hover={{ bg: "whiteAlpha.300" }}
        bg={"whiteAlpha.200"}
        pl={4}
        pr={3}
        className={`result-item-${data.id} momentItem ${isFocused ? "momentItem-focused" : ""}`}
        pos={"relative"}
        ref={ref}
        borderWidth={1}
        borderColor={isFocused ? "transparent" : ""}
        borderStyle={"inset"}
      >
        {isFocused && (
          <Box
            pos={"absolute"}
            h={"100%"}
            w={"100%"}
            top={0}
            left={0}
            borderWidth={2}
            borderColor={"teal.300"}
            borderRadius={"md"}
            pointerEvents={"none"}
          />
        )}
        {inputs.kind !== "RecommendedMoment" && (
          <Checkbox
            className={`inp-result-item-checkbox inp-result-item-${data.id}`}
            w={4}
            mr={4}
            colorScheme={"teal"}
            size={"md"}
            onChange={() => checkCallback(data.id)}
            isChecked={isChecked}
            display={"inherit"}
          />
        )}
        <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
          <Flex
            flexDirection={"column"}
            minW={"150px"}
            justify={"center"}
            w={"100%"}
            onClick={selectMoment}
            py={2}
            minH={12}
            gap={inputs.kind === "UserMoment" ? 0.5 : 1}
          >
            {inputs.kind === "UserMoment" ? (
              <HStack w={"100%"} justifyContent={"space-between"} gap={2}>
                <Editable
                  h={6}
                  value={currentTitle}
                  submitOnBlur
                  onChange={(newTitle) => setcurrentTitle(newTitle)}
                  onSubmit={(newTitle) => {
                    if (!newTitle.trim()) {
                      toast({
                        title: "Error",
                        description: "Moment title cannot be empty",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                      setcurrentTitle(originalTitle);
                    } else {
                      inputs.updateMoment(inputs.moment, { title: newTitle });
                      setOriginalTitle(newTitle);
                      handleAmplitudeTrack("Moment Title Updated", {
                        Original: originalTitle,
                        New: newTitle,
                      });
                    }
                  }}
                  mr={2}
                  w={"100%"}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isFocused) focusedCallback(data.id);
                  }}
                >
                  <HStack h={6} mr={2} w={"100%"}>
                    <EditablePreview
                      title={originalTitle}
                      noOfLines={1}
                      borderRadius={"sm"}
                      p={0}
                      h={6}
                      w={"100%"}
                      maxW={"14.25rem"}
                      _hover={{ bg: "whiteAlpha.200" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isFocused) focusedCallback(data.id);
                      }}
                    />
                    <Input
                      as={EditableInput}
                      borderRadius={"sm"}
                      noOfLines={1}
                      p={0}
                      h={6}
                      w={"100%"}
                      maxLength={50}
                    />
                  </HStack>
                </Editable>
                <Text
                  color={"gray.300"}
                  fontSize={"xs"}
                  userSelect={"none"}
                  w={"max-content"}
                  className={"moment-time"}
                >
                  {secondsToHMSDuration(data.start_time)}
                </Text>
              </HStack>
            ) : (
              <HStack w={"100%"} justifyContent={"space-between"} gap={2}>
                <HStack>
                  <Text noOfLines={1} title={data.title}>
                    {data.title}
                  </Text>
                  {isUpdated && (
                    <Tooltip label={"Modified"}>
                      <Box color={"yellow.300"}>
                        <TriangleAlert color={"currentColor"} size={16} />
                      </Box>
                    </Tooltip>
                  )}
                </HStack>
                <Text
                  color={"gray.300"}
                  fontSize={"xs"}
                  userSelect={"none"}
                  w={"max-content"}
                  className={"moment-time"}
                >
                  {secondsToHMSDuration(data.start_time)}
                </Text>
              </HStack>
            )}

            <HStack
              w={"100%"}
              maxW={28}
              gap={1}
              h={6}
              className={"moment-features"}
              _empty={{ display: "none" }}
            >
              {features
                .filter(
                  (f) =>
                    data.annotations[f] >= (recommendedMomentSettings as any)[f].featureThreshold
                )
                .map((feature, index) => momentFeatureTag(feature, index))}
            </HStack>
            <HStack
              gap={1}
              h={6}
              className="tags"
              display={
                inputs.kind === "RecommendedMoment" && tags.length === 0 ? "none" : undefined
              }
            >
              <Popover placement={"bottom-end"} strategy={"fixed"} onClose={updateTags} isLazy>
                {inputs.kind === "UserMoment" && (
                  <PopoverTrigger>
                    <IconButton
                      aria-label={"Edit moment tags"}
                      icon={<Plus />}
                      h={"1.1rem"}
                      minW={"1.1rem"}
                      mr={1}
                      fontSize={"xs"}
                      variant={"solid"}
                      borderRadius={"sm"}
                      colorScheme={"gray"}
                      bg={"whiteAlpha.300"}
                      _hover={{ bg: "whiteAlpha.400" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isFocused) focusedCallback(data.id);
                      }}
                    />
                  </PopoverTrigger>
                )}

                <PopoverContent
                  onClick={(e) => e.stopPropagation()}
                  maxW={"20.4rem"}
                  w={"100%"}
                  shadow={"dark-lg"}
                >
                  <PopoverHeader>
                    <Text userSelect={"none"} fontSize={"sm"}>
                      Tags
                    </Text>
                    <PopoverCloseButton top={1.5} right={1.5} />
                  </PopoverHeader>
                  {/* User Tags */}
                  {isOrgMember && (
                    <VStack w={"100%"} align={"start"} gap={0} px={3} pt={3}>
                      <Text fontSize={"sm"}>Universal tags</Text>
                      <Text fontSize={"xs"} color={"gray.300"}>
                        Visible to all users
                      </Text>
                    </VStack>
                  )}
                  <Wrap p={3} spacing={1}>
                    {userTags.map((option) => (
                      <WrapItem key={option.label}>
                        <Tooltip label={option.tooltip}>
                          <Button
                            size={"xs"}
                            variant={tags.includes(option.label) ? "solid" : "outline"}
                            colorScheme={tags.includes(option.label) ? "blue" : "gray"}
                            width={"fit-content"}
                            display={"inline-block"}
                            fontWeight={"normal"}
                            onClick={() => handleTagToggle(option.label)}
                            borderWidth={1}
                          >
                            {option.label}
                          </Button>
                        </Tooltip>
                      </WrapItem>
                    ))}
                  </Wrap>
                  {isOrgMember && orgTags.length > 0 && (
                    <>
                      <Divider />
                      <VStack w={"100%"} align={"start"} gap={0} px={3} pt={3}>
                        <Text fontSize={"sm"}>Organization tags</Text>
                        <Text fontSize={"xs"} color={"gray.300"}>
                          Visible only to organizations
                        </Text>
                      </VStack>
                      <Wrap p={3} spacing={1}>
                        {orgTags.map((option) => (
                          <WrapItem key={option.label}>
                            <Tooltip label={option.tooltip}>
                              <Button
                                size={"xs"}
                                variant={tags.includes(option.label) ? "solid" : "outline"}
                                colorScheme={tags.includes(option.label) ? "blue" : "gray"}
                                width={"fit-content"}
                                display={"inline-block"}
                                fontWeight={"normal"}
                                onClick={() => handleTagToggle(option.label)}
                                borderWidth={1}
                              >
                                {option.label}
                              </Button>
                            </Tooltip>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </>
                  )}
                </PopoverContent>
              </Popover>
              {displayedTags.length > 0 && (
                <HStack gap={1} display={"inline-flex"}>
                  {displayedTags.slice(0, maxTags).map((label, index) => (
                    <Badge
                      key={index}
                      h={"1.05rem"}
                      w={"fit-content"}
                      variant={"subtle"}
                      fontWeight={"medium"}
                      letterSpacing={"wide"}
                      fontSize={"xs"}
                      userSelect={"none"}
                      textTransform={"capitalize"}
                    >
                      {label}
                    </Badge>
                  ))}
                  {displayedTags.length > maxTags && (
                    <Tooltip
                      label={displayedTags
                        .slice(maxTags)
                        .sort((a, b) => a.localeCompare(b))
                        .join(", ")}
                      style={{ fontSize: "sm" }}
                    >
                      <Badge
                        h={"1.05rem"}
                        w={"fit-content"}
                        variant={"subtle"}
                        fontWeight={"medium"}
                        letterSpacing={"wide"}
                        fontSize={"xs"}
                        size={"xs"}
                        userSelect={"none"}
                        textTransform={"capitalize"}
                      >
                        +{displayedTags.length - maxTags}
                      </Badge>
                    </Tooltip>
                  )}
                </HStack>
              )}
            </HStack>
          </Flex>
        </Flex>
        {inputs.kind === "UserMoment" ? (
          <HStack w={"fit-content"} h={"100%"} justifyContent={"end"} mr={1}>
            <Tooltip label={"Download"}>
              <IconButton
                aria-label={"Download"}
                className={"btn-po-download moment-action"}
                colorScheme={"gray"}
                variant={"outline"}
                size={"sm"}
                onClick={() => {
                  inputs.downloadCallback();
                  handleAmplitudeTrack("Download Single Moment", {
                    MomentId: inputs.moment.id,
                    MomentTitle: inputs.moment.title,
                    OriginalRec: inputs.moment.recommended_moment_id,
                  });
                }}
                icon={<DownloadIcon boxSize={4} />}
              />
            </Tooltip>
            <Tooltip label={"Reformat to Vertical"}>
              <IconButton
                aria-label={"Reformat to Vertical"}
                className={"btn-po-reformat moment-action"}
                colorScheme={"gray"}
                variant={"outline"}
                size={"sm"}
                onClick={() => {
                  inputs.verticalReformat();
                  handleAmplitudeTrack("Start Vertical Reformat", {
                    MomentId: inputs.moment.id,
                    MomentTitle: inputs.moment.title,
                    OriginalRec: inputs.moment.recommended_moment_id,
                  });
                }}
                icon={<ScreenRotation size={18} style={{ transform: "scaleY(-1)rotate(75deg)" }} />}
              />
            </Tooltip>
            <Tooltip label={"Delete"}>
              <IconButton
                aria-label={"Delete Moment"}
                className={"btn-po-delete moment-action"}
                colorScheme={"gray"}
                variant={"outline"}
                size={"sm"}
                onClick={() => {
                  handleAmplitudeTrack("Delete Moment Clicked", {
                    MomentId: inputs.moment.id,
                    OriginalRec: inputs.moment.recommended_moment_id,
                  });
                  setShowDeleteConfirmation(true);
                }}
                icon={<DeleteIcon />}
              />
            </Tooltip>
            <DeleteConfirmation
              confirm={() => inputs.deleteMoments([inputs.moment.id])}
              isOpen={showDeleteConfirmation}
              onClose={() => setShowDeleteConfirmation(false)}
              headerText={"Delete Moment"}
              bodyText={`Are you sure you want to delete ${inputs.moment.title}?`}
            />
          </HStack>
        ) : (
          <HStack h={"100%"} justify={"end"} mr={1}>
            {isUpdated && (
              <Tooltip label={"Restore Moment"}>
                <IconButton
                  aria-label={"Restore Moment"}
                  className={"btn-po-accept moment-action"}
                  colorScheme={"gray"}
                  variant={"outline"}
                  size={"sm"}
                  onClick={() => {
                    setShowRestoreConfirmation(true);
                    handleAmplitudeTrack("Restore Original Recommendation", {
                      MomentId: inputs.moment.id,
                    });
                  }}
                  icon={<Undo size={16} />}
                  display={!inputs.moment.rejected ? "static" : "none"}
                />
              </Tooltip>
            )}
            <Tooltip label={"Add to My Moments"}>
              <IconButton
                aria-label={"Accept"}
                className={"btn-po-accept moment-action"}
                colorScheme={"gray"}
                variant={"outline"}
                size={"sm"}
                onClick={() => {
                  inputs.accept();
                  handleAmplitudeTrack("Accept Recommended Moment", {
                    MomentId: inputs.moment.id,
                  });
                }}
                icon={<CheckIcon />}
                display={!inputs.moment.rejected ? "static" : "none"}
              />
            </Tooltip>
            <Tooltip label={!inputs.moment.rejected ? "Reject Moment" : "Restore"}>
              <IconButton
                aria-label={!inputs.moment.rejected ? "Reject" : "Restore"}
                className={"btn-po-toggle moment-action"}
                colorScheme={"gray"}
                variant={"outline"}
                size={"sm"}
                onClick={() => {
                  toggle();
                  if (!inputs.moment.rejected) {
                    handleAmplitudeTrack("Reject Recommended Moment", {
                      MomentId: inputs.moment.id,
                    });
                  } else {
                    handleAmplitudeTrack("Undo Recommended Moment Rejection", {
                      MomentId: inputs.moment.id,
                    });
                  }
                }}
                icon={!inputs.moment.rejected ? <CloseIcon boxSize={3} /> : <Undo size={16} />}
              />
            </Tooltip>
          </HStack>
        )}
        {inputs.kind === "RecommendedMoment" && inputs.moment.rejected && (
          <Box
            pos={"absolute"}
            borderRadius={"md"}
            w={"100%"}
            h={"100%"}
            bg={"blackAlpha.500"}
            top={0}
            left={0}
            pointerEvents={"none"}
          />
        )}
      </Flex>
      {inputs.kind === "RecommendedMoment" && (
        <AlertDialog
          isOpen={showRestoreConfirmation}
          leastDestructiveRef={cancelRef}
          onClose={() => setShowRestoreConfirmation(false)}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize={"lg"}>Restore Recommendation</AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to restore this Recommendation? It will be set back to its
                original timestamp.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  ref={cancelRef}
                  onClick={() => setShowRestoreConfirmation(false)}
                  colorScheme={"gray"}
                  variant={"ghost"}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme={"green"}
                  onClick={() => {
                    inputs.restore();
                    setShowRestoreConfirmation(false);
                  }}
                  ml={2}
                >
                  Restore
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};
