import {
  Text as ChakraText,
  Link as ChakraLink,
  UnorderedList as ChakraUnorderedList,
  ListItem as ChakraListItem,
} from "@chakra-ui/react";

export const Text: React.FC<React.ComponentProps<typeof ChakraText>> = (props) => (
  <ChakraText lineHeight={2} pb={4} sx={{ textWrap: "pretty" }} {...props} />
);

export const Link: React.FC<React.ComponentProps<typeof ChakraLink>> = ({ href, ...props }) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (href && href.startsWith("#")) {
      e.preventDefault();
      const element = document.getElementById(href.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  return (
    <ChakraLink
      color={"purple.200"}
      _hover={{ color: "purple.300", textDecor: "underline" }}
      onClick={handleClick}
      href={href}
      {...props}
    />
  );
};

export const UnorderedList: React.FC<React.ComponentProps<typeof ChakraUnorderedList>> = (
  props
) => <ChakraUnorderedList spacing={2} pl={6} pb={4} {...props} />;

export const ListItem: React.FC<React.ComponentProps<typeof ChakraListItem>> = (props) => (
  <ChakraListItem {...props} />
);
