import { WebVTTParser } from "webvtt-parser";

export type Message = {
  index: number;
  timestamp: number;
  content: string;
  userName: string;
  userColor: string;
};

const hexToRgb = (hex: string): [number, number, number] => {
  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

const relativeLuminance = (r: number, g: number, b: number): number => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4;
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const contrastRatio = (lum1: number, lum2: number): number =>
  (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);

const isGoodContrast = (hex1: string, hex2: string): boolean => {
  const [r1, g1, b1] = hexToRgb(hex1);
  const [r2, g2, b2] = hexToRgb(hex2);
  const lum1 = relativeLuminance(r1, g1, b1);
  const lum2 = relativeLuminance(r2, g2, b2);
  return contrastRatio(lum1, lum2) >= 3;
};

export const parseChatFile = (content: string): Message[] => {
  return content.split("\n").reduce((messages: Message[], line, index) => {
    if (line.trim() === "") return messages;

    const message = JSON.parse(line);
    const { sender } = message.content.metadata;
    if (!sender.displayName) return messages;

    let userColor = sender.twitch?.color ?? "#68D391";
    if (!isGoodContrast(userColor, "#171923")) {
      userColor = "#68D391";
    }

    messages.push({
      index,
      timestamp: message.timestamp,
      content: message.content.value,
      userName: sender.displayName,
      userColor,
    });
    return messages;
  }, []);
};

export const parseVTTFile = (content: string, userName: string): Message[] => {
  const parser = new WebVTTParser();
  const tree = parser.parse(content);
  return tree.cues.map((cue: any, index: number) => ({
    index,
    timestamp: cue.startTime,
    content: cue.text.replace(/\{.*?\}/g, "").trim(),
    userName,
    userColor: "#9F7AEA",
  }));
};
