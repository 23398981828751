export type TagType = "user" | "org";

export interface Tag {
  label: string;
  tooltip: string;
  type: TagType;
}

export const pillOptions: Tag[] = [
  // User Tags
  {
    label: "Milestone",
    tooltip: "Moments of celebration or significant achievement within the game",
    type: "user",
  },
  {
    label: "Success",
    tooltip: "Successful completion of a challenging task within the game",
    type: "user",
  },
  {
    label: "Failure",
    tooltip: "Moments of notable or fascinating failure within the game",
    type: "user",
  },
  {
    label: "Unusual",
    tooltip: "Moments of peculiar, intriguing, or rare occurrences within the game",
    type: "user",
  },
  {
    label: "Social",
    tooltip: "Noteworthy social interaction, trendy, meme, or viral Moment",
    type: "user",
  },
  {
    label: "Humorous",
    tooltip: "Moments that sparked laughter or amusement",
    type: "user",
  },
  {
    label: "Happiness",
    tooltip: "Moments of intense joy or positivity",
    type: "user",
  },
  {
    label: "Sarcastic",
    tooltip: "Moments that include instances of irony or mockery",
    type: "user",
  },
  {
    label: "Anger",
    tooltip: "Moments of pronounced annoyance, hostility, or frustration",
    type: "user",
  },
  {
    label: "Sadness",
    tooltip: "Moments of sadness or disappointment",
    type: "user",
  },
  {
    label: "Fear",
    tooltip: "Moments of unexpected danger or fear",
    type: "user",
  },
  {
    label: "Surprise",
    tooltip: "Moments of surprising or unexpected events",
    type: "user",
  },
  {
    label: "Dislike",
    tooltip: "Moments of aversion, disapproval, or displeasure",
    type: "user",
  },
  {
    label: "Bug",
    tooltip: "Moments highlighting bugs or glitches",
    type: "user",
  },
  {
    label: "Feedback",
    tooltip: "Moments where feedback is provided",
    type: "user",
  },
  {
    label: "Sponsor",
    tooltip: "Moments related to sponsors or sponsorships",
    type: "user",
  },
  {
    label: "Educational",
    tooltip: "Moments with an educational purpose",
    type: "user",
  },
  {
    label: "Gameplay",
    tooltip: "Moments focused on gameplay mechanics or strategies",
    type: "user",
  },
  {
    label: "Community",
    tooltip: "Moments involving the community or fan interactions",
    type: "user",
  },
  // Org Tags
  {
    label: "Brand",
    tooltip: "Moments emphasizing brand presence or mentions",
    type: "org",
  },
  {
    label: "Callout",
    tooltip: "Moments where specific callouts are made",
    type: "org",
  },
  {
    label: "Positive",
    tooltip: "Moments with positive sentiments",
    type: "org",
  },
  {
    label: "Negative",
    tooltip: "Moments with negative sentiments",
    type: "org",
  },
  {
    label: "Neutral",
    tooltip: "Moments with neutral sentiments",
    type: "org",
  },
];
