import { Box, Flex, Text } from "@chakra-ui/react";
import { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { RivrMention, mentionDisplaySettings } from "../../../../../models/mention";
import CustomTooltip from "./CustomTooltip";
import { formatDateForDisplay } from "../../../../../utils/time";

type Sentiment = "Positive" | "Negative" | "Neutral";

interface ChartDataItem {
  date: string;
  Positive: number;
  Neutral: number;
  Negative: number;
}

const COLORS: Record<Sentiment, string> = {
  Positive: "#48BB78",
  Neutral: "#4299e1",
  Negative: "#F56565",
};

interface MentionsPerDayProps {
  mentions: RivrMention[];
}

const MentionsPerDay: React.FC<MentionsPerDayProps> = ({ mentions }) => {
  const [barVisibility, setBarVisibility] = useState<Record<Sentiment, boolean>>({
    Positive: true,
    Neutral: true,
    Negative: true,
  });

  // Process mentions data
  const data: ChartDataItem[] = useMemo(() => {
    // Group mentions by date and sentiment
    const groupedData: Record<string, Record<Sentiment, number>> = {};

    // Get the thresholds from mentionDisplaySettings
    const positiveThreshold = mentionDisplaySettings["ASR positive"].featureThreshold;
    const negativeThreshold = -mentionDisplaySettings["ASR negative"].featureThreshold;

    mentions.forEach((mention) => {
      const date = formatDateForDisplay(new Date(mention.date));

      // Initialize date entry if it doesn't exist
      if (!groupedData[date]) {
        groupedData[date] = { Positive: 0, Negative: 0, Neutral: 0 };
      }

      // Determine sentiment category
      let sentiment: Sentiment = "Neutral";
      if (mention.sentiment !== null && mention.sentiment !== undefined) {
        if (mention.sentiment >= positiveThreshold) sentiment = "Positive";
        else if (mention.sentiment <= negativeThreshold) sentiment = "Negative";
        else sentiment = "Neutral";
      }

      groupedData[date][sentiment]++;
    });

    // Convert grouped data to array and sort by date
    const chartData = Object.keys(groupedData)
      .map((date) => ({
        date,
        Positive: groupedData[date].Positive,
        Negative: groupedData[date].Negative,
        Neutral: groupedData[date].Neutral,
      }))
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return chartData;
  }, [mentions]);

  // Calculate total counts for each sentiment
  const totalCounts: Record<Sentiment, number> = useMemo(() => {
    const totals: Record<Sentiment, number> = { Positive: 0, Negative: 0, Neutral: 0 };
    data.forEach((item) => {
      totals.Positive += item.Positive;
      totals.Negative += item.Negative;
      totals.Neutral += item.Neutral;
    });
    return totals;
  }, [data]);

  const handleLegendClick = (entry: { name: Sentiment }) => {
    const sentiment = entry.name;
    setBarVisibility((prev) => ({
      ...prev,
      [sentiment]: !prev[sentiment],
    }));
  };

  const renderLegend = () => {
    return (
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {(Object.keys(COLORS) as Sentiment[]).map((key, index) => {
          const isActive = barVisibility[key];
          return (
            <li
              key={`item-${index}`}
              onClick={() => handleLegendClick({ name: key })}
              style={{
                marginRight: 20,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                opacity: isActive ? 1 : 0.5,
                fontSize: "0.8rem",
                color: COLORS[key],
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: 8,
                  width: 12,
                  height: 12,
                  backgroundColor: COLORS[key],
                }}
              />
              <span>{`${key} (${totalCounts[key].toLocaleString()})`}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Box w="100%">
      {data.length === 0 ? (
        <Flex align="center" justify="center" color="gray.300" p={8} h="300px">
          <Text>No mentions found for the provided keywords</Text>
        </Flex>
      ) : (
        <ResponsiveContainer width="100%" height={399} minWidth={0} minHeight={0}>
          <BarChart
            data={data}
            margin={{ top: 10, right: 10, left: -20, bottom: 10 }}
            reverseStackOrder
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#ffffff" strokeOpacity={0.2} />
            <XAxis dataKey="date" tick={{ fill: "#ffffff", fontSize: 12 }} />
            <YAxis tick={{ fill: "#ffffff", fontSize: 12 }} allowDecimals={false} />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign={"top"} height={32} content={renderLegend} />
            <Bar
              isAnimationActive={false}
              dataKey="Positive"
              fill={COLORS.Positive}
              name="Positive"
              stackId="a"
              hide={!barVisibility.Positive}
            />
            <Bar
              isAnimationActive={false}
              dataKey="Neutral"
              fill={COLORS.Neutral}
              name="Neutral"
              stackId="a"
              hide={!barVisibility.Neutral}
            />
            <Bar
              isAnimationActive={false}
              dataKey="Negative"
              fill={COLORS.Negative}
              name="Negative"
              stackId="a"
              hide={!barVisibility.Negative}
            />
            <Brush
              travellerWidth={16}
              y={360}
              height={24}
              fill="rgba(255, 255, 255, 0.08)"
              stroke="rgba(255, 255, 255, 0.24)"
              tickFormatter={() => ""}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export default MentionsPerDay;
