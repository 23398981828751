import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogFooter,
  UnorderedList,
  ListItem,
  VStack,
  HStack,
  Button,
  Alert,
  AlertIcon,
  Text,
  Code,
} from "@chakra-ui/react";
import { EmailIcon, QuestionIcon } from "@chakra-ui/icons";

interface CreditsHelpProps {
  isOpen: boolean;
  onClose: () => void;
  cancelRef: React.MutableRefObject<HTMLButtonElement | null>;
}

const CreditsHelp = ({ isOpen, onClose, cancelRef }: CreditsHelpProps) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isCentered
      size={"4xl"}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize={"lg"}>Understanding video hour billing</AlertDialogHeader>
        {/* <AlertDialogHeader fontSize={"lg"}>Understanding credits</AlertDialogHeader> */}
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <VStack align={"flex-start"} spacing={4}>
            <VStack align={"flex-start"}>
              <Text fontSize={"lg"} fontWeight={"medium"}>
                Video hours
              </Text>
              {/* <Text fontSize={"lg"} fontWeight={"medium"}>
                Credits
              </Text> */}
              <UnorderedList spacing={1} pl={10}>
                <ListItem>Analyzed video hours are billed at $1.00 USD per video hour.</ListItem>
                <ListItem>Video hours are rounded to the nearest hour.</ListItem>
                <ListItem>1-hour and 15-minutes = 1 video hour.</ListItem>
                <ListItem>1-hour and 30-minutes = 2 video hours.</ListItem>
                {/* <ListItem>Credits are required to analyze videos.</ListItem>
                <ListItem>
                  Credits are utilized at a rate of 1 credit per video hour, rounded to the nearest
                  hour. For example, a 1 hour and 15-minute video would be charged as 1 credit, and
                  a 1 hour and 45-minute video would be charged as 2 credits.
                </ListItem>
                <ListItem>Videos under 1 hour are charged at 1 credit.</ListItem> */}
              </UnorderedList>
            </VStack>
            {/* <VStack align={"flex-start"}>
              <Text fontSize={"lg"} fontWeight={"medium"}>
                Subscription Credits
              </Text>
              <UnorderedList spacing={1} pl={10}>
                <ListItem>
                  Subscription Credits are included with your organization's subscription.
                </ListItem>
                <ListItem>
                  Subscription Credits are always used before Purchased Credits and reset at the
                  start of each billing cycle.
                </ListItem>
                <ListItem>
                  Subscription Credits do not accumulate or roll over each billing cycle.
                </ListItem>
              </UnorderedList>
            </VStack> */}
            <VStack align={"flex-start"}>
              {/* <Text fontSize={"lg"} fontWeight={"medium"}>
                Purchased Credits
              </Text> */}
              <UnorderedList spacing={1} pl={10}>
                {/* <ListItem>
                  Purchased Credits can be acquired as needed and are only used after Subscription
                  Credits have been depleted.
                </ListItem>
                <ListItem>Purchased Credits persist and do not expire.</ListItem> */}
              </UnorderedList>
            </VStack>
          </VStack>
        </AlertDialogBody>
        <AlertDialogFooter pb={6}>
          {/*           <Alert status={"info"} borderRadius={"md"}>
            <AlertIcon />
            <HStack w={"100%"} justify={"space-between"}>
              <Text>
                Need help figuring out how many credits you'll need for your next campaign?
              </Text>
              <Button
                size={"sm"}
                colorScheme={"blue"}
                minW={"max-content"}
                leftIcon={<EmailIcon />}
                as={"a"}
                href={"mailto:sales@rivr.stream"}
                target={"_blank"}
              >
                Contact sales
              </Button>
            </HStack>
          </Alert> */}
          <Alert status={"info"} borderRadius={"md"}>
            <QuestionIcon color={"blue.200"} fontSize={"xl"} mr={3} />
            <HStack w={"100%"} justify={"space-between"}>
              <Text>
                Anticipate high-volume usage? Have a question about billing or your organization's
                subscription plan?
              </Text>
              <Button
                size={"sm"}
                colorScheme={"blue"}
                minW={"max-content"}
                leftIcon={<EmailIcon />}
                as={"a"}
                href={"mailto:sales@rivr.stream"}
                target={"_blank"}
              >
                Contact sales
              </Button>
            </HStack>
          </Alert>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreditsHelp;
