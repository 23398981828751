import {
  Button,
  IconButton,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverCloseButton,
  useDisclosure,
  VStack,
  Tooltip,
  Flex,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import { Filter } from "@icon-park/react";
import * as amplitude from "@amplitude/analytics-browser";
import { useState } from "react";

type Props = {
  recommendedAmount: number;
  setRecommendedAmount: (amount: number) => void;
  minRecommendedAmount: number;
  maxRecommendedAmount: number;
};

export const RecommendedFilterPopover = (props: Props) => {
  const { recommendedAmount, setRecommendedAmount, minRecommendedAmount, maxRecommendedAmount } =
    props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [amount, setAmount] = useState(recommendedAmount);

  const openPopover = () => {
    setAmount(recommendedAmount);
    onOpen();
  };

  const applyFilter = () => {
    setRecommendedAmount(amount);
    onClose();
  };

  const handleAmplitudeTrack = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose} isLazy={true}>
      <PopoverTrigger>
        <Tooltip label="Filter" placement={"right-end"}>
          <IconButton
            aria-label="Create filter"
            className="btn-results-filter"
            colorScheme="gray"
            variant="solid"
            size="sm"
            onClick={openPopover}
            icon={<Filter theme="filled" color="currentColor" />}
          />
        </Tooltip>
      </PopoverTrigger>
      <PopoverContent width={60} sx={{ inset: "0px auto auto -40px" }}>
        <PopoverHeader>
          <Text color="gray.300" fontSize="xs" fontWeight="medium" textTransform="uppercase">
            Filters
          </Text>
        </PopoverHeader>
        <PopoverCloseButton className="btn-filter-close" />
        <PopoverBody>
          <VStack spacing="2" alignItems="left">
            <Text>Recommendations</Text>
            <VStack px={2} pb={2} spacing={0}>
              <Flex justifyContent={"space-between"} w={"104%"} color={"gray.300"}>
                <Text fontSize={"sm"}>{minRecommendedAmount}</Text>
                <Text fontSize={"sm"}>{maxRecommendedAmount}</Text>
              </Flex>
              <Slider min={0} max={1} step={0.1} onChange={setAmount} value={amount}>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
              <Flex justifyContent={"space-between"} w={"104%"} color={"gray.300"}>
                <Text fontSize={"sm"}>Less</Text>
                <Text fontSize={"sm"}>More</Text>
              </Flex>
            </VStack>
          </VStack>
        </PopoverBody>
        <PopoverFooter>
          <Button
            className="btn-filter-add-filter"
            onClick={() => {
              applyFilter();
              handleAmplitudeTrack("Apply Filter to Recommended Moments");
            }}
            float="right"
            variant="solid"
            colorScheme={"green"}
            size="xs"
          >
            Apply filter
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
