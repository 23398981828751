import { gql } from "@apollo/client";

// Queries
// Get clip requests from past 7 days
export const UserClipRequestsQuery = gql`
  query UserClipRequests($account_id: uuid!, $requested_at: timestamptz!) {
    user_clip_request(
      where: {
        account_id: { _eq: $account_id }
        requested_at: { _gte: $requested_at }
        clip_request: { search: { deleted: { _eq: false } } }
      }
    ) {
      clip_request {
        moment
        action
        search {
          channel: metadata(path: "$.channel")
          user_name: metadata(path: "$.user_name")
        }
        clip_results {
          id
          url
          expires_at
        }
      }
      requested_at
    }
  }
`;

// Mutations
// Add new user clip request
export const InsertUserClipRequestMutation = gql`
  mutation InsertUserClipRequest(
    $account_id: uuid!
    $clip_request_id: Int!
    $requested_at: timestamptz!
  ) {
    insert_user_clip_request_one(
      object: {
        account_id: $account_id
        clip_request_id: $clip_request_id
        requested_at: $requested_at
      }
    ) {
      account_id
      clip_request_id
    }
  }
`;
