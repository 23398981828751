import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ImgNotFoundSmall from "../../assets/image-not-found-small.png";
import { hexToBase64 } from "../../utils/image";
import { OrganizationMembership } from "../../models/rivrOrganization";
import { useContext, useMemo } from "react";
import { CampaignSubPages, CampaignURLParams, updateURLParams } from "../../models/navigation";
import { AccountContext } from "../../context/AccountContext";

const OrgSelect = () => {
  const { memberships } = useContext(AccountContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";
  const selectedPage = searchParams.get(CampaignURLParams.SubPage) || CampaignSubPages.Campaigns;
  const sortOption = searchParams.get(CampaignURLParams.ChannelSortOption) || "channel";
  const sortDirection = searchParams.get(CampaignURLParams.ChannelSortDirection) || "asc";

  const selectedMembership = useMemo(() => {
    return selectedOrg !== ""
      ? memberships.find((m: OrganizationMembership) => m.organization.id === selectedOrg)
      : memberships[0];
  }, [selectedOrg, memberships]);

  const handleOrgSelect = (organizationId: string) => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.SelectedOrganization, organizationId],
      [CampaignURLParams.SelectedCampaign, ""],
      [CampaignURLParams.SelectedChannels, []],
      [CampaignURLParams.ChannelSortOption, sortOption],
      [CampaignURLParams.ChannelSortDirection, sortDirection],
      [
        CampaignURLParams.SelectedStatuses,
        ["analyzing", "analyzed", "reviewing", "completed", "error"],
      ],
      [CampaignURLParams.MomentTypeFilter, "all"],
      [CampaignURLParams.MomentStatusFilter, ["favorited"]],
      [CampaignURLParams.SelectedLanguages, []],
    ];

    if (selectedPage !== CampaignSubPages.Campaigns) {
      newParams.push([CampaignURLParams.SubPage, CampaignSubPages.Campaigns]);
    }

    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: false });
  };

  return (
    <Box>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon boxSize={5} />}
          bg={"transparent"}
          minW={"fit-content"}
          fontWeight={"medium"}
          pl={2}
          pr={2}
        >
          <HStack spacing={3}>
            <Image
              boxSize={8}
              src={
                selectedMembership?.organization && selectedMembership.organization.image !== null
                  ? `data:image;base64, ${hexToBase64(selectedMembership.organization.image)}`
                  : ""
              }
              fallbackSrc={ImgNotFoundSmall}
              fit={"contain"}
              borderRadius={"sm"}
            />

            <Text>{selectedMembership?.organization.name}</Text>
          </HStack>
        </MenuButton>
        <MenuList maxH={80} overflowY={"auto"} sx={{ scrollbarWidth: "thin" }}>
          {memberships.map((item, index) => (
            <MenuItem onClick={() => handleOrgSelect(item.organization.id)} key={index}>
              <HStack spacing={3}>
                <Image
                  boxSize={8}
                  src={
                    item.organization.image !== null
                      ? `data:image;base64, ${hexToBase64(item.organization.image)}`
                      : ""
                  }
                  fallbackSrc={ImgNotFoundSmall}
                  fit={item.organization.image !== null ? "contain" : "cover"}
                  borderRadius={"sm"}
                />
                <Text>{item.organization.name}</Text>
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default OrgSelect;
