import React from "react";
import { VStack, Button, Divider, Text } from "@chakra-ui/react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import {
  Analysis,
  ChartLine,
  City,
  Crown,
  Endocrine,
  Flag,
  Helpcenter,
  Home,
  Lightning,
  Message,
  RocketOne,
  Search,
  Star,
} from "@icon-park/react";

interface SidebarButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  isActive?: boolean;

  rightIcon?: React.ReactElement;
}

const SidebarButton = ({
  children,
  onClick,
  isActive = false,

  rightIcon,
}: SidebarButtonProps) => {
  return (
    <Button
      size={"md"}
      variant={"ghost"}
      colorScheme={isActive ? "blue" : "gray"}
      justifyContent={"space-between"}
      onClick={onClick}
      w={"100%"}
      rightIcon={rightIcon}
      isActive={isActive}
      fontWeight={"normal"}
    >
      {children}
    </Button>
  );
};

interface KbNavigationProps {
  isOrgMember: boolean;
}

const KbNavigation = ({ isOrgMember }: KbNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const navigateTo = (subPage: KBSubPages | null = null) => {
    const base = `${RivrLocations.KnowledgeBase}${subPage ? `/${subPage}` : ""}`;
    navigate(`${base}?${searchParams.toString()}`);
  };

  return (
    <VStack w={"100%"} py={2} px={2} align={"start"}>
      <Text
        fontSize={"sm"}
        fontWeight={"semibold"}
        pl={2}
        py={2}
        textTransform={"uppercase"}
        letterSpacing={1}
        color={"gray.300"}
      >
        Creators
      </Text>
      <SidebarButton
        onClick={() => navigateTo()}
        isActive={location.pathname === RivrLocations.KnowledgeBase}
        rightIcon={<Home />}
      >
        Rivr
      </SidebarButton>
      <SidebarButton
        onClick={() => navigateTo(KBSubPages.GettingStarted)}
        isActive={
          location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.GettingStarted}`
        }
        rightIcon={<RocketOne />}
      >
        Getting started
      </SidebarButton>
      <SidebarButton
        onClick={() => navigateTo(KBSubPages.Campaigns)}
        isActive={location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.Campaigns}`}
        rightIcon={<Crown />}
      >
        Creator campaigns
      </SidebarButton>
      <SidebarButton
        onClick={() => navigateTo(KBSubPages.Search)}
        isActive={location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.Search}`}
        rightIcon={<Search />}
      >
        Search
      </SidebarButton>
      <SidebarButton
        onClick={() => navigateTo(KBSubPages.Results)}
        isActive={location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.Results}`}
        rightIcon={<Star />}
      >
        Results
      </SidebarButton>
      <SidebarButton
        onClick={() => navigateTo(KBSubPages.Timeline)}
        isActive={location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.Timeline}`}
        rightIcon={<ChartLine />}
      >
        The Timeline
      </SidebarButton>
      <SidebarButton
        onClick={() => navigateTo(KBSubPages.Moments)}
        isActive={location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.Moments}`}
        rightIcon={<Endocrine />}
      >
        Moments
      </SidebarButton>
      <SidebarButton
        onClick={() => navigateTo(KBSubPages.ChatSpeech)}
        isActive={location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.ChatSpeech}`}
        rightIcon={<Message />}
      >
        Chat & Speech
      </SidebarButton>
      <Divider />
      {isOrgMember && (
        <>
          <Text
            fontSize={"sm"}
            fontWeight={"semibold"}
            pl={2}
            py={2}
            textTransform={"uppercase"}
            letterSpacing={1}
            color={"gray.300"}
          >
            Organizations
          </Text>
          <SidebarButton
            onClick={() => navigateTo(KBSubPages.Organization)}
            isActive={
              location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.Organization}`
            }
            rightIcon={<City />}
          >
            My Organization
          </SidebarButton>
          <SidebarButton
            onClick={() => navigateTo(KBSubPages.OrgCampaigns)}
            isActive={
              location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.OrgCampaigns}`
            }
            rightIcon={<Flag />}
          >
            Campaigns
          </SidebarButton>
          <SidebarButton
            onClick={() => navigateTo(KBSubPages.Analyze)}
            isActive={location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.Analyze}`}
            rightIcon={<Analysis />}
          >
            Analyze
          </SidebarButton>
          <SidebarButton
            onClick={() => navigateTo(KBSubPages.Discover)}
            isActive={location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.Discover}`}
            rightIcon={<Lightning />}
          >
            Discover
          </SidebarButton>

          <Divider />
        </>
      )}

      <SidebarButton
        onClick={() => navigateTo(KBSubPages.Support)}
        isActive={location.pathname === `${RivrLocations.KnowledgeBase}/${KBSubPages.Support}`}
        rightIcon={<Helpcenter />}
      >
        Support
      </SidebarButton>
    </VStack>
  );
};

export default KbNavigation;
