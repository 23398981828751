import { useContext } from "react";
import { AccountContext } from "../context/AccountContext";

type Tutorial = {
  completed: boolean;
  completeTutorial: () => void;
};

export function useTutorial(tutorial: string): Tutorial {
  const { account, completeTutorial } = useContext(AccountContext);
  const completed = account.tutorial_progress.some(
    (progress: any) => progress.tutorial === tutorial
  );
  const finishTutorial = () => {
    completeTutorial(tutorial);
  };
  return {
    completed,
    completeTutorial: finishTutorial,
  };
}
