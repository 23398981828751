type ValidUrl = {
  origin: string;
  cleanUrl: string;
};

export const originTwitch = "Twitch";
export const originYouTube = "Youtube";

const cleanUrl = (url: URL, origin: string, requiredParams: string[] = []): ValidUrl | string => {
  let query = "";
  const missing: string[] = [];
  requiredParams.forEach((param) => {
    const value = url.searchParams.get(param);
    if (value === null) missing.push(param);
    else query += `${query === "" ? "?" : "&"}${param}=${value}`;
  });
  if (missing.length > 0)
    return `This ${origin} URL is missing required parameter(s) ${missing.join(", ")}`;
  return { origin, cleanUrl: `${url.origin}${url.pathname}${query}` };
};

const checkTwitch = (url: URL, flags: Map<string, boolean>): ValidUrl | string => {
  const pathParts = url.pathname.split("/");
  pathParts.shift();

  // VODs
  if (
    (pathParts.length === 2 && pathParts[0] === "videos") ||
    (pathParts.length === 3 && pathParts[1] === "video")
  )
    return cleanUrl(url, originTwitch);

  // Live streams
  if (pathParts.length === 1 && pathParts[0] !== "") {
    if (flags.get("live-twitch-url")) return cleanUrl(url, originTwitch);
    return "Twitch live streams are not yet supported";
  }

  // Clips
  if (pathParts.length === 3 && pathParts[1] === "clip")
    return "Twitch clips are not yet supported";

  return "This Twitch URL is not supported";
};

const checkYouTube = (url: URL, flags: Map<string, boolean>): ValidUrl | string => {
  if (!flags.get("youtube-url")) return "YouTube URLs are not yet supported";

  const pathParts = url.pathname.split("/");
  pathParts.shift();

  // VODs and live streams
  if (url.pathname === "/watch") return cleanUrl(url, originYouTube, ["v"]);

  // Shorts
  if (pathParts.length === 2 && pathParts[0] === "shorts")
    return "YouTube shorts are not yet supported";

  // Clips
  if (pathParts.length === 2 && pathParts[0] === "clip")
    return "YouTube clips are not yet supported";

  return "This YouTube URL is not supported";
};

const checkShortYouTube = (url: URL, flags: Map<string, boolean>): ValidUrl | string => {
  if (!flags.get("youtube-url")) return "YouTube URLs are not yet supported";

  const pathParts = url.pathname.split("/");
  pathParts.shift();

  // VODs and live streams
  if (pathParts.length === 1 && pathParts[0] !== "") return cleanUrl(url, originYouTube);

  return "This YouTube URL is not supported";
};

const hostnames = new Map<string, (url: URL, flags: Map<string, boolean>) => ValidUrl | string>([
  ["www.twitch.tv", checkTwitch],
  ["www.youtube.com", checkYouTube],
  ["youtu.be", checkShortYouTube],
]);

export const checkUrl = (url: string, flags: Map<string, boolean>): ValidUrl | string => {
  try {
    const parsedUrl = new URL(url);
    const handler = hostnames.get(parsedUrl.hostname);
    if (handler) return handler(parsedUrl, flags);
    return `'${parsedUrl.hostname}' URLs are not supported`;
  } catch {
    return `'${url}' is not a valid URL`;
  }
};
