import { Navigate } from "react-router-dom";
import { Box, VStack, useDisclosure } from "@chakra-ui/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import FeedbackModalContext from "./context/FeedbackModalContext";
import FeedbackForm from "./components/FeedbackForm/FeedbackForm";
import { AccountProvider } from "./context/AccountProvider";
import NavigationBar from "./components/Navigation/NavigationBar";
import { DownloadsProvider } from "./context/DownloadsProvider";
import OnboardingModal from "./components/Onboarding/OnboardingModal";
import GlobalActions from "./components/GlobalActions/GlobalActions";
import { ErrorScreen } from "./ErrorScreen";

const Protected = ({ children }) => {
  const { isAuthenticated } = useKindeAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const hideNavBarPaths = ["/billing-redirect"];
  const showNavBar = !hideNavBarPaths.includes(window.location.pathname);

  if (!isAuthenticated) {
    localStorage.setItem("loginRedirect", `${window.location.pathname}${window.location.search}`);
    return <Navigate replace to="/" />;
  }
  localStorage.removeItem("loginRedirect");

  return (
    <Box w={"100%"} h={"100vh"}>
      <AccountProvider>
        <DownloadsProvider>
          <FeedbackModalContext.Provider value={{ isOpen, onOpen, onClose }}>
            <ErrorScreen>
              <VStack spacing={0} h={"100%"} w={"100%"} className={"site-wrapper"}>
                <OnboardingModal />

                {showNavBar && <NavigationBar />}

                {children}
              </VStack>
              <FeedbackForm />
              <GlobalActions />
            </ErrorScreen>
          </FeedbackModalContext.Provider>
        </DownloadsProvider>
      </AccountProvider>
    </Box>
  );
};
export default Protected;
