import { calculateVideoHours, getCurrentCycleStartDate } from "../utils/credits";
import { channelsFromSearches, RivrChannel } from "./rivrChannel";
import { getLanguageDisplayName, RivrLanguage } from "./rivrLanguage";

export type CampaignStatus = "active" | "paused" | "archived" | "completed";

export type RivrCampaign = {
  id: string;
  title: string;
  image: string | null;
  description: string;
  userId: string;
  orgId: string;
  createdAt: Date;
  archivedAt: Date | null;
  status: CampaignStatus;
  startDate: Date;
  endDate: Date;
  channels: RivrChannel[];
  searchCount: number;
  searchHours: number;
  hoursThisCycle: number;
  hoursLastCycle: number;
  languages: RivrLanguage[];
};

function getHoursThisCycle(searches: any[]): number {
  const cycleStartDate = getCurrentCycleStartDate();

  return searches.length > 0
    ? calculateVideoHours(
        searches
          .filter((search) => {
            return search.createdAt.valueOf() >= cycleStartDate.valueOf();
          })
          .map((search) => {
            return search.videoDuration;
          })
      )
    : 0;
}

function getHoursLastCycle(searches: any[]): number {
  const cycleStartDate = getCurrentCycleStartDate();
  const lastCycleStartDate = new Date(cycleStartDate);
  lastCycleStartDate.setMonth(lastCycleStartDate.getMonth() - 1);

  return searches.length > 0
    ? calculateVideoHours(
        searches
          .filter((search) => {
            return (
              lastCycleStartDate.valueOf() <= search.createdAt.valueOf() &&
              search.createdAt.valueOf() < cycleStartDate.valueOf()
            );
          })
          .map((search) => {
            return search.videoDuration;
          })
      )
    : 0;
}

export function campaignFromQueryResult(campaign: any): RivrCampaign {
  const uniqueLanguages: RivrLanguage[] = [];

  for (const search of campaign.searches) {
    if (!uniqueLanguages.map((l) => l.code).includes(search.language) && search.language !== null) {
      uniqueLanguages.push({
        name: getLanguageDisplayName(search.language),
        code: search.language,
      });
    }
  }

  const result: RivrCampaign = {
    id: campaign.id,
    title: campaign.title,
    image: campaign.image,
    description: campaign.description,
    userId: campaign.user_id,
    orgId: campaign.organization_id,
    createdAt: new Date(campaign.created_at),
    archivedAt:
      campaign.archived_at === null ? campaign.archived_at : new Date(campaign.archived_at),
    status: campaign.status as CampaignStatus,
    startDate: campaign.start_date === null ? campaign.start_date : new Date(campaign.start_date),
    endDate: campaign.end_date === null ? campaign.end_date : new Date(campaign.end_date),
    channels: channelsFromSearches(campaign.searches),
    searchCount: campaign.searches.length,
    searchHours: calculateVideoHours(
      campaign.searches.map((s: any) => (s.video_duration !== null ? s.video_duration : "00:00:00"))
    ),
    hoursThisCycle: getHoursThisCycle(
      campaign.searches.map((s: any) => ({
        videoDuration: s.video_duration !== null ? s.video_duration : "00:00:00",
        createdAt: new Date(s.created_at),
      }))
    ),
    hoursLastCycle: getHoursLastCycle(
      campaign.searches.map((s: any) => ({
        videoDuration: s.video_duration !== null ? s.video_duration : "00:00:00",
        createdAt: new Date(s.created_at),
      }))
    ),
    languages: uniqueLanguages.sort((a, b) => a.name.localeCompare(b.name)),
  };

  return result;
}
