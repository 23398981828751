import { gql } from "@apollo/client";

// Queries
// Get all searches, limited to 200 in component
export const AllSearchesQuery = gql`
  query AllSearches($limit: Int, $startTime: timestamptz, $endTime: timestamptz) {
    search(
      order_by: { id: desc }
      where: { deleted: { _eq: false }, created_at: { _gte: $startTime, _lte: $endTime } }
      limit: $limit
    ) {
      id
      status
      search_progress
      workflow_status
      url
      video_duration
      created_at
      campaign_id
      user_id
    }
  }
`;
