import { Alert, AlertDescription, AlertIcon, Box } from "@chakra-ui/react";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import { ChartLine, Endocrine } from "@icon-park/react";
import { Text, Link, UnorderedList, ListItem } from "../../components/KnowledgeBase/KbElements";

const KbChatSpeech: React.FC = () => {
  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"results-chat-and-speech"} py={2}>
          Chat & Speech
        </LinkedHeading>

        <LinkedHeading as={"h2"} id={"results-chat"} pt={6} pb={4}>
          Searching Chat transcripts
        </LinkedHeading>
        <Text>
          To search through Chat transcripts, click the Chat Search tab in the upper-right corner of
          the Results page. Here, you can search for any messages sent via chat. Click on any
          message to skip to that point in the video.
        </Text>
        <Text>
          You can change the size of the text by clicking the plus{" "}
          <KbButton isIconButton={true} icon={<AddIcon />} colorScheme={"gray"} /> or minus{" "}
          <KbButton isIconButton={true} icon={<MinusIcon />} colorScheme={"gray"} /> buttons.
        </Text>
        <Text>Searching through Chat can be useful for finding:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>Call-outs and mentions</ListItem>
          <ListItem>Emotes and commands</ListItem>
          <ListItem>Messages sent by a specific chatter</ListItem>
        </UnorderedList>

        <LinkedHeading as={"h2"} id={"results-speech"} pt={6} pb={4}>
          Searching Speech transcripts
        </LinkedHeading>
        <Text>
          To search through Speech transcripts, click the Speech Search tab in the upper-right
          corner of the Results page. Here, you can search for any words spoken aloud by you or
          others in your video. Click on any message to skip to that point in the video.
        </Text>
        <Text>
          You can change the size of the text by clicking the plus{" "}
          <KbButton isIconButton={true} icon={<AddIcon />} colorScheme={"gray"} /> or minus{" "}
          <KbButton isIconButton={true} icon={<MinusIcon />} colorScheme={"gray"} /> buttons.
        </Text>
        <Text>Searching through Speech can be useful for finding:</Text>
        <UnorderedList spacing={2} pl={6} pb={8}>
          <ListItem>Call-outs and catchphrases</ListItem>
          <ListItem>Brand mentions</ListItem>
          <ListItem>Reminders and "clip that!" moments</ListItem>
        </UnorderedList>

        <Alert status={"warning"} borderRadius={"md"} mb={2}>
          <AlertIcon />
          <AlertDescription>
            Speech transcriptions can sometimes be inaccurate. When searching, try alternative
            spellings and similar words. For example, brand names like "BEACN" may be interpreted as
            "Beacon," "Beecon," or "bee can."
          </AlertDescription>
        </Alert>

        <Alert status={"warning"} borderRadius={"md"} mb={4}>
          <AlertIcon />
          <AlertDescription>
            Speech transcriptions can sometimes pick up words in background music or non-human
            speech (such as NPCs in video games).
          </AlertDescription>
        </Alert>

        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 12, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "The Timeline",
              description: "How to navigate and understand Signals",
              icon: ChartLine,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Timeline}`,
            },
            {
              title: "Moments",
              description: "How to analyze your VODs for your best Moments",
              icon: Endocrine,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Moments}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbChatSpeech;
