import { Heading, Text, Link, List, ListItem, VStack } from "@chakra-ui/react";

const Terms = () => {
  return (
    <VStack maxW={"1600px"} px={6} py={12} align={"start"} gap={8}>
      <Heading fontSize={"2xl"}>Updated: November 22nd, 2024</Heading>
      <VStack align={"start"}>
        <Heading fontSize={"2xl"}>Terms and Conditions</Heading>
        <Text>
          Welcome to Rivr! These Terms and Conditions ("T&C") govern your use of the services
          provided by Rivr & Botni.Vision, Inc. ("Rivr," "we," "us," or "our"), accessible via{" "}
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="/"
          >
            https://app.rivr.stream
          </Link>
          ,{" "}
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="https://rivr.stream"
          >
            https://rivr.stream
          </Link>{" "}
          and{" "}
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="https://botni.vision"
            isExternal
          >
            https://botni.vision
          </Link>{" "}
          (collectively, the "Websites") By accessing or using our service, you agree to comply with
          and be bound by these T&C and our{" "}
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="/privacy"
          >
            Privacy Policy
          </Link>
          . If you do not agree to these terms, please do not use our service.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Definitions</Heading>
        <List>
          <ListItem>
            - "Service" refers to the SaaS platform provided by Rivr, including all features and
            functionalities accessible through the Websites.
          </ListItem>
          <ListItem>
            - "User" refers to any individual or entity that accesses or uses the Service.
          </ListItem>
          <ListItem>
            - "Account" means the personal account created by a User to access the Service.
          </ListItem>
          <ListItem>
            - "Content" refers to any information, data, text, graphics, or other materials
            submitted, uploaded, or otherwise made available by Users through the Service.
          </ListItem>
          <ListItem>
            - "Subscription" refers to the paid plans that grant Users access to certain features of
            the Service.
          </ListItem>
        </List>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Acceptance of Terms</Heading>
        <Text>
          By using the Service, you agree to these T&C and our Privacy Policy. If you are entering
          into these T&C on behalf of a company or other legal entity, you represent that you have
          the authority to bind that entity to these terms.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Eligibility</Heading>
        <Text>
          You must be at least 18 years old to use the Service. By using the Service, you represent
          and warrant that you meet this age requirement.
        </Text>
      </VStack>

      <VStack align={"start"} gap={4}>
        <Heading fontSize={"xl"}>User Accounts</Heading>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Account Creation</Heading>
          <Text>
            To access certain features of the Service, you must create an Account. You agree to
            provide accurate, current, and complete information during the registration process and
            to update such information to keep it accurate.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Account Security</Heading>
          <Text>
            You are responsible for maintaining the confidentiality of your Account credentials and
            for all activities that occur under your Account. You agree to notify Rivr immediately
            of any unauthorized use of your Account.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Account Termination</Heading>
          <Text>
            Rivr reserves the right to suspend or terminate your Account at any time, without
            notice, without refund, for conduct that Rivr believes violates these T&C or is harmful
            to other Users or the Service.
          </Text>
        </VStack>
      </VStack>

      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Cookies</Heading>
        <Text>
          We employ the use of cookies. By accessing Rivr, you agreed to use cookies in agreement
          with Rivr & Botni.Vision, Inc.'s{" "}
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="/privacy"
          >
            Privacy Policy
          </Link>
          . Most interactive websites use cookies to let us retrieve the user's details for each
          visit. Cookies are used by our website to enable the functionality of certain areas to
          make it easier for people visiting our website. Some of our affiliate/advertising partners
          may also use cookies.
        </Text>
      </VStack>
      <VStack align={"start"} gap={4}>
        <Heading fontSize={"xl"}>Service Usage</Heading>
        <Heading fontSize={"md"}>Permitted Uses</Heading>
        <Text>
          You may use the Service solely for lawful purposes and in accordance with these T&C. You
          agree not to use the Service:
        </Text>
        <List>
          <ListItem>
            - In any way that violates any applicable local, state, national, or international law
            or regulation.
          </ListItem>
          <ListItem>
            - For the purpose of exploiting, harming, or attempting to exploit or harm minors.
          </ListItem>
          <ListItem>
            - To transmit, or procure the sending of, any advertising or promotional material
            without our prior written consent.
          </ListItem>
        </List>
        <Heading fontSize={"md"}>Prohibited Uses</Heading>
        <Text>You agree not to:</Text>
        <List>
          <ListItem>
            - Republish, sell, rent, or sub-license any material from the Service.
          </ListItem>
          <ListItem>
            - Reproduce, duplicate, copy, or otherwise exploit material from the Service for any
            commercial purpose.
          </ListItem>
          <ListItem>
            - Redistribute content from the Service except as expressly permitted.
          </ListItem>
        </List>
      </VStack>

      <VStack align={"start"} gap={4}>
        <Heading fontSize={"xl"}>Subscription and Payment</Heading>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Subscription Plans</Heading>
          <Text>
            Rivr offers various subscription plans that provide access to different features of the
            Service. Details of each plan, including pricing and features, are available in your
            Account settings.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Billing and Payment</Heading>
          <Text>
            By subscribing to a plan, you authorize Rivr to charge the provided payment method on a
            recurring basis according to the selected plan's billing cycle. All fees are
            non-refundable unless otherwise stated.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Auto-Renewal and Cancellation</Heading>
          <Text>
            Subscriptions automatically renew at the end of each billing cycle unless canceled prior
            to the renewal date. You can cancel your subscription at any time through your Account
            settings. Upon cancellation, you will continue to have access to the Service until the
            end of the current billing period.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Taxes</Heading>
          <Text>
            You are responsible for any taxes applicable to your subscription. Rivr will collect
            taxes where required by law.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Refund Policy</Heading>
          <Text>
            Refunds are discretionary and handled on a case-by-case basis. Rivr reserves the right
            to not offer refunds in any case. If you believe you are eligible for a refund, please
            contact us with your request and relevant details. Each refund request will be reviewed
            individually, and refunds may be granted at Rivr's sole discretion.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Payment Processing</Heading>
          <Text>
            Payment processing for Rivr’s Services is handled by a third-party payment processor,
            Lemon Squeezy, LLC ("Payment Processor"), depending on the payment method chosen for
            purchase. By making a purchase, you agree to be bound by the terms and conditions of
            Lemon Squeezy, LLC, which can be found at{" "}
            <Link
              color={"purple.200"}
              _hover={{ color: "purple.300", textDecor: "underline" }}
              href="https://www.lemonsqueezy.com/terms"
              isExternal
            >
              https://www.lemonsqueezy.com/terms
            </Link>
            . Payment processing is conducted via Lemon Squeezy, LLC, which is considered our
            Payment Collection Partner. All payments are processed securely through Lemon Squeezy,
            LLC. Chargebacks and Refunds: Any chargebacks or disputes must be handled directly with
            Lemon Squeezy, LLC in accordance with their chargeback procedures. Please refer to their{" "}
            <Link
              color={"purple.200"}
              _hover={{ color: "purple.300", textDecor: "underline" }}
              href="https://docs.lemonsqueezy.com/help/payments/refunds-chargebacks"
              isExternal
            >
              Refund Policy
            </Link>{" "}
            for more information.
          </Text>
        </VStack>
      </VStack>

      <VStack align={"start"} gap={4}>
        <Heading fontSize={"xl"}>Intellectual Property Rights</Heading>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Ownership</Heading>
          <Text>
            All content, features, and functionality of the Service, including but not limited to
            text, graphics, logos, and software, are the exclusive property of Rivr and are
            protected by international intellectual property laws.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>User-Generated Content</Heading>
          <Text>
            By participating in additional opt-in features such as Spotlights, Promotions, Feedback,
            or Machine Learning, you grant Rivr the rights to use and present your Content as
            outlined in our{" "}
            <Link
              color={"purple.200"}
              _hover={{ color: "purple.300", textDecor: "underline" }}
              href="/privacy"
            >
              Privacy Policy
            </Link>
            . You retain ownership of your Content and are responsible for ensuring that it does not
            infringe on the rights of any third party.
          </Text>
        </VStack>
      </VStack>

      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Content Liability</Heading>
        <Text
          textAlign={"center"}
          sx={{ textWrap: "balance" }}
          bg={"red.600"}
          px={6}
          py={4}
          borderRadius={"md"}
          borderWidth={1}
        >
          By using Rivr you agree that we shall not be held responsible for any content that is
          downloaded, created, generated, modified, or distributed through, from, or with Rivr. We
          shall not be held responsible for content that appears on yours or any other Website.
        </Text>
        <Text>
          Additionally, by opting into any of the additional features such as Spotlights or
          Promotions, you acknowledge and agree that Rivr shall not be held responsible for any
          content that is featured or shared through these services. You retain full responsibility
          for the content you choose to share and feature, ensuring that it complies with all
          applicable laws and regulations.
        </Text>
        <Text>
          You agree to protect and defend us against all claims that is rising on your Website. No
          link(s) should appear on any Website that may be interpreted as libelous, obscene or
          criminal, or which infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </Text>
      </VStack>

      <VStack align={"start"} gap={4}>
        <VStack align={"start"}>
          <Heading fontSize={"xl"}>Additional Opt-in Terms and Conditions</Heading>
          <Text>
            Rivr offers additional opt-in features that govern the terms and rights associated with
            your use of our platform. These opt-ins outline the specific rights you grant to Rivr
            and the terms under which you engage with these additional services. Please review the
            following carefully:
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Spotlights</Heading>
          <Text>
            By opting into Spotlights, you grant Rivr a perpetual, worldwide, non-exclusive,
            royalty-free license to use, modify, adapt, publish, translate, create derivative works
            from, distribute, perform, and display your Content in any media formats and channels.
            This license allows us to promote your Content through various channels while respecting
            your ownership and rights over the original Content. You retain all ownership rights to
            your Content, and Rivr does not claim any ownership over it.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Promotions</Heading>
          <Text>
            When you opt into Promotions, you agree that Rivr may present your Content and publicly
            available social media profiles to third-party brands for collaboration purposes. You
            retain all rights to your Content and profiles, and Rivr ensures that no personal
            information (such as email addresses or phone numbers) is shared without your explicit
            consent. Any collaboration initiated through Promotions is subject to separate
            agreements between you and the third-party brands.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Feedback</Heading>
          <Text>
            By opting into Feedback, you agree to participate in surveys, interviews, and other
            feedback collection methods conducted by Rivr. You grant Rivr the right to use the
            feedback provided to improve our services and products. All feedback is anonymized and
            aggregated to protect your privacy.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Machine Learning</Heading>
          <Text>
            When you opt into Machine Learning, you consent to Rivr using your anonymized Content
            and interaction data to train and enhance our machine learning algorithms. You retain
            ownership of your original Content, and Rivr does not use any personally identifiable
            information (PII) in the machine learning processes. The insights gained from your data
            are used solely to improve content discovery and recommendation features on the Rivr
            platform.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Consent for Additional Opt-in Options</Heading>
          <Text>
            By selecting any of the additional opt-in features you agree to the specific terms and
            conditions outlined above. You may withdraw your consent for any of these opt-ins at any
            time by contacting us or adjusting your preferences within your account settings.
            Continued participation after any changes signifies your acceptance of the updated
            terms.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Consent Withdrawal and Content Removal</Heading>
          <Text>
            If you choose to withdraw your consent for any of the opt-in features Rivr will cease to
            use your Content or data in future efforts related to the respective feature. However,
            any Content or data that has already been used under the corresponding license prior to
            your withdrawal of consent may remain in use and cannot be retroactively removed or
            withdrawn.
          </Text>
        </VStack>
      </VStack>

      <VStack align={"start"} gap={4}>
        <Heading fontSize={"xl"}>Termination</Heading>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>By User</Heading>
          <Text>
            You may terminate your Account and subscription at any time through your Account
            settings. Upon termination, you will lose access to the Service immediately, and your
            subscription will not be renewed.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>By Rivr</Heading>
          <Text>
            Rivr reserves the right to suspend or terminate your Account and access to the Service
            at any time, without notice, without refund, for conduct that Rivr believes violates
            these T&C or is harmful to other Users or the Service.
          </Text>
        </VStack>
      </VStack>
      <VStack align={"start"} gap={4}>
        <Heading fontSize={"xl"}>Disclaimers and Limitation of Liability</Heading>
        <Heading fontSize={"md"}>Disclaimers</Heading>
        <Text>
          The Service is provided "as is" and "as available" without any warranties of any kind,
          either express or implied. Rivr does not warrant that the Service will be uninterrupted,
          error-free, or free of viruses or other harmful components.
        </Text>
        <Text>
          To the maximum extent permitted by applicable law, Rivr excludes all representations,
          warranties, and conditions relating to our website and the use of additional opt-in
          features. This exclusion includes, but is not limited to, Spotlights, Promotions,
          Feedback, and Machine Learning. Nothing in this disclaimer will: limit or exclude our or
          your liability for death or personal injury; limit or exclude our or your liability for
          fraud or fraudulent misrepresentation; limit any of our or your liabilities in any way
          that is not permitted under applicable law; or exclude any of our or your liabilities that
          may not be excluded under applicable law. The limitations and prohibitions of liability
          set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the disclaimer, including
          liabilities arising in contract, in tort and for breach of statutory duty.
        </Text>

        <Heading fontSize={"md"}>Limitation of Liability</Heading>
        <Text>
          To the fullest extent permitted by law, Rivr shall not be liable for any indirect,
          incidental, special, consequential, or punitive damages, including without limitation,
          loss of profits, data, use, goodwill, or other intangible losses, resulting from:
        </Text>
        <List>
          <ListItem>- Your access to or use of or inability to access or use the Service;</ListItem>
          <ListItem>- Any conduct or content of any third party on the Service;</ListItem>
          <ListItem>- Any content obtained from the Service;</ListItem>
          <ListItem>
            - Unauthorized access, use, or alteration of your transmissions or content.
          </ListItem>
        </List>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Indemnification</Heading>
        <Text>
          You agree to indemnify, defend, and hold harmless Rivr, its affiliates, officers,
          directors, employees, and agents from and against any claims, liabilities, damages,
          losses, and expenses, including without limitation, reasonable legal and accounting fees,
          arising out of or in any way connected with your access to or use of the Service, or your
          violation of these T&C.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Governing Law and Dispute Resolution</Heading>
        <Text>
          These T&C shall be governed by and construed in accordance with the laws of the Quebec,
          Canada, without regard to its conflict of law provisions. Any disputes arising out of or
          related to these T&C or the Service shall be resolved exclusively through binding
          arbitration in Quebec, Canada.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Modifications to Terms</Heading>
        <Text>
          Rivr reserves the right to modify or replace these T&C at any time. If a revision is
          material, we will provide at least 30 days' notice prior to any new terms taking effect.
          By continuing to access or use the Service after those revisions become effective, you
          agree to be bound by the revised terms.
        </Text>
      </VStack>
      <Heading fontSize={"xl"}>Miscellaneous</Heading>

      <VStack align={"start"} gap={4}>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Severability</Heading>
          <Text>
            If any provision of these T&C is found to be unenforceable or invalid by a court, the
            remaining provisions shall remain in full force and effect.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>Entire Agreement</Heading>
          <Text>
            These T&C, along with our Privacy Policy, constitute the entire agreement between you
            and Rivr regarding your use of the Service and supersede all prior agreements and
            understandings.
          </Text>
        </VStack>
        <VStack align={"start"}>
          <Heading fontSize={"lg"}>No Waiver</Heading>
          <Text>
            No waiver of any term of these T&C shall be deemed a further or continuing waiver of
            such term or any other term, and Rivr’s failure to assert any right or provision under
            these T&C shall not constitute a waiver of such right or provision.
          </Text>
        </VStack>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Your Privacy</Heading>
        <Text>
          Please read our{" "}
          <Link
            color={"purple.200"}
            _hover={{ color: "purple.300", textDecor: "underline" }}
            href="/privacy"
          >
            privacy policy
          </Link>{" "}
          to understand how we handle your personal information.
        </Text>
      </VStack>
      <VStack align={"start"}>
        <Heading fontSize={"xl"}>Copyright Notice</Heading>
        <Text>© {new Date().getFullYear()} Rivr & Botni.Vision, Inc. All Rights Reserved.</Text>
        <Text>
          All trademarks, logos, and service marks displayed on this website are the property of
          Rivr & Botni.Vision, Inc. or their respective owners. You are not permitted to use these
          trademarks without the prior written consent of Rivr & Botni.Vision, Inc. Any unauthorized
          use of the materials may violate copyright, trademark, and other applicable laws. All
          content provided on this website, including text, graphics, logos, images, and software,
          is the exclusive property of Rivr & Botni.Vision, Inc. and is protected by the United
          States, Canada, and international copyright laws.
        </Text>
      </VStack>
    </VStack>
  );
};

export default Terms;
