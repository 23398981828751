import {
  Text,
  IconButton,
  Badge,
  Box,
  Image,
  VStack,
  Tooltip,
  HStack,
  Progress,
  Spinner,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import {
  Heartbeat,
  MoreOne,
  Square,
  Check,
  InboxIn,
  InboxOut,
  Eyes,
  BackOne,
  Redo,
} from "@icon-park/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, useSearchParams } from "react-router-dom";
import { originTwitch, originYouTube } from "../../../models/urls";
import TwitchLogo from "../../../assets/twitch-logo.svg";
import YouTubeLogo from "../../../assets/youtube-logo.svg";
import ImgNotFound from "../../../assets/image-not-found.png";
import { memo, useState } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { SearchMutation } from "../../../api/actions";
import {
  SetSearchArchiveStatusMutation,
  SetSearchWorkflowMutation,
  StopSearchMutation,
  DeleteSearchMutation,
  SearchByIDQuery,
  ClearCampaignIdSearchMutation,
} from "../../../api/search";
import { RivrSearch } from "../../../models/rivrSearch";
import {
  CampaignURLParams,
  ResultsURLParams,
  RivrLocations,
  updateURLParams,
} from "../../../models/navigation";
import { formatDateForDisplay } from "../../../utils/time";
import Cookies from "js-cookie";
import { Eye, Video } from "lucide-react";
import * as amplitude from "@amplitude/analytics-browser";

interface Props {
  search: RivrSearch;
  fetchSearches: any;
}

const CampaignSearchItem = memo(({ search, fetchSearches }: Props) => {
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value;

  const client = useApolloClient();
  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const [isStatusLoading, setIsStatusLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";

  const viewLink = `${RivrLocations.Results}/${search.id}?${updateURLParams(
    searchParams.toString(),
    [
      [ResultsURLParams.SelectedMoment, ""],
      [ResultsURLParams.SelectedTimestamp, ""],
      [ResultsURLParams.SelectedPanel, ""],
    ]
  )}`;

  const [stopSearchAPI] = useMutation(StopSearchMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [setWorkflowStatusAPI] = useMutation(SetSearchWorkflowMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [archiveSearchAPI] = useMutation(SetSearchArchiveStatusMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
      setIsStatusLoading(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [deleteSearchAPI] = useMutation(DeleteSearchMutation, {
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => console.log("Error:", err.extensions.code));
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [insertSearchAPI] = useMutation(SearchMutation, {
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => console.log("Error:", err.extensions.code));
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const [clearCampaignIdSearchAPI] = useMutation(ClearCampaignIdSearchMutation, {
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        graphQLErrors.forEach((err) => console.log("Error:", err.extensions.code));
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsStatusLoading(false);
    },
  });

  const stopSearch = () => {
    setIsStatusLoading(true);
    stopSearchAPI({ variables: { id: search.id } });
    handleAmplitudeTrack("Video Stopped", {
      SearchID: search.id,
    });
  };

  const setSearchWorkflowStatus = (status: string | undefined) => {
    setIsStatusLoading(true);
    setWorkflowStatusAPI({
      variables: { id: search.id, status: status !== undefined ? status : null },
    });
    handleAmplitudeTrack("Video Status Changed", {
      SearchID: search.id,
      Status: status || "analyzed",
    });
  };

  const archiveSearch = (date: Date | undefined) => {
    setIsStatusLoading(true);
    archiveSearchAPI({ variables: { id: search.id, date: date !== undefined ? date : null } });
    handleAmplitudeTrack(date ? "Video Archived" : "Video Unarchived", {
      SearchID: search.id,
    });
  };

  const isArchived = search.archivedAt !== undefined;

  const reprocessSearch = async () => {
    setIsStatusLoading(true);
    try {
      // Step 1: Stop the search
      await stopSearchAPI({ variables: { id: search.id } });

      // Step 2: Fetch the full search data before modifying it
      const { data } = await client.query({
        query: SearchByIDQuery,
        variables: { id: search.id },
        fetchPolicy: "no-cache",
      });

      const fullSearch = data.search_by_pk;

      // Step 3: Mark the search as deleted
      await deleteSearchAPI({
        variables: { id: search.id, deleted_at: new Date().toISOString() },
      });

      // Step 4: Clear campaign_id
      await clearCampaignIdSearchAPI({
        variables: { id: search.id },
      });

      // Step 5: Insert a new search with the same data, ensuring non-null Boolean values
      await insertSearchAPI({
        variables: {
          searchRequest: {
            url: fullSearch.url,
            campaignId: fullSearch.campaign_id,
            userId: fullSearch.user_id,
          },
        },
      });

      // Refresh the searches
      fetchSearches({ campaign: selectedCampaign });
      handleAmplitudeTrack("Video Reanalyzed", { OriginalSearchId: search.id });
    } catch (error) {
      console.error(error);
    } finally {
      setIsStatusLoading(false);
    }
  };

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  const getBadgeForStatus = () => {
    if (isArchived) {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="gray"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Archived
        </Badge>
      );
    }
    if (search.status === "in-progress") {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="yellow"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Analyzing... {search.searchProgress ? `${search.searchProgress}%` : ""}
          <Spinner size="xs" ml={1} mt={0.5} />
        </Badge>
      );
    } else if (search.status === "error") {
      return (
        <Badge
          display="flex"
          borderRadius="sm"
          colorScheme="red"
          py={0.5}
          variant="solid"
          pointerEvents={"none"}
          letterSpacing={0.5}
        >
          Error
        </Badge>
      );
    } else {
      switch (search.workflowStatus) {
        case "completed":
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="blue"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Completed
            </Badge>
          );
        case "reviewing":
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="purple"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Reviewing
            </Badge>
          );
        case undefined:
          return (
            <Badge
              display="flex"
              borderRadius="sm"
              colorScheme="green"
              py={0.5}
              variant="solid"
              pointerEvents={"none"}
              letterSpacing={0.5}
            >
              Analyzed
            </Badge>
          );
      }
    }
  };

  return (
    <VStack
      spacing={"0"}
      alignItems={"flex-start"}
      className={"search-item-wrapper"}
      borderWidth={1}
      borderRadius={"md"}
      h={"fit-content"}
      _hover={{
        ".search-item-details": { bg: "whiteAlpha.300", transition: "background-color 200ms" },
      }}
    >
      <Box position={"relative"} w={"100%"} h={"auto"} opacity={isArchived ? 0.4 : 1}>
        {search.metadata && (
          <Badge
            variant={"solid"}
            position={"absolute"}
            top={3}
            right={3}
            py={0.5}
            textTransform={"none"}
            pointerEvents={"none"}
            letterSpacing={0.5}
          >
            {search.metadata.userName}
          </Badge>
        )}
        <Link
          to={viewLink}
          onClick={() => handleAmplitudeTrack("Video Clicked", { SearchId: search.id })}
        >
          <Image
            src={search.metadata ? search.metadata.thumbnailUrl : undefined}
            borderTopRadius={"md"}
            w={"full"}
            h={"auto"}
            fallbackSrc={ImgNotFound}
          />
        </Link>
      </Box>
      <VStack
        w={"100%"}
        spacing={0}
        mt={"-54px !important"}
        position={"relative"}
        pointerEvents={"none"}
      >
        <HStack
          justifyContent={"flex-start"}
          w={"100%"}
          bg={`linear-gradient(to top, #171923 25%, transparent 100%)`}
          p={3}
          className="search-item-status"
          pointerEvents={"none"}
        >
          <HStack alignItems={"flex-end"} pointerEvents={"none"}>
            {getBadgeForStatus()}

            {search.videoType === "VOD" && (
              <Badge
                colorScheme={"blackAlpha"}
                bg={"blackAlpha.800"}
                variant={"solid"}
                position={"absolute"}
                right={3}
                py={0.5}
                pointerEvents={"none"}
                opacity={isArchived ? 0.4 : 1}
              >
                {search.videoDuration}
              </Badge>
            )}

            {(!search.status || search.status !== "in-progress") && (
              <Box h={"30px"} bg={"gray.900"} pointerEvents={"none"} />
            )}
          </HStack>
        </HStack>
        {search.videoType === "VOD" && search.status === "in-progress" && (
          <VStack w="100%" spacing={-1}>
            <Progress
              size={"sm"}
              colorScheme={"yellow"}
              isIndeterminate={search.searchProgress ? false : true}
              value={search.searchProgress ?? 0}
              w={"100%"}
              className={"search-item-progress"}
            />
            <Progress
              size={"sm"}
              colorScheme={"yellow"}
              isIndeterminate
              w={"100%"}
              mt={"-8px !important"}
            />
          </VStack>
        )}
        {search.videoType === "LIVE" && search.status === "in-progress" && (
          <Progress value={60} size={"sm"} colorScheme={"red"} isIndeterminate w={"100%"} />
        )}
      </VStack>
      <HStack
        justifyContent={"space-between"}
        w={"100%"}
        bg={isArchived ? "whiteAlpha.50" : "whiteAlpha.200"}
        p={3}
        className={"search-item-details"}
        borderBottomRadius={"md"}
        h={"100%"}
        alignItems={"start"}
        transition={"background-color 200ms"}
      >
        <VStack
          alignItems={"start"}
          alignContent={"start"}
          justifyContent={"center"}
          spacing={1.5}
          w={"100%"}
        >
          <HStack
            alignItems={"center"}
            justifyContent={"start"}
            className={"search-item-details-icon-title"}
            w={"100%"}
            spacing={1}
            opacity={isArchived ? 0.4 : 1}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
          >
            {search.originType === originTwitch ? (
              <Image src={TwitchLogo} alt="Twitch Logo" title="Twitch" h="18px" mr={1} />
            ) : search.originType === originYouTube ? (
              <Image src={YouTubeLogo} alt="YouTube Logo" title="YouTube" h="14px" mr={1} />
            ) : (
              <></>
            )}
            <Link
              to={viewLink}
              onClick={() => handleAmplitudeTrack("Video Clicked", { SearchId: search.id })}
            >
              <Text
                className={`lnk-to-result-${search.id}`}
                fontSize={"sm"}
                noOfLines={1}
                wordBreak={"break-all"}
                title={search.videoTitle || "N/A"}
              >
                {search.videoTitle || "N/A"}
              </Text>
            </Link>
          </HStack>
          <HStack w={"100%"} spacing={0} cursor={"default"} justifyContent={"space-between"}>
            <HStack spacing={0} opacity={isArchived ? 0.4 : 1}>
              <Tooltip label={"Date published"}>
                <HStack alignItems={"center"} spacing={1.5} pr={3}>
                  <Video size={18} />
                  <Text fontSize={"sm"} sx={{ textWrap: "nowrap" }}>
                    {search.metadata
                      ? formatDateForDisplay(search.metadata.publishedAt)
                      : "Not available"}
                  </Text>
                </HStack>
              </Tooltip>
              {search.userMoments.length > 0 && (
                <Tooltip
                  label={`${search.userMoments.length} Moment${
                    search.userMoments.length === 1 ? "" : "s"
                  } created`}
                >
                  <HStack alignItems={"center"} spacing={1.5} pr={3}>
                    <Heartbeat size={18} theme={"outline"} />
                    <Text fontSize={"sm"} sx={{ textWrap: "nowrap" }}>
                      {search.userMoments.length}
                    </Text>
                  </HStack>
                </Tooltip>
              )}
              {search.metadata?.viewCount !== undefined && search.metadata?.viewCount > 0 && (
                <Tooltip
                  label={`${search.metadata.viewCount.toLocaleString()} View${
                    search.metadata.viewCount === 1 ? "" : "s"
                  }`}
                >
                  <HStack alignItems={"center"} spacing={1.5} pr={3}>
                    <Eye size={18} />
                    <Text fontSize={"sm"} sx={{ textWrap: "nowrap" }}>
                      {search.metadata.viewCount >= 1_000_000
                        ? `${(search.metadata.viewCount / 1_000_000).toFixed(1)}m`
                        : search.metadata.viewCount >= 1_000
                        ? `${(search.metadata.viewCount / 1_000).toFixed(1)}k`
                        : search.metadata.viewCount.toLocaleString()}
                    </Text>
                  </HStack>
                </Tooltip>
              )}
            </HStack>
            <Menu strategy={"fixed"} placement={"right"}>
              <MenuButton
                as={IconButton}
                aria-label={"More options"}
                icon={<MoreOne size={"20px"} />}
                size={"xs"}
                variant={"ghost"}
                disabled={isStatusLoading}
              />
              <MenuList>
                {["in-progress", "error"].includes(search.status) === false && (
                  <>
                    {!isArchived ? (
                      <>
                        {search.workflowStatus !== undefined && (
                          <MenuItem
                            icon={<BackOne />}
                            onClick={(e) => setSearchWorkflowStatus(undefined)}
                          >
                            Reset to Analyzed
                          </MenuItem>
                        )}
                        {search.workflowStatus !== "reviewing" && (
                          <MenuItem
                            icon={<Eyes />}
                            onClick={(e) => setSearchWorkflowStatus("reviewing")}
                          >
                            Mark Reviewing
                          </MenuItem>
                        )}
                        {search.workflowStatus !== "completed" && (
                          <MenuItem
                            icon={<Check />}
                            onClick={(e) => setSearchWorkflowStatus("completed")}
                          >
                            Mark Completed
                          </MenuItem>
                        )}
                        {!demoUser && (
                          <MenuItem
                            icon={<InboxIn />}
                            onClick={(e) => {
                              archiveSearch(new Date());
                            }}
                          >
                            Archive
                          </MenuItem>
                        )}
                      </>
                    ) : (
                      <MenuItem
                        icon={<InboxOut />}
                        onClick={(e) => {
                          archiveSearch(undefined);
                        }}
                      >
                        Unarchive
                      </MenuItem>
                    )}

                    <MenuDivider />
                  </>
                )}
                <MenuItem
                  as={"a"}
                  href={search.url}
                  target={"_blank"}
                  icon={<ExternalLinkIcon />}
                  onClick={() =>
                    handleAmplitudeTrack("View Video on Platform", {
                      Platform: search.originType,
                      URL: search.url,
                    })
                  }
                >
                  View on {search.originType === originTwitch ? "Twitch" : "YouTube"}
                </MenuItem>
                {search.status === "in-progress" && (
                  <MenuItem icon={<Square theme="filled" />} onClick={stopSearch}>
                    Stop analyzing
                  </MenuItem>
                )}

                {isAdmin && (
                  <>
                    <MenuDivider />
                    <MenuItem icon={<Redo />} onClick={reprocessSearch}>
                      Reanalyze
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  );
});

export default CampaignSearchItem;
