import Login from "../pages/login";
import Search from "../pages/search";
import Campaigns from "../pages/campaigns";
import Account from "../pages/account";
import Results from "../pages/results";
import Privacy from "../pages/privacy";
import Terms from "../pages/terms";
import GoogleDriveCallback from "../pages/google-drive-callback";
import PageNotFound from "../pages/page-not-found";
import Protected from "../Protected";
import AccessDenied from "../pages/access-denied";
import { TwitchOAuth } from "../pages/twitch-oauth";
import KeyManager from "../components/KeyManager/KeyManager";
import { RivrLocations } from "../models/navigation";
import RunManager from "../pages/run-manager";
import { BillingRedirect } from "../pages/billing-redirect";

const mainRoutes = [
  {
    path: "/",
    exact: true,
    element: <Login />,
  },
  {
    path: RivrLocations.Search,
    element: (
      <Protected>
        <Search />
      </Protected>
    ),
  },
  {
    path: RivrLocations.Campaigns,
    element: (
      <Protected>
        <Campaigns />
      </Protected>
    ),
  },
  {
    path: RivrLocations.Account,
    element: (
      <Protected>
        <Account />
      </Protected>
    ),
  },
  {
    path: "/twitch-oauth",
    element: (
      <Protected>
        <TwitchOAuth />
      </Protected>
    ),
  },
  {
    path: "/billing-redirect",
    element: (
      <Protected>
        <BillingRedirect />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.Results}/:videoID`,
    element: (
      <Protected>
        <Results />
      </Protected>
    ),
  },

  {
    path: "/oauth/googledrive/callback",
    element: (
      <Protected>
        <GoogleDriveCallback />
      </Protected>
    ),
  },
  {
    path: RivrLocations.KeyManager,
    element: (
      <Protected>
        <KeyManager />
      </Protected>
    ),
  },
  {
    path: RivrLocations.RunManager,
    element: (
      <Protected>
        <RunManager />
      </Protected>
    ),
  },
  {
    path: RivrLocations.Privacy,
    element: (
      <Protected>
        <Privacy />
      </Protected>
    ),
  },
  {
    path: RivrLocations.Terms,
    element: (
      <Protected>
        <Terms />
      </Protected>
    ),
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: RivrLocations.AccessDenied,
    element: <AccessDenied />,
  },
];

export default mainRoutes;
