import { gql } from "@apollo/client";

// Queries
export const AccountByKindeIdQuery = gql`
  query AccountByKindeId($kinde_id: String!) {
    account(where: { kinde_id: { _eq: $kinde_id } }) {
      id
      kinde_id
      email
      name
      twitch_username
      subscription_status
      subscription_id
      email_notifications
      onboarding_response
      allow_spotlights
      allow_promotions
      allow_feedback
      allow_machine_learning
      memberships {
        organization_id
        role
      }
      tutorial_progress {
        tutorial
      }
    }
  }
`;

export const GetAllAccountsQuery = gql`
  query GetAllAccounts {
    account {
      id
      twitch_username
    }
  }
`;

// Mutations
export const CreateAccountMutation = gql`
  mutation CreateAccount($kinde_id: String!, $email: String!) {
    insert_account_one(object: { kinde_id: $kinde_id, email: $email }) {
      id
      kinde_id
      email
      name
      twitch_username
      subscription_status
      subscription_id
      email_notifications
      onboarding_response
      allow_spotlights
      allow_promotions
      allow_feedback
      allow_machine_learning
      memberships {
        organization_id
        role
      }
      tutorial_progress {
        tutorial
      }
    }
  }
`;

export const UpdateAccountMutation = gql`
  mutation UpdateAccount($id: uuid!, $updates: account_set_input!) {
    update_account_by_pk(pk_columns: { id: $id }, _set: $updates) {
      id
    }
  }
`;
