import { Box, VStack, Flex, Text } from "@chakra-ui/react";
import { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { RivrMention, mentionDisplaySettings } from "../../../../../models/mention";
import CustomTooltip from "./CustomTooltip";

type Sentiment = "Positive" | "Negative" | "Neutral";

interface ChartDataItem {
  channel: string;
  Positive: number;
  Negative: number;
  Neutral: number;
  totalMentions: number;
}

const COLORS: Record<Sentiment, string> = {
  Positive: "#48BB78",
  Neutral: "#4299e1",
  Negative: "#F56565",
};

interface MentionsPerChannelProps {
  mentions: RivrMention[];
}

// Custom tick component for XAxis
const CustomXAxisTick: React.FC<any> = ({ x, y, payload }) => {
  const text = payload.value;
  const maxLabelLength = 9;

  let displayText = text;
  if (text.length > maxLabelLength) {
    displayText = text.substring(0, maxLabelLength) + "...";
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-2}
        dy={4}
        textAnchor="end"
        fill="#ffffff"
        transform="rotate(-90)"
        style={{ fontSize: 12 }}
      >
        <title>{text}</title>
        {displayText}
      </text>
    </g>
  );
};

const MentionsPerChannel: React.FC<MentionsPerChannelProps> = ({ mentions }) => {
  const [barVisibility, setBarVisibility] = useState<Record<Sentiment, boolean>>({
    Positive: true,
    Neutral: true,
    Negative: true,
  });

  // Process mentions data
  const data: ChartDataItem[] = useMemo(() => {
    // Group mentions by channel and sentiment
    const groupedData: Record<string, Record<Sentiment, number>> = {};

    const positiveThreshold = mentionDisplaySettings["ASR positive"].featureThreshold;
    const negativeThreshold = -mentionDisplaySettings["ASR negative"].featureThreshold;

    mentions.forEach((mention) => {
      // Only consider Speaker mentions
      if (mention.type !== "Speech") return;

      const channel = mention.channel;

      // Initialize channel entry if it doesn't exist
      if (!groupedData[channel]) {
        groupedData[channel] = { Positive: 0, Negative: 0, Neutral: 0 };
      }

      // Determine sentiment category
      let sentiment: Sentiment = "Neutral";
      if (mention.sentiment !== null && mention.sentiment !== undefined) {
        if (mention.sentiment >= positiveThreshold) sentiment = "Positive";
        else if (mention.sentiment <= negativeThreshold) sentiment = "Negative";
        else sentiment = "Neutral";
      }

      groupedData[channel][sentiment]++;
    });

    // Convert grouped data to array and sort channels by total mentions
    const chartData = Object.keys(groupedData)
      .map((channel) => ({
        channel,
        Positive: groupedData[channel].Positive,
        Negative: groupedData[channel].Negative,
        Neutral: groupedData[channel].Neutral,
        totalMentions:
          groupedData[channel].Positive +
          groupedData[channel].Negative +
          groupedData[channel].Neutral,
      }))
      .sort((a, b) => b.totalMentions - a.totalMentions); // Sort by total mentions

    return chartData;
  }, [mentions]);

  // Calculate total counts for each sentiment
  const totalCounts: Record<Sentiment, number> = useMemo(() => {
    const totals: Record<Sentiment, number> = { Positive: 0, Negative: 0, Neutral: 0 };
    data.forEach((item) => {
      totals.Positive += item.Positive;
      totals.Negative += item.Negative;
      totals.Neutral += item.Neutral;
    });
    return totals;
  }, [data]);

  const handleLegendClick = (entry: { name: Sentiment }) => {
    const sentiment = entry.name;
    setBarVisibility((prev) => ({
      ...prev,
      [sentiment]: !prev[sentiment],
    }));
  };

  const renderLegend = () => {
    return (
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {(Object.keys(COLORS) as Sentiment[]).map((key, index) => {
          const isActive = barVisibility[key];
          return (
            <li
              key={`item-${index}`}
              onClick={() => handleLegendClick({ name: key })}
              style={{
                marginRight: 20,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                opacity: isActive ? 1 : 0.5,
                fontSize: "0.8rem",
                color: COLORS[key],
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: 8,
                  width: 12,
                  height: 12,
                  backgroundColor: COLORS[key],
                }}
              />
              <span>{`${key} (${totalCounts[key].toLocaleString()})`}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <VStack w="100%" align="start">
      <Box w="100%">
        {data.length === 0 ? (
          <Flex align="center" justify="center" color="gray.300" p={8} h="300px">
            <Text>No mentions found for the provided keywords</Text>
          </Flex>
        ) : (
          <ResponsiveContainer width="100%" height={399}>
            <BarChart
              data={data}
              margin={{ top: 10, right: 10, left: -20, bottom: 80 }}
              reverseStackOrder
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#ffffff" strokeOpacity={0.2} />
              <XAxis dataKey="channel" tick={<CustomXAxisTick />} interval={0} />
              <YAxis tick={{ fill: "#ffffff", fontSize: 12 }} allowDecimals={false} />
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="top" height={32} content={renderLegend} />
              <Bar
                isAnimationActive={false}
                dataKey="Positive"
                fill={COLORS.Positive}
                name="Positive"
                stackId="a"
                hide={!barVisibility.Positive}
              />
              <Bar
                isAnimationActive={false}
                dataKey="Neutral"
                fill={COLORS.Neutral}
                name="Neutral"
                stackId="a"
                hide={!barVisibility.Neutral}
              />
              <Bar
                isAnimationActive={false}
                dataKey="Negative"
                fill={COLORS.Negative}
                name="Negative"
                stackId="a"
                hide={!barVisibility.Negative}
              />
              <Brush
                travellerWidth={16}
                y={360}
                height={24}
                fill="rgba(255, 255, 255, 0.08)"
                stroke="rgba(255, 255, 255, 0.24)"
                tickFormatter={() => ""}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </VStack>
  );
};

export default MentionsPerChannel;
