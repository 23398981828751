import { Box, Text, HStack, VStack, Icon, LinkBox, Flex } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import LinkedHeading from "./KbLinkedHeading";

interface Article {
  title: string;
  description: string;
  icon: React.ElementType;
  link: string;
}

interface RelatedArticlesProps {
  articles: Article[];
}

const RelatedArticles: React.FC<RelatedArticlesProps> = ({ articles }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <Box>
      <LinkedHeading as="h1" fontSize="xl" id="related-articles" pt={6} pb={4}>
        Related articles
      </LinkedHeading>
      <Flex
        direction={{ base: "column", lg: "row" }}
        gap={4}
        wrap="nowrap"
        justifyContent="space-between"
        mt={4}
      >
        {articles.map((article, index) => (
          <LinkBox
            key={index}
            flex="1"
            onClick={() => navigate(`${article.link}?${searchParams.toString()}`)}
          >
            <Box
              bg="whiteAlpha.100"
              py={4}
              px={6}
              borderRadius="md"
              transition=".2s"
              borderWidth={1}
              borderColor="gray.600"
              _hover={{ bg: "whiteAlpha.200" }}
              cursor="pointer"
            >
              <VStack alignItems="flex-start">
                <HStack mt={2}>
                  <Icon as={article.icon} boxSize={4} />
                  <Text fontSize="lg" fontWeight="medium" pb={0} lineHeight={0}>
                    {article.title}
                  </Text>
                </HStack>
                <Text pb={1} fontSize="sm" color="gray.400">
                  {article.description}
                </Text>
              </VStack>
            </Box>
          </LinkBox>
        ))}
      </Flex>
    </Box>
  );
};

export default RelatedArticles;
