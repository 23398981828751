import { useSearchParams } from "react-router-dom";
import { Select } from "@chakra-ui/react";
import { OrganizationMembership } from "../../models/rivrOrganization";
import { RivrCampaign, campaignFromQueryResult } from "../../models/rivrCampaign";
import { useContext, useMemo } from "react";
import { CampaignSubPages, CampaignURLParams, updateURLParams } from "../../models/navigation";
import { useQuery } from "@apollo/client";
import { AccountContext } from "../../context/AccountContext";
import { OrgCampaignsQuery } from "../../api/campaign";

const CampaignSelect = () => {
  const { memberships } = useContext(AccountContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";
  const selectedPage = searchParams.get(CampaignURLParams.SubPage) || CampaignSubPages.Campaigns;
  const sortOption = searchParams.get(CampaignURLParams.ChannelSortOption) || "channel";
  const sortDirection = searchParams.get(CampaignURLParams.ChannelSortDirection) || "asc";

  const selectedMembership = useMemo(() => {
    return selectedOrg !== ""
      ? memberships.find((m: OrganizationMembership) => m.organization.id === selectedOrg)
      : memberships[0];
  }, [selectedOrg, memberships]);

  const { data: campaignsData } = useQuery(OrgCampaignsQuery, {
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
    variables: { org_id: selectedMembership?.organization.id },
  });

  const campaigns: RivrCampaign[] = useMemo(() => {
    if (campaignsData) {
      return campaignsData.campaign.map((c: any) => campaignFromQueryResult(c));
    } else return [];
  }, [campaignsData]);

  const handleCampaignSelect = (campaign: string) => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.SelectedCampaign, campaign],
      [CampaignURLParams.SelectedChannels, []],
      [CampaignURLParams.ChannelSortOption, sortOption],
      [CampaignURLParams.ChannelSortDirection, sortDirection],
      [
        CampaignURLParams.SelectedStatuses,
        ["analyzing", "analyzed", "reviewing", "completed", "error"],
      ],
      [CampaignURLParams.MomentTypeFilter, "all"],
      [CampaignURLParams.MomentStatusFilter, ["favorited"]],
      [CampaignURLParams.SelectedLanguages, []],
    ];

    if (selectedPage === CampaignSubPages.Campaigns) {
      newParams.push([CampaignURLParams.SubPage, CampaignSubPages.Analyze]);
    }

    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: false });
  };

  return (
    <Select
      size={"sm"}
      borderRadius={"md"}
      placeholder={"Select a campaign"}
      textOverflow={"ellipsis"}
      overflow={"hidden"}
      value={selectedCampaign}
      w={"max-content"}
      maxW={60}
      variant={"solid"}
      bg={"whiteAlpha.200"}
      _hover={{ bg: "whiteAlpha.300" }}
      onChange={(e) => handleCampaignSelect(e.target.value)}
    >
      {campaigns
        .sort((a: any, b: any) => {
          return a.title.localeCompare(b.title);
        })
        .map((c: any) => (
          <option key={c.id} value={c.id}>
            {c.title}
          </option>
        ))}
    </Select>
  );
};

export default CampaignSelect;
