import { HStack, IconButton } from "@chakra-ui/react";
import { PlayOne } from "@icon-park/react";
import { TypeAnimation } from "react-type-animation";

const MockSearchbar = () => {
  return (
    <HStack
      w={"100%"}
      maxW={"30rem"}
      p={2}
      pl={6}
      bg={"whiteAlpha.200"}
      borderRadius={"full"}
      borderWidth={1}
      overflow={"hidden"}
      alignItems={"center"}
      justifyContent={"space-between"}
      pointerEvents={"none"}
      userSelect={"none"}
      fontSize={"xl"}
      shadow={"lg"}
    >
      <TypeAnimation
        sequence={[
          "Discover your best moments, instantly",
          2000,
          "Scrub less, create more",
          2000,
          "Find your next viral clip",
          2000,
        ]}
        speed={50}
        deletionSpeed={80}
        wrapper={"p"}
        repeat={Infinity}
        pointerEvents={"none"}
        userSelect={"none"}
      />
      <IconButton
        borderRadius={"full"}
        colorScheme={"green"}
        pointerEvents={"none"}
        userSelect={"none"}
        icon={<PlayOne size={24} theme={"filled"} />}
      />
    </HStack>
  );
};

export default MockSearchbar;
