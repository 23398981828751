import { Center, VStack, HStack, Image, Text, Heading, Button } from "@chakra-ui/react";
import RivrBot from "../assets/rivr-bot.png";
import { ArrowLeft } from "@icon-park/react";

const AccessDenied = () => {
  return (
    <Center w="100vw" h="100vh" p={6}>
      <VStack spacing={8} justifyContent={"center"}>
        <HStack spacing={3} flexWrap={"nowrap"} h={28} justifyContent={"center"}>
          <Text color="rivr.red" fontSize={"9xl"} fontWeight={"extrabold"}>
            4
          </Text>
          <Image src={RivrBot} height="7rem" borderRadius={"full"} />
          <Text color="rivr.red" fontSize={"9xl"} fontWeight={"extrabold"}>
            3
          </Text>
        </HStack>
        <VStack>
          <Heading as="h1">Access Denied</Heading>
          <Text color="gray.300">Sorry, you do not have permission to access this page.</Text>
        </VStack>
        <Button colorScheme="gray" size="lg" leftIcon={<ArrowLeft />} as="a" href="/">
          Back to Rivr
        </Button>
      </VStack>
    </Center>
  );
};

export default AccessDenied;
