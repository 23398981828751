import { gql } from "@apollo/client";

// Queries
export const AuthURLQuery = gql`
  query AuthURL($keyCodeRef: String!, $userId: String!) {
    getAuthURL(data: { key_code_ref: $keyCodeRef, user_id: $userId }) {
      url
      session_id
    }
  }
`;

export const AccessTokenQuery = gql`
  query AccessToken($sessionId: String!) {
    getAccessToken(data: { session_id: $sessionId }) {
      access_token
      access_token_expiry
    }
  }
`;

export const RevokeConnectedAppTokenQuery = gql`
  query RevokeConnectedAppToken($sessionId: String!) {
    revokeConnectedAppToken(data: { session_id: $sessionId }) {
      result
    }
  }
`;
