import { Select } from "@chakra-ui/react";

interface OrganizationSelectorProps {
  organizations: { id: string; name: string }[];
  selectedOrganization: string;
  setSelectedOrganization: (id: string) => void;
}

const OrganizationSelector = ({
  organizations,
  selectedOrganization,
  setSelectedOrganization,
}: OrganizationSelectorProps) => (
  <Select
    borderRadius={"md"}
    size={"sm"}
    placeholder="Select Organization"
    minW={"max-content"}
    maxW={"max-content"}
    value={selectedOrganization || ""}
    onChange={(e) => setSelectedOrganization(e.target.value)}
  >
    {organizations
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((org) => (
        <option key={org.id} value={org.id}>
          {org.name}
        </option>
      ))}
  </Select>
);

export default OrganizationSelector;
