import { ContentBox, Dimensions } from "../models/action";

export const changeRatio = (box: ContentBox, ratio: number, dims: Dimensions) => {
  const newBox = absoluteToRelative(
    changeRatioAbsolute(relativeToAbsolute(box, dims), ratio),
    dims
  );
  return constrainRelative(newBox);
};

const changeRatioAbsolute = (box: ContentBox, ratio: number) => {
  const volume = box.width * box.height;
  const width = (ratio * volume) ** 0.5;
  const height = volume / width;
  const x = box.x + (box.width - width) / 2;
  const y = box.y + (box.height - height) / 2;
  return { x, y, width, height };
};

const constrainRelative = (box: ContentBox) => {
  let { width, height } = box;
  if (width > 1) {
    height /= width;
    width = 1;
  }
  if (height > 1) {
    width /= height;
    height = 1;
  }
  let x = box.x + (box.width - width) / 2;
  let y = box.y + (box.height - height) / 2;
  if (x < 0) x = 0;
  else if (x + width > 1) x = 1 - width;
  if (y < 0) y = 0;
  else if (y + height > 1) y = 1 - height;
  return { x, y, width, height };
};

export const relativeToAbsolute = (box: ContentBox, dims: Dimensions) => {
  return {
    x: box.x * dims.width,
    y: box.y * dims.height,
    width: box.width * dims.width,
    height: box.height * dims.height,
  };
};

export const absoluteToRelative = (box: ContentBox, dims: Dimensions) => {
  return {
    x: box.x / dims.width,
    y: box.y / dims.height,
    width: box.width / dims.width,
    height: box.height / dims.height,
  };
};
