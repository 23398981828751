import { KBSubPages, RivrLocations } from "../models/navigation";
import Protected from "../Protected";
import KbIntroduction from "../pages/knowledgebase/kbintroduction";
import KbGettingStarted from "../pages/knowledgebase/kbgettingstarted";
import KbSearch from "../pages/knowledgebase/kbsearch";
import KbResults from "../pages/knowledgebase/kbresults";
import KbSupport from "../pages/knowledgebase/kbsupport";
import KbCampaigns from "../pages/knowledgebase/kbcampaigns";
import KbTimeline from "../pages/knowledgebase/kbtimeline";
import KbMoments from "../pages/knowledgebase/kbmoments";
import KbChatSpeech from "../pages/knowledgebase/kbchatspeech";
import KbOrganization from "../pages/knowledgebase/kborganization";
import KbOrgCampaigns from "../pages/knowledgebase/kborgcampaigns";
import KbAnalyze from "../pages/knowledgebase/kbanalyze";
import KbDiscover from "../pages/knowledgebase/kbdiscover";

const knowledgebaseRoutes = [
  {
    path: RivrLocations.KnowledgeBase,
    element: (
      <Protected>
        <KbIntroduction />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.GettingStarted}`,
    element: (
      <Protected>
        <KbGettingStarted />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.Campaigns}`,
    element: (
      <Protected>
        <KbCampaigns />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.Search}`,
    element: (
      <Protected>
        <KbSearch />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.Results}`,
    element: (
      <Protected>
        <KbResults />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.Timeline}`,
    element: (
      <Protected>
        <KbTimeline />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.Moments}`,
    element: (
      <Protected>
        <KbMoments />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.ChatSpeech}`,
    element: (
      <Protected>
        <KbChatSpeech />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.Support}`,
    element: (
      <Protected>
        <KbSupport />
      </Protected>
    ),
  },
  // Org Pages
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.Organization}`,
    element: (
      <Protected>
        <KbOrganization />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.OrgCampaigns}`,
    element: (
      <Protected>
        <KbOrgCampaigns />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.Analyze}`,
    element: (
      <Protected>
        <KbAnalyze />
      </Protected>
    ),
  },
  {
    path: `${RivrLocations.KnowledgeBase}/${KBSubPages.Discover}`,
    element: (
      <Protected>
        <KbDiscover />
      </Protected>
    ),
  },
];

export default knowledgebaseRoutes;
