import { Text, Box, Heading, VStack, HStack, Divider } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { Loading } from "../Loading/Loading";
import KeyGenerator from "./KeyGenerator";
import KeyCampaignLinker from "./KeyCampaignLinker";
import OrganizationSelector from "./OrganizationSelector";
import CampaignFilter from "./CampaignFilter";
import KeyTable from "./KeyTable";
import KeyStats from "./KeyStats";
import {
  useFetchOrganizations,
  useFetchAccountsAndKeys,
  useFetchCampaigns,
} from "../../hooks/useKeyManager";
import { Navigate, useSearchParams } from "react-router-dom";
import { CampaignURLParams } from "../../models/navigation";
import Cookies from "js-cookie";

const KeyManager = () => {
  const [searchParams] = useSearchParams();

  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const [filter, setFilter] = useState({
    campaignId: searchParams.get(CampaignURLParams.SelectedCampaign) || "",
  });
  const [viewFilter, setViewFilter] = useState<"all" | "claimed" | "unclaimed">("all");
  const [selectedOrganization, setSelectedOrganization] = useState<string>(
    searchParams.get(CampaignURLParams.SelectedOrganization) || ""
  );
  const [reloadTable, setReloadTable] = useState(false);

  const organizations = useFetchOrganizations().sort((a, b) => a.name.localeCompare(b.name));
  const {
    accounts,
    data,
    loading: loadingAccountsAndKeys,
  } = useFetchAccountsAndKeys(selectedOrganization, reloadTable);
  const { campaigns, loading: loadingCampaigns } = useFetchCampaigns(selectedOrganization);

  useEffect(() => {
    setReloadTable(false);
  }, [reloadTable]);

  const keysInOrg = data?.length || 0;
  const keysInCampaign =
    data?.filter((key) => filter.campaignId === "" || key.campaign_id === filter.campaignId)
      .length || 0;
  const keysClaimed =
    data?.filter((key) => {
      if (filter.campaignId) {
        return key.campaign_id === filter.campaignId && key.claimed_by !== null;
      }
      return key.claimed_by !== null;
    }).length || 0;

  const keysUnclaimed =
    data?.filter((key) => {
      if (filter.campaignId) {
        return key.campaign_id === filter.campaignId && key.claimed_by === null;
      }
      return key.claimed_by === null;
    }).length || 0;

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const filteredData =
    data?.filter((key) => {
      return (
        (filter.campaignId === "" || key.campaign_id === filter.campaignId) &&
        (viewFilter === "all" ||
          (viewFilter === "claimed" && key.claimed_by !== null) ||
          (viewFilter === "unclaimed" && key.claimed_by === null))
      );
    }) || [];

  const exportToCSV = (type: "full" | "client") => {
    let headers: string[];
    let rows: (string | number)[][];

    if (type === "client") {
      headers = ["Key Name", "Access Key"];
      rows = filteredData.map((key) => [key.key_name, key.access_key]);
    } else {
      headers = [
        "Key Name",
        "Access Key",
        "Claimed By",
        "Twitch Username",
        "Campaign ID",
        "Active Duration",
        "Claimed At",
        "Expires At",
      ];
      rows = filteredData.map((key) => [
        key.key_name,
        key.access_key,
        key.claimed_by,
        accounts[key.claimed_by] || "",
        key.campaign_id,
        key.active_duration,
        new Date(key.claimed_at).toLocaleString(),
        new Date(key.expires_at).toLocaleString(),
      ]);
    }

    const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", `access_keys_${type}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!isAdmin) return <Navigate to="/" />;

  if (loadingAccountsAndKeys || loadingCampaigns) return <Loading variant={"large"} />;

  return (
    <Box p={4} w={"100%"} h={"100vh"} overflow="hidden">
      <VStack w={"100%"} align={"start"} spacing={4} h="100%">
        <HStack w={"100%"} justify="space-between" align={"start"} wrap={"wrap"}>
          <VStack align={"start"} spacing={1}>
            <Heading as="h1" size="lg">
              Key Manager
            </Heading>
            <Text color={"gray.300"}>Create, manage, and view campaign keys</Text>
          </VStack>
          <KeyStats
            keysInOrg={keysInOrg}
            keysInCampaign={keysInCampaign}
            keysClaimed={keysClaimed}
            keysUnclaimed={keysUnclaimed}
            campaignSelected={filter.campaignId !== ""}
          />
        </HStack>
        <HStack w={"100%"}>
          <OrganizationSelector
            organizations={organizations}
            selectedOrganization={selectedOrganization}
            setSelectedOrganization={setSelectedOrganization}
          />
          <CampaignFilter
            campaigns={campaigns}
            filter={filter}
            handleFilterChange={handleFilterChange}
            viewFilter={viewFilter}
            setViewFilter={setViewFilter}
            exportToCSV={exportToCSV}
          />
          <KeyGenerator
            campaigns={campaigns}
            selectedOrganization={selectedOrganization}
            onKeysGenerated={() => setReloadTable(true)} // Pass function to reload table
          />
          <KeyCampaignLinker campaigns={campaigns} selectedOrganization={selectedOrganization} />
        </HStack>
        {selectedOrganization && (
          <>
            <Divider />
            <KeyTable
              data={filteredData}
              accounts={accounts}
              viewFilter={viewFilter}
              keysClaimed={keysClaimed}
              keysUnclaimed={keysUnclaimed}
            />
          </>
        )}
      </VStack>
    </Box>
  );
};

export default KeyManager;
