import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Heading,
  VStack,
  HStack,
  Divider,
  Text,
  AbsoluteCenter,
} from "@chakra-ui/react";
import DownloadsItem from "./DownloadsItem";
import { useApolloClient } from "@apollo/client";
import { UserClipRequestsQuery } from "../../api/user-clip-request";
import { useEffect, useState } from "react";
import { Moment } from "../../models/moment";
import { Action } from "../../models/action";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  accountId: string;
  downloaderRequest: (
    moments: Moment[],
    searchId: string,
    actions?: Action[],
    forVerticalFormat?: boolean
  ) => Promise<void>;
  isOrgMember: boolean;
}

const DownloadsDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  accountId,
  downloaderRequest,
  isOrgMember,
}: Props) => {
  const client = useApolloClient();

  const [downloadHistory, setDownloadHistory] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  const fetchHistory = async () => {
    const downloads = await client.query({
      query: UserClipRequestsQuery,
      variables: {
        account_id: accountId,
        requested_at: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7),
      },
    });

    setDownloadHistory(downloads.data.user_clip_request);
    setDataFetched(true);
  };

  const downloadsList = downloadHistory
    .filter(
      // We only want requests that have a valid result which hasn't expired
      (download: any) =>
        download.clip_request.clip_results.length > 0 &&
        download.clip_request.clip_results[0].url &&
        new Date(download.clip_request.clip_results[0].expires_at).getTime() > Date.now()
    )
    .sort(
      (a: any, b: any) => new Date(b.requested_at).getTime() - new Date(a.requested_at).getTime()
    )
    .reduce((acc: any[], download: any, index: number, sortedDownloads: any[]) => {
      const downloadDate = new Date(download.requested_at);
      const today = new Date();
      const daysAgo = Math.floor(
        (today.getTime() - downloadDate.getTime()) / (1000 * 60 * 60 * 24)
      );

      const previousDownloadDate =
        index > 0 ? new Date(sortedDownloads[index - 1].requested_at) : null;
      const previousDaysAgo = previousDownloadDate
        ? Math.floor((today.getTime() - previousDownloadDate.getTime()) / (1000 * 60 * 60 * 24))
        : null;

      // Add a divider if the current download and the previous download were requested on different days
      if (index !== 0 && daysAgo !== previousDaysAgo) {
        acc.push(
          <HStack w={"100%"} py={1} key={`divider-${daysAgo}`}>
            <Divider />
            <Text fontSize={"xs"} color={"gray.300"} minW={"fit-content"}>
              {`${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`}
            </Text>
            <Divider />
          </HStack>
        );
      }

      acc.push(
        <DownloadsItem
          key={index}
          download={download}
          downloaderRequest={downloaderRequest}
          isOrgMember={isOrgMember}
        />
      );

      return acc;
    }, []);

  useEffect(() => {
    if (isOpen && !dataFetched) {
      fetchHistory();
    }
    if (!isOpen && dataFetched) {
      setDataFetched(false);
    }
  }, [isOpen, fetchHistory, dataFetched]);

  return (
    <Drawer
      isOpen={isOpen}
      placement={"right"}
      onClose={onClose}
      size={"sm"}
      closeOnOverlayClick={true}
    >
      <DrawerOverlay />
      <DrawerContent mt={16} borderLeftWidth={1}>
        <DrawerCloseButton mt={0.5} />
        <DrawerHeader px={4} py={4} borderBottomWidth={1} bg={"blackAlpha.300"}>
          <Heading fontSize={"lg"} fontWeight={"normal"}>
            Downloads
          </Heading>
        </DrawerHeader>
        <DrawerBody px={4} pt={2} sx={{ scrollbarWidth: "thin" }}>
          {downloadsList.length > 0 ? (
            <VStack py={2}>{downloadsList}</VStack>
          ) : (
            <AbsoluteCenter w={"100%"}>
              <Text color={"gray.300"} w={"100%"} textAlign={"center"} sx={{ textWrap: "pretty" }}>
                No downloads in the last 7 days
              </Text>
            </AbsoluteCenter>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DownloadsDrawer;
