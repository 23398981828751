import { useState, useContext } from "react";
import {
  VStack,
  Box,
  HStack,
  Badge,
  Text,
  Icon,
  List,
  ListItem,
  Tooltip,
  Button,
  ButtonGroup,
} from "@chakra-ui/react";
import { PlanType, planDetails } from "../../models/plan";
import { Loading } from "../Loading/Loading";
import { AccountContext } from "../../context/AccountContext";
import { PaymentPortalQuery } from "../../api/actions";
import { useQuery } from "@apollo/client";
import * as amplitude from "@amplitude/analytics-browser";
import { CircleCheckBig, Rocket } from "lucide-react";

type PlanCardProps = {
  orgId?: string;
  planType: PlanType;
  planAvailable: boolean;
  showSubscribe: boolean;
  subscriptionExists: boolean;
  displayVariant?: "monthly" | "yearly";
};

const PlanCard = ({
  orgId,
  planType,
  planAvailable,
  showSubscribe,
  subscriptionExists,
  displayVariant,
}: PlanCardProps) => {
  const selectedPlan = planDetails[planType];
  const [isYearly, setIsYearly] = useState(
    displayVariant === undefined ? !Boolean(selectedPlan.monthly) : displayVariant === "yearly"
  );
  const { account } = useContext(AccountContext);

  const { loading, data: urls } = useQuery(PaymentPortalQuery, {
    variables: orgId ? { org: orgId } : {},
    skip: !subscriptionExists || !showSubscribe,
  });

  if (loading) return <Loading variant={"small"} />;

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <VStack w={"100%"} align={"center"} maxW={"30rem"} spacing={4}>
      {selectedPlan.monthly && selectedPlan.yearly && !displayVariant && (
        <HStack w={"full"}>
          <ButtonGroup size={"sm"} variant={"outline"} w={"full"} isAttached>
            <Button
              w={"full"}
              onClick={() => {
                setIsYearly(false);
                handleAmplitudeTrack("Plan Billing Frequency Changed", {
                  Plan: selectedPlan.planName,
                  Frequency: "Monthly",
                });
              }}
              isActive={!isYearly}
            >
              Monthly
            </Button>
            <Button
              w={"full"}
              onClick={() => {
                setIsYearly(true);
                handleAmplitudeTrack("Plan Billing Frequency Changed", {
                  Plan: selectedPlan.planName,
                  Frequency: "Yearly",
                });
              }}
              isActive={isYearly}
            >
              Yearly
              <Badge
                colorScheme={"green"}
                ml={2}
                py={0.5}
                letterSpacing={"wider"}
                fontWeight={"normal"}
              >
                2 Months Free
              </Badge>
            </Button>
          </ButtonGroup>
        </HStack>
      )}
      <Box
        bg={"whiteAlpha.200"}
        borderRadius={"lg"}
        h={"100%"}
        p={6}
        borderWidth={2}
        borderColor={selectedPlan.borderColor}
        w={"100%"}
      >
        <VStack alignItems={"flex-start"} flex={1}>
          <Badge
            colorScheme={selectedPlan.badgeColor}
            fontSize={16}
            letterSpacing={"widest"}
            w={"100%"}
            textAlign={"center"}
            borderRadius={"md"}
            flexWrap={"wrap"}
          >
            {selectedPlan.planHeader}
          </Badge>
          <HStack justifyContent={"space-between"} alignItems={"center"} w={"100%"}>
            <VStack gap={-1} py={3} align={"flex-start"}>
              <Text fontSize={32} fontWeight={"semibold"}>
                {selectedPlan.planName}
              </Text>
              <Text fontSize={"xs"} color={"gray.300"}>
                {selectedPlan.planNameSub}
              </Text>
            </VStack>
            {planAvailable && (
              <VStack gap={-1} alignItems={"flex-end"}>
                <HStack>
                  <Text
                    fontSize={20}
                    fontWeight={"semibold"}
                    textDecoration={"line-through"}
                    color={"gray.300"}
                  >
                    {isYearly
                      ? selectedPlan.yearly?.price.previous
                      : selectedPlan.monthly?.price.previous}
                  </Text>
                  <Text fontSize={32} fontWeight={"semibold"}>
                    {isYearly
                      ? selectedPlan.yearly?.price.regular
                      : selectedPlan.monthly?.price.regular}
                  </Text>
                </HStack>
                <Text fontSize={"xs"} color={"gray.300"}>
                  billed {isYearly ? "yearly" : "monthly"}, USD
                </Text>
              </VStack>
            )}
          </HStack>
          <List spacing={2} pb={4}>
            {selectedPlan.features.map((feature, index) => (
              <ListItem key={index}>
                <HStack alignItems={"center"} justifyContent={"flex-start"}>
                  <Icon as={CircleCheckBig} color={"green.300"} boxSize={4} />
                  {feature.tooltip ? (
                    <Tooltip label={feature.tooltip} placement="right" hasArrow>
                      <Text textDecoration={"underline dotted gray"} textUnderlineOffset={5}>
                        {feature.name}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text>{feature.name}</Text>
                  )}
                </HStack>
              </ListItem>
            ))}
          </List>
          {showSubscribe &&
            (subscriptionExists ? (
              <Button
                colorScheme={"green"}
                onClick={() => {
                  handleAmplitudeTrack("Manage Subscription Clicked", {
                    Plan: selectedPlan.planName,
                    SubscriptionExists: true,
                  });
                  window.open(urls?.getPaymentPortal.customerPortal, "_blank");
                }}
                size={"lg"}
                w={"full"}
                minH={12}
              >
                Manage subscription
              </Button>
            ) : (
              <Button
                colorScheme={"green"}
                onClick={() => {
                  handleAmplitudeTrack("Subscribe Button Clicked", {
                    Plan: selectedPlan.planName,
                    BillingFrequency: isYearly ? "Yearly" : "Monthly",
                    OrganizationID: orgId || null,
                    AccountID: account.id || null,
                  });
                  window.location.href = `${
                    isYearly ? selectedPlan.yearly?.url : selectedPlan.monthly?.url
                  }${orgId ?? account.id}`;
                }}
                leftIcon={<Rocket />}
                size={"md"}
                w={"full"}
                minH={12}
              >
                Subscribe to {selectedPlan.planName} {selectedPlan.planNameSub}
              </Button>
            ))}
        </VStack>
      </Box>
    </VStack>
  );
};

export default PlanCard;
