import { gql } from "@apollo/client";

// Queries
// Get an org's campaigns
export const OrgCampaignsQuery = gql`
  query OrgCampaigns($org_id: uuid) {
    campaign(where: { organization_id: { _eq: $org_id }, deleted_at: { _is_null: true } }) {
      user_id
      title
      id
      created_at
      archived_at
      status
      image
      description
      start_date
      end_date
      organization_id
      searches {
        created_at
        video_duration
        channel: metadata(path: "$.channel")
        user_name: metadata(path: "$.user_name")
        language: metadata(path: "$.language")
      }
    }
  }
`;

// Get searches from a chosen campaign
export const CampaignSearchesQuery = gql`
  query CampaignSearches($campaign: uuid) {
    search(where: { campaign_id: { _eq: $campaign } }) {
      id
      url
      origin_type
      video_title
      video_id
      video_type
      video_duration
      search_progress
      status
      created_at
      metadata
      workflow_status
      archived_at
      moments(where: { deleted: { _eq: false } }) {
        id
        search_id
        title
        start_time
        end_time
        tags
        annotations
        archived_at
        favorited
      }
      recommended_moments(where: { rejected: { _eq: false } }) {
        id
        search_id
        title
        moment_time
        annotations
        archived_at
        favorited
        tags
      }
    }
  }
`;

// Mutations
// Create a new campaign
export const InsertCampaignMutation = gql`
  mutation (
    $user_id: String
    $title: String
    $status: String
    $image: bytea
    $description: String
    $start_date: timestamptz
    $end_date: timestamptz
    $organization_id: uuid
  ) {
    insert_campaign_one(
      object: {
        user_id: $user_id
        title: $title
        status: $status
        image: $image
        description: $description
        start_date: $start_date
        end_date: $end_date
        organization_id: $organization_id
      }
    ) {
      id
    }
  }
`;

// Set campaign status to desired state
export const SetCampaignStatusMutation = gql`
  mutation SetCampaignStatus($id: uuid, $status: String) {
    update_campaign(where: { id: { _eq: $id } }, _set: { status: $status }) {
      returning {
        id
        status
      }
    }
  }
`;

// Set campaign archived at and status to desired states
export const SetCampaignArchiveStatusMutation = gql`
  mutation SetCampaignArchiveStatus($id: uuid, $date: timestamptz, $status: String) {
    update_campaign(where: { id: { _eq: $id } }, _set: { archived_at: $date, status: $status }) {
      returning {
        id
        archived_at
        status
      }
    }
  }
`;

// Set campaign deleted at to desired state
export const SetCampaignDeleteStatusMutation = gql`
  mutation SetCampaignDeleteStatus($id: uuid, $date: timestamptz) {
    update_campaign(where: { id: { _eq: $id } }, _set: { deleted_at: $date }) {
      returning {
        id
        deleted_at
      }
    }
  }
`;

// Update campaign with desired parameters
export const UpdateCampaignMutation = gql`
  mutation UpdateCampaign($id: uuid, $updates: campaign_set_input) {
    update_campaign(where: { id: { _eq: $id } }, _set: $updates) {
      returning {
        id
      }
    }
  }
`;
