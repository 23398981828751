import { gql } from "@apollo/client";

// Mutations
export const UpsertMomentMutation = gql`
  mutation UpsertMoment($object: moment_insert_input!) {
    insert_moment_one(
      object: $object
      on_conflict: {
        constraint: moment_pkey
        update_columns: [
          title
          tags
          start_time
          end_time
          updated_at
          deleted
          recommended_moment_id
          annotations
        ]
      }
    ) {
      id
    }
  }
`;

export const UpdateMomentMutation = gql`
  mutation UpdateMoment($id: uuid!, $object: moment_set_input!) {
    update_moment_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;

export const DeleteMomentsMutation = gql`
  mutation DeleteMoments($ids: [uuid!]!, $updated_at: timestamptz!) {
    update_moment(where: { id: { _in: $ids } }, _set: { deleted: true, updated_at: $updated_at }) {
      affected_rows
    }
  }
`;
