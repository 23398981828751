import { Box } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import { SettingsIcon } from "@chakra-ui/icons";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import { Search, Star } from "@icon-park/react";

import { Text, Link } from "../../components/KnowledgeBase/KbElements";

const KbCampaigns: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"campaigns"} py={2}>
          Creator campaigns
        </LinkedHeading>

        <LinkedHeading as={"h2"} id={"campaigns-what-are-campaigns"} pt={6} pb={4}>
          What are creator campaigns?
        </LinkedHeading>
        <Text>
          Creator campaigns allow organizations to grant creators temporary access to Rivr. During a
          campaign, an organization distributes keys to creators, unlocking access to Rivr and its
          various features.
        </Text>

        <LinkedHeading as={"h2"} id={"campaigns-joining"} pt={6} pb={4}>
          Joining a campaign
        </LinkedHeading>
        <Text>
          To join a campaign, visit the{" "}
          <Link onClick={() => navigate(`${RivrLocations.Account}?${searchParams.toString()}`)}>
            Account
          </Link>{" "}
          page, which you can access via the{" "}
          <KbButton isIconButton={true} icon={<SettingsIcon />} colorScheme={"gray"} /> menu in the
          top-right corner of Rivr. Navigate to the Campaigns tab and connect your account to enable
          the campaign key input. If the key is valid, a notification will confirm it, and the key
          will appear in a table below. You'll also see a notification on the{" "}
          <Link onClick={() => navigate(`${RivrLocations.Search}?${searchParams.toString()}`)}>
            Search
          </Link>{" "}
          page indicating that you’re in an active campaign.
        </Text>
        <Text>
          During campaigns, you might gain temporary access to special features, like automatic VOD
          analysis. Reach out to the organization running the campaign for details on what’s
          included.
        </Text>

        <LinkedHeading as={"h2"} id={"campaigns-expired"} pt={6} pb={4}>
          Expired campaigns
        </LinkedHeading>
        <Text>
          When a campaign ends, access to its features may no longer be available. However, you’ll
          still have access to the videos analyzed during the campaign and can continue creating
          Moments and downloading clips for a limited time.
        </Text>
        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 12, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "Search",
              description: "How to analyze your VODs for your best Moments",
              icon: Search,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Search}`,
            },
            {
              title: "Results",
              description: "How to navigate results and download clips",
              icon: Star,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Results}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbCampaigns;
