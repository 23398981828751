import { useEffect, useCallback, useRef } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

const CHATWOOT_BASE_URL = "https://app.chatwoot.com";
const CHATWOOT_ID = process.env.REACT_APP_CHAT_ID;

const useChatwootScript = () => {
  const chatwootReady = useRef(false);

  const loadScript = useCallback(() => {
    if (!CHATWOOT_ID) return;

    window.chatwootSettings = {
      position: "right",
      type: "standard",
      launcherTitle: "Chat with us",
      darkMode: "dark",
    };

    const script = document.createElement("script");
    script.src = `${CHATWOOT_BASE_URL}/packs/js/sdk.js`;
    script.async = true;

    script.onload = () => {
      window.chatwootSDK.run({
        websiteToken: CHATWOOT_ID,
        baseUrl: CHATWOOT_BASE_URL,
      });
      chatwootReady.current = true;
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const cleanup = loadScript();
    return cleanup;
  }, [loadScript]);

  return chatwootReady;
};

const useSetUserInfo = (user, chatwootReady) => {
  const userInfoSet = useRef(false);

  const setUserInChatwoot = useCallback(() => {
    if (!window.$chatwoot || !chatwootReady.current || !user || userInfoSet.current) return;

    try {
      window.$chatwoot.setUser(user.id, {
        name: `${user.given_name} ${user.family_name}`,
        email: user.email,
      });
      window.$chatwoot.setCustomAttributes({
        kinde_id: user.id,
        kinde_email: user.email,
        kinde_given_name: user.given_name,
        kinde_family_name: user.family_name,
      });
      window.$chatwoot.setConversationCustomAttributes({
        current_page: window.location.href,
      });
      userInfoSet.current = true;
    } catch (error) {
      console.error(error);
    }
  }, [user, chatwootReady]);

  return setUserInChatwoot;
};

const LiveChat = () => {
  const { user } = useKindeAuth();
  const chatwootReady = useChatwootScript();
  const setUserInChatwoot = useSetUserInfo(user, chatwootReady);

  useEffect(() => {
    const interval = setInterval(() => {
      if (chatwootReady.current && window.$chatwoot && typeof window.$chatwoot.on === "function") {
        window.$chatwoot.on("widget:opened", () => {
          setUserInChatwoot();
        });
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [chatwootReady, setUserInChatwoot]);

  return null;
};

export default LiveChat;
