import { useMemo, useState } from "react";
import { useTrigger } from "./useTrigger";

type PaginationState<T> = {
  elements: T[];
  totalElements: number;
  page: number;
  totalPages: number;
  setPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  from: number;
  to: number;
};

export function usePagination<T>(
  elements: T[],
  sortFunction?: (a: T, b: T) => number,
  filterFunction?: (element: T) => boolean,
  pageSize = 10
): PaginationState<T> {
  const [currentPage, setCurrentPage] = useState(0);
  const sortedList = useMemo(() => {
    if (!sortFunction) return elements;
    const result = [...elements];
    result.sort(sortFunction);
    return result;
  }, [elements, sortFunction]);
  const filteredList = useMemo(
    () => (filterFunction ? sortedList.filter(filterFunction) : sortedList),
    [sortedList, filterFunction]
  );
  const renderedList = filteredList.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
  const totalPages = Math.ceil(filteredList.length / pageSize) || 1;
  const totalElements = filteredList.length;
  const from = totalElements ? currentPage * pageSize + 1 : 0;
  const to = totalElements ? from + renderedList.length - 1 : 0;
  const hasNextPage = currentPage + 1 < totalPages;
  const hasPreviousPage = currentPage > 0;

  if (currentPage >= totalPages) setCurrentPage(totalPages - 1);

  useTrigger(() => {
    setCurrentPage(0);
  }, [sortFunction, filterFunction]);

  const setPage = (page: number) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page - 1);
  };

  const nextPage = () => {
    if (hasNextPage) setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    if (hasPreviousPage) setCurrentPage(currentPage - 1);
  };

  return {
    elements: renderedList,
    totalElements,
    page: currentPage + 1,
    totalPages,
    setPage,
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
    from,
    to,
  };
}
