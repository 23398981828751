import { RecommendedMoment, recommendedMomentSettings, RivrMoment } from "../models/moment";
import { annotations as signalAnnotations } from "../models/signals";
import { getPeakInInterval } from "./signal";
import { durationToSeconds } from "./time";

// Recommended Moments
export const dedupeMoments = (moments: RecommendedMoment[]): RecommendedMoment[] => {
  const sortedMoments = [...moments].sort(
    (a, b) => a.time - b.time || Number(a.created_at) - Number(b.created_at)
  );
  const unique = [];
  for (const moment of sortedMoments)
    if (unique.length === 0 || unique[unique.length - 1].time !== moment.time) unique.push(moment);
  return unique;
};

export const recalculateAnnotationsAndLevel = (
  moments: RecommendedMoment[],
  features: string[],
  signalData: Map<
    string,
    {
      x: Date[];
      y: number[];
    }
  >
) => {
  const result = moments.map((m) => ({ ...m }));

  result.forEach((moment) => {
    for (const { name, signal, negative } of signalAnnotations) {
      const annotation = getPeakInInterval(
        signalData.get(signal) || { x: [], y: [] },
        moment.start_time,
        moment.end_time,
        negative ? "min" : "max"
      );

      if (annotation !== undefined)
        moment.annotations[name] = +(annotation * (negative ? -1 : 1)).toFixed(3);
    }
    const level =
      features.length < 1
        ? moment.level
        : features.reduce(
            (lvl, feat) =>
              lvl +
              (moment.annotations?.[feat] >=
              (recommendedMomentSettings as any)[feat].featureThreshold
                ? 1
                : 0) +
              (moment.annotations?.[feat] ?? 0),
            0
          );
    moment.level = level;
  });

  return result;
};

// Rivr Moments
export const dedupeRivrMoments = (moments: RivrMoment[]): RivrMoment[] => {
  const sortedMoments = [...moments].sort(
    (a, b) =>
      a.channel.localeCompare(b.channel) ||
      new Date(a.date).getTime() - new Date(b.date).getTime() ||
      a.type.localeCompare(b.type) ||
      durationToSeconds(a.timestamp) - durationToSeconds(b.timestamp) ||
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
  const unique = [];
  for (const moment of sortedMoments)
    if (unique.length === 0 || unique[unique.length - 1].timestamp !== moment.timestamp)
      unique.push(moment);

  return unique;
};
