import { Tooltip } from "@chakra-ui/react";
import { markerBorderColor, markerSize } from "../../models/moment";

type Props = {
  onClick: () => void;
  info: any;
  zero: number;
};

export const Marker = (props: Props) => {
  const { onClick, info, zero } = props;
  const strokeColor = markerBorderColor === null ? info.color : markerBorderColor;
  const strokeOpacity = Math.min(info.opacity + 0.25, 1.0);

  return (
    <Tooltip label={info.title}>
      <svg
        onClick={onClick}
        width={`${markerSize}px`}
        height={`${markerSize}px`}
        viewBox="0 0 20 20"
        style={{
          position: "absolute",
          left: `${info.pos - markerSize / 2}px`,
          top: `${zero - markerSize / 2}px`,
          cursor: "pointer",
          zIndex: 1, // Make sure moment markers are on top of selected moment box
        }}
      >
        {info.shape === "square" ? (
          <rect
            x="2"
            y="2"
            width="16"
            height="16"
            fill={info.color}
            fillOpacity={info.opacity}
            stroke={strokeColor}
            strokeOpacity={strokeOpacity}
            strokeWidth="2"
          />
        ) : info.shape === "triangle" ? (
          <polygon
            points="2,18 10,2 18,18"
            fill={info.color}
            fillOpacity={info.opacity}
            stroke={strokeColor}
            strokeOpacity={strokeOpacity}
            strokeWidth="2"
          />
        ) : info.shape === "diamond" ? (
          <polygon
            points="1,10 10,1 19,10 10,19"
            fill={info.color}
            fillOpacity={info.opacity}
            stroke={strokeColor}
            strokeOpacity={strokeOpacity}
            strokeWidth="2"
          />
        ) : info.shape === "cross" ? (
          <polygon
            points="8,12 2,12 2,8 8,8 8,2 12,2 12,8 18,8 18,12 12,12 12,18 8,18"
            fill={info.color}
            fillOpacity={info.opacity}
            stroke={strokeColor}
            strokeOpacity={strokeOpacity}
            strokeWidth="2"
          />
        ) : info.shape === "x" ? (
          <polygon
            points="7,10 2,5 5,2 10,7 15,2 18,5 13,10 18,15 15,18 10,13 5,18 2,15"
            fill={info.color}
            fillOpacity={info.opacity}
            stroke={strokeColor}
            strokeOpacity={strokeOpacity}
            strokeWidth="2"
          />
        ) : info.shape === "star" ? (
          <polygon
            points="2,8 8,8 10,2 12,8 18,8 13,11.5 16,18 10,14 4,18 7,11.5"
            fill={info.color}
            fillOpacity={info.opacity}
            stroke={strokeColor}
            strokeOpacity={strokeOpacity}
            strokeWidth="2"
          />
        ) : info.shape === "heart" ? (
          <path
            d="M 10 18 L 4 12 C -2 6 4 0 10 6 C 16 0 22 6 16 12 Z"
            fill={info.color}
            fillOpacity={info.opacity}
            stroke={strokeColor}
            strokeOpacity={strokeOpacity}
            strokeWidth="2"
          />
        ) : (
          <circle
            cx="10"
            cy="10"
            r="8"
            fill={info.color}
            fillOpacity={info.opacity}
            stroke={strokeColor}
            strokeOpacity={strokeOpacity}
            strokeWidth="2"
          />
        )}
      </svg>
    </Tooltip>
  );
};
