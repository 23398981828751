import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Tr,
  Td,
  Tag,
  Text,
  Badge,
  Tooltip,
  HStack,
  IconButton,
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  Wrap,
  WrapItem,
  Divider,
  VStack,
} from "@chakra-ui/react";
import { InboxIn, InboxOut, NewEfferent, Plus, Star } from "@icon-park/react";
import { UpdateMomentMutation } from "../../../../api/moment";
import { UpdateRecommendedMomentMutation } from "../../../../api/recommended-moment";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import {
  CampaignURLParams,
  ResultsURLParams,
  RivrLocations,
  updateURLParams,
} from "../../../../models/navigation";
import { recommendedMomentSettings, RivrMoment } from "../../../../models/moment";
import "../CampaignDiscover.css";
import { pillOptions } from "../../../../models/tags";
import * as amplitude from "@amplitude/analytics-browser";

interface Props {
  moment: RivrMoment;
  fetchSearches: any;
  miniPlayer: boolean;
  selectedMoment: RivrMoment | null;
  setSelectedMoment: React.Dispatch<React.SetStateAction<RivrMoment | null>>;
  features: string[];
  isOrgMember: boolean;
}

export const DiscoverMomentItem = ({
  moment,
  fetchSearches,
  miniPlayer,
  selectedMoment,
  setSelectedMoment,
  features,
  isOrgMember,
}: Props) => {
  const navigate = useNavigate();

  const maxTags = 3;
  const [tags, setTags] = useState<string[]>(moment.tags);

  const [searchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";

  const isArchived = moment.archivedAt !== null;
  const viewLink = `${RivrLocations.Results}/${moment.searchId}?${updateURLParams(
    searchParams.toString(),
    [
      [ResultsURLParams.SelectedMoment, moment.id],
      [ResultsURLParams.SelectedTimestamp, ""],
      [ResultsURLParams.SelectedPanel, ""],
    ]
  )}`;

  const [momentUpdateAPI] = useMutation(UpdateMomentMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const [recommendedMomentUpdateAPI] = useMutation(UpdateRecommendedMomentMutation, {
    onCompleted() {
      fetchSearches({ campaign: selectedCampaign });
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });

  const handleNavigate = () => {
    if (miniPlayer) {
      setSelectedMoment(moment);
      handleAmplitudeTrack("Previewed Moment", {
        MomentId: moment.id,
        MomentType: moment.type,
      });
    } else {
      navigate(viewLink);
      handleAmplitudeTrack("Navigated to Moment in Results", {
        MomentId: moment.id,
        MomentType: moment.type,
      });
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
    if (miniPlayer) {
      e.preventDefault();
      handleNavigate();
    }
  };

  const handleFavorite = () => {
    const isFavorited = !moment.favorited;
    if (moment.type === "User Generated") {
      momentUpdateAPI({
        variables: { id: moment.id, object: { favorited: isFavorited } },
      });
    } else {
      recommendedMomentUpdateAPI({
        variables: { id: moment.id, object: { favorited: isFavorited } },
      });
    }

    handleAmplitudeTrack("Moment Favorited Status Changed", {
      Action: isFavorited ? "Favorited" : "Unfavorited",
      MomentId: moment.id,
      MomentType: moment.type,
    });
  };

  const handleArchive = (date: Date | null) => {
    if (moment.type === "User Generated") {
      momentUpdateAPI({
        variables: { id: moment.id, object: { archived_at: date } },
      });
    } else {
      recommendedMomentUpdateAPI({
        variables: { id: moment.id, object: { archived_at: date } },
      });
    }

    moment.archivedAt =
      date !== null
        ? date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })
        : null;

    handleAmplitudeTrack("Moment Archived Status Changed", {
      Action: date !== null ? "Archived" : "Unarchived",
      MomentId: moment.id,
      MomentType: moment.type,
    });
  };

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  const momentFeatureTag = (label: string, index: number) => (
    <Tooltip key={index} label={(recommendedMomentSettings as any)[label].title}>
      <Tag
        colorScheme={(recommendedMomentSettings as any)[label].colorScheme}
        variant={"subtle"}
        size={"xl"}
        py={0.5}
        borderRadius={"sm"}
        minW={"24px"}
        justifyContent={"center"}
        opacity={
          moment.annotations[label] >= (recommendedMomentSettings as any)[label].featureThreshold
            ? 1
            : 0.2
        }
        className={
          (recommendedMomentSettings as any)[label].hyperThreshold &&
          moment.annotations[label] >= (recommendedMomentSettings as any)[label].hyperThreshold
            ? "hyper-hype"
            : ""
        }
      >
        {(recommendedMomentSettings as any)[label].icon}
      </Tag>
    </Tooltip>
  );

  const handleTagToggle = (tag: string) => {
    setTags((prevTags) => {
      const updatedTags: string[] = prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag];

      return updatedTags;
    });
  };

  const updateTags = () => {
    const tagsChanged =
      tags.length !== moment.tags.length || tags.some((tag) => !moment.tags.includes(tag));

    if (tagsChanged) {
      const addedTags = tags.filter((t) => !moment.tags.includes(t));
      const removedTags = moment.tags.filter((t) => !tags.includes(t));

      moment.tags = tags;

      if (moment.type === "User Generated") {
        momentUpdateAPI({
          variables: { id: moment.id, object: { tags: tags } },
        });
      } else if (moment.type === "Recommended") {
        recommendedMomentUpdateAPI({
          variables: { id: moment.id, object: { tags: tags } },
        });
      }

      handleAmplitudeTrack("Tags Updated", {
        Added: addedTags,
        Removed: removedTags,
        TotalTags: tags.length,
        MomentType: moment.type,
        MomentId: moment.id,
      });
    }
  };

  const userTags = pillOptions
    .filter((tag) => tag.type === "user")
    .sort((a, b) => a.label.localeCompare(b.label));

  const orgTags = pillOptions
    .filter((tag) => tag.type === "org")
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Tr
      _hover={{ bg: "whiteAlpha.100", cursor: "pointer" }}
      sx={{
        "&:hover .actions": {
          opacity: 1,
        },
      }}
      display={"table-row"}
      onClick={handleNavigate}
      bg={selectedMoment === moment ? "whiteAlpha.100" : undefined}
    >
      <Td opacity={isArchived ? 0.4 : 1} px={2}>
        <Tooltip placement={"bottom"} label={"Favorite"}>
          <IconButton
            size="xs"
            variant={"ghost"}
            aria-label="Favorite Moment"
            icon={<Star size={16} theme={moment.favorited ? "filled" : "outline"} />}
            onClick={(e) => {
              e.stopPropagation();
              handleFavorite();
            }}
          />
        </Tooltip>
      </Td>
      <Td minW={36} px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <HStack w={"100%"} maxW={28} spacing={0.5}>
            {features.map((feature, index) => momentFeatureTag(feature, index))}
          </HStack>
        </Box>
      </Td>
      <Td px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text overflow={"hidden"} textOverflow={"ellipsis"} title={moment.channel}>
            {moment.channel}
          </Text>
        </Box>
      </Td>
      <Td maxW={56} px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text overflow={"hidden"} textOverflow={"ellipsis"} title={moment.title}>
            {moment.title}
          </Text>
        </Box>
      </Td>
      <Td px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Badge
            colorScheme={moment.type === "User Generated" ? "orange" : "purple"}
            letterSpacing={"wide"}
            w={"6.5rem"}
            textAlign={"center"}
            textTransform={"capitalize"}
            fontWeight={"normal"}
          >
            {moment.type}
          </Badge>
        </Box>
      </Td>
      <Td px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          {moment.date}
        </Box>
      </Td>
      <Td maxW={28} px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          {moment.timestamp}
        </Box>
      </Td>
      <Td maxW={80} px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          as={Link}
          to={viewLink}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <Text overflow={"hidden"} textOverflow={"ellipsis"} title={moment.vod}>
            {moment.vod}
          </Text>
        </Box>
      </Td>
      <Td px={2}>
        <Box
          opacity={isArchived ? 0.4 : 1}
          onClick={(e: any) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <HStack gap={0.5}>
            <Popover
              strategy={"fixed"}
              onOpen={() => {
                setTags(moment.tags);
              }}
              onClose={updateTags}
              isLazy
              closeOnBlur={true}
            >
              <PopoverTrigger>
                <IconButton
                  aria-label={"Edit moment tags"}
                  icon={<Plus />}
                  h={"1rem"}
                  minW={"1rem"}
                  fontSize={"xs"}
                  variant={"solid"}
                  borderRadius={"sm"}
                  colorScheme={"gray"}
                  bg={"whiteAlpha.300"}
                  mr={0.5}
                  _hover={{ bg: "whiteAlpha.400" }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </PopoverTrigger>
              <PopoverContent
                onClick={(e) => e.stopPropagation()}
                maxW={"20.40rem"}
                w={"100%"}
                shadow={"dark-lg"}
              >
                <PopoverHeader>
                  <Text userSelect={"none"} fontSize={"sm"}>
                    Tags
                  </Text>
                  <PopoverCloseButton top={1} right={1} />
                </PopoverHeader>
                <VStack w={"100%"} align={"start"} gap={1} px={3} pt={3}>
                  <Text fontSize={"sm"}>Universal tags</Text>
                  <Text fontSize={"xs"} color={"gray.300"}>
                    Visible to all users
                  </Text>
                </VStack>
                <Wrap p={3} spacing={1}>
                  {userTags.map((option) => (
                    <WrapItem key={option.label}>
                      <Tooltip label={option.tooltip}>
                        <Button
                          size={"xs"}
                          variant={tags.includes(option.label) ? "solid" : "outline"}
                          colorScheme={tags.includes(option.label) ? "blue" : "gray"}
                          width={"fit-content"}
                          display={"inline-block"}
                          fontWeight={"normal"}
                          onClick={() => handleTagToggle(option.label)}
                          borderWidth={1}
                        >
                          {option.label}
                        </Button>
                      </Tooltip>
                    </WrapItem>
                  ))}
                </Wrap>

                <Divider my={0} />
                <VStack w={"100%"} align={"start"} gap={1} px={3} pt={3}>
                  <Text fontSize={"sm"}>Organization tags</Text>
                  <Text fontSize={"xs"} color={"gray.300"}>
                    Visible only to organizations
                  </Text>
                </VStack>
                <Wrap p={3} spacing={1}>
                  {orgTags.map((option) => (
                    <WrapItem key={option.label}>
                      <Tooltip label={option.tooltip}>
                        <Button
                          size={"xs"}
                          variant={tags.includes(option.label) ? "solid" : "outline"}
                          colorScheme={tags.includes(option.label) ? "blue" : "gray"}
                          width={"fit-content"}
                          display={"inline-block"}
                          fontWeight={"normal"}
                          onClick={() => handleTagToggle(option.label)}
                          borderWidth={1}
                        >
                          {option.label}
                        </Button>
                      </Tooltip>
                    </WrapItem>
                  ))}
                </Wrap>
              </PopoverContent>
            </Popover>
            {moment.tags.slice(0, maxTags).map((tag, index) => (
              <Badge
                key={index}
                size={"sm"}
                borderRadius={"sm"}
                w={"fit-content"}
                letterSpacing={"wide"}
                fontWeight={"normal"}
                textTransform={"capitalize"}
                mr={0.5}
              >
                {tag}
              </Badge>
            ))}
            {moment.tags.length > maxTags && (
              <Tooltip
                label={moment.tags
                  .slice(maxTags)
                  .sort((a, b) => a.localeCompare(b))
                  .join(", ")}
              >
                <Badge
                  size={"sm"}
                  borderRadius={"sm"}
                  w={"fit-content"}
                  letterSpacing={"wide"}
                  fontWeight={"normal"}
                  textTransform={"capitalize"}
                >
                  +{moment.tags.length - maxTags}
                </Badge>
              </Tooltip>
            )}
          </HStack>
        </Box>
      </Td>
      <Td maxW={"fit-content"} isNumeric px={2}>
        <HStack justify={"flex-end"} spacing={1} className="actions" opacity={0}>
          <Tooltip label={"View"}>
            <Box
              opacity={isArchived ? 0.4 : 1}
              as={Link}
              to={viewLink}
              onClick={(e: any) => {
                e.stopPropagation();
                handleClick(e);
              }}
            >
              <IconButton
                size="xs"
                variant={"ghost"}
                aria-label="View Moment"
                icon={<NewEfferent size={14} />}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!miniPlayer) navigate(viewLink);
                }}
              />
            </Box>
          </Tooltip>
          <Tooltip label={isArchived ? "Unarchive" : "Archive"}>
            <IconButton
              size="xs"
              variant={"ghost"}
              aria-label="Archive Moment"
              icon={isArchived ? <InboxOut size={14} /> : <InboxIn size={14} />}
              onClick={(e) => {
                e.stopPropagation();
                handleArchive(isArchived ? null : new Date());
              }}
            />
          </Tooltip>
        </HStack>
      </Td>
    </Tr>
  );
};
