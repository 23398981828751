import { useState } from "react";
import { Box, VStack, HStack, IconButton, Tooltip, Center, Text, Button } from "@chakra-ui/react";
import { CloseOne, Scale, InboxIn, InboxOut, Star, NewEfferent } from "@icon-park/react";
import { Link } from "react-router-dom";
import { durationToSeconds } from "../../utils/time";
import { RivrMoment } from "../../models/moment";
import { GenericPlayer } from "../MediaPlayer/GenericPlayer";
import { VideoInfo } from "../MediaPlayer/PlayerProps";
import { CircleAlert } from "lucide-react";

interface Props {
  toggleVisibility: () => void;
  timestamp: string;
  videoInfo: VideoInfo;
  resultsLink: string;
  playerScale: number;
  setPlayerScale: React.Dispatch<React.SetStateAction<number>>;
  handleFavorite?: () => void;
  handleArchive?: (date: Date | null) => void;
  moment?: RivrMoment;
  onError?: (error: any) => void;
}

const MiniPlayer = ({
  toggleVisibility,
  timestamp,
  videoInfo,
  resultsLink,
  playerScale,
  setPlayerScale,
  handleFavorite,
  handleArchive,
  moment,
  onError,
}: Props) => {
  const handleToggleScale = () => setPlayerScale((playerScale) => (playerScale === 1 ? 2 : 1));
  const baseWidthRem = "30rem";
  const aspectRatio = 9 / 16;
  const calculatedHeight = `calc(${baseWidthRem} * ${aspectRatio} * 1.18)`;

  const isArchived = moment?.archivedAt !== null;

  const [playerError, setPlayerError] = useState<any>(null);
  const [retryKey, setRetryKey] = useState(0);
  const handlePlayerError = (error: any) => {
    setPlayerError(error);
  };

  return (
    <Box
      position={"fixed"}
      right={6}
      bottom={24}
      w={`calc(${baseWidthRem} * ${playerScale})`}
      maxW={"50vw"}
      h={`calc(${calculatedHeight} * ${playerScale})`}
      maxH={"60vh"}
      zIndex={"overlay"}
      boxShadow={"lg"}
      borderRadius={"md"}
      overflow={"hidden"}
      borderWidth={1}
      bg={"gray.700"}
      shadow={"dark-lg"}
    >
      <VStack spacing={0} height={"100%"}>
        <Box flex={"1"} w={"100%"} position={"relative"}>
          {playerError ? (
            <Center flexDirection="column" justifyContent="center" height="100%" gap={2}>
              <Box color={"red.400"}>
                <CircleAlert size={48} color="currentColor" />
              </Box>
              <Text textAlign={"center"} w={"fit-content"} sx={{ textWrap: "balance" }}>
                {"An error occurred while playing the video"}
              </Text>
              <Text
                textAlign={"center"}
                w={"fit-content"}
                sx={{ textWrap: "balance" }}
                color={"red.400"}
                fontSize={"xs"}
                px={6}
              >
                {playerError.message}
              </Text>
              <Button
                mt={2}
                size={"sm"}
                w={"fit-content"}
                onClick={() => {
                  setPlayerError(null);
                  setRetryKey((prevKey) => prevKey + 1);
                }}
              >
                Reload
              </Button>
            </Center>
          ) : (
            <GenericPlayer
              key={retryKey}
              seekTime={{ seconds: durationToSeconds(timestamp) }}
              pausePlayer={{ pause: false }}
              videoInfo={videoInfo}
              updatePlayTime={() => 0}
              onError={handlePlayerError}
            />
          )}
        </Box>
        <HStack p={2} bg={"gray.800"} w={"100%"} justify={"space-between"}>
          <Tooltip label={"Scale player"} placement={"bottom"}>
            <IconButton
              transform={"scaleX(-1)"}
              size={"sm"}
              icon={<Scale />}
              aria-label={"Scale video player"}
              colorScheme={"blue"}
              onClick={handleToggleScale}
            />
          </Tooltip>
          {moment && handleFavorite && handleArchive && (
            <HStack>
              <Tooltip label={moment.favorited ? "Unfavorite Moment" : "Favorite Moment"}>
                <IconButton
                  size="sm"
                  icon={<Star size={16} theme={moment.favorited ? "filled" : "outline"} />}
                  aria-label={"Favorite Moment"}
                  onClick={handleFavorite}
                  colorScheme={moment.favorited ? "red" : "gray"}
                />
              </Tooltip>
              <Tooltip label={isArchived ? "Unarchive" : "Archive"}>
                <IconButton
                  size="sm"
                  icon={isArchived ? <InboxOut /> : <InboxIn />}
                  aria-label={"Archive Moment"}
                  onClick={() => handleArchive(isArchived ? null : new Date())}
                  colorScheme={isArchived ? "purple" : "gray"}
                />
              </Tooltip>
            </HStack>
          )}
          <Tooltip label={"Go to result page"} placement={"bottom"}>
            <IconButton
              size={"sm"}
              icon={<NewEfferent />}
              aria-label={"Go to results page"}
              colorScheme={"green"}
              as={Link}
              to={resultsLink}
            />
          </Tooltip>
          <IconButton
            size={"sm"}
            icon={<CloseOne size={20} />}
            borderRadius={"full"}
            aria-label={"Close video player"}
            onClick={toggleVisibility}
            pos={"absolute"}
            top={2}
            right={2}
            zIndex={"1"}
          />
        </HStack>
      </VStack>
    </Box>
  );
};

export default MiniPlayer;
