import { gql } from "@apollo/client";

// Queries
// Retrieve in-progress, error, and user stopped search records for a user
export const ActiveSearchesQuery = gql`
  query ActiveSearches($user_id: String) {
    search(
      order_by: { id: desc }
      where: {
        user_id: { _eq: $user_id }
        deleted: { _eq: false }
        _or: [
          { status: { _in: ["in-progress", "error"] } }
          { status_description: { _eq: "stopped" } }
        ]
      }
    ) {
      created_at
      id
      origin_type
      search_progress
      url
      video_title
      video_id
      video_type
      video_duration
      metadata
      status
      status_description
    }
  }
`;

// Get Search History For User.
export const SearchHistoryQuery = gql`
  query SearchHistory($user_id: String, $limit: Int) {
    search(
      order_by: { id: desc }
      where: { user_id: { _eq: $user_id }, deleted: { _eq: false }, status: { _eq: "stopped" } }
      limit: $limit
    ) {
      id
      status
      status_description
      search_progress
      workflow_status
      url
      video_title
      video_id
      video_type
      video_duration
      created_at
      archived_at
      origin_type
      metadata
      moments_aggregate(where: { deleted: { _eq: false } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

// Get Search Records By ID
export const SearchByIDQuery = gql`
  query SearchByID($id: Int!) {
    search_by_pk(id: $id) {
      user_id
      search_progress
      status
      status_description
      url
      video_duration
      video_title
      video_type
      video_id
      origin_type
      campaign_id
      metadata
      asr_transcript
      moments {
        updated_at
        title
        tags
        start_time
        search_id
        id
        end_time
        created_at
        deleted
        recommended_moment_id
        annotations
      }
    }
  }
`;

// Mutations
// Stop Search. Update Search Record status to stopped
export const StopSearchMutation = gql`
  mutation StopSearch($id: Int) {
    update_search(
      where: { id: { _eq: $id } }
      _set: { status: "stopped", status_description: "stopped" }
    ) {
      returning {
        id
        created_at
        origin_type
        search_progress
        status
        status_description
        url
        user_id
        video_duration
        video_title
        video_type
      }
    }
  }
`;

// Set Search Workflow Status to desired state
export const SetSearchWorkflowMutation = gql`
  mutation SetSearchWorkflow($id: Int, $status: String) {
    update_search(where: { id: { _eq: $id } }, _set: { workflow_status: $status }) {
      returning {
        id
        workflow_status
      }
    }
  }
`;

// Set Search Archived at Status to desired state
export const SetSearchArchiveStatusMutation = gql`
  mutation SetSearchArchiveStatus($id: Int, $date: timestamptz) {
    update_search(where: { id: { _eq: $id } }, _set: { archived_at: $date }) {
      returning {
        id
        archived_at
      }
    }
  }
`;

// Mark Search Deleted. Update Search Record status to Deleted
export const DeleteSearchMutation = gql`
  mutation DeleteSearch($id: Int, $deleted_at: timestamptz) {
    update_search(where: { id: { _eq: $id } }, _set: { deleted: "true", deleted_at: $deleted_at }) {
      returning {
        id
        deleted
        deleted_at
      }
    }
  }
`;

// Mark all Search item as Deleted for that user
export const DeleteAllSearchHistoryMutation = gql`
  mutation DeleteAllSearchHistory($user_id: String, $deleted_at: timestamptz) {
    update_search(
      where: { user_id: { _eq: $user_id }, status: { _in: ["stopped", "error"] } }
      _set: { deleted: "true", deleted_at: $deleted_at }
    ) {
      affected_rows
      returning {
        id
        deleted
        deleted_at
      }
    }
  }
`;

export const ClearCampaignIdSearchMutation = gql`
  mutation ClearCampaignIdSearch($id: Int!) {
    update_search(where: { id: { _eq: $id } }, _set: { campaign_id: null }) {
      returning {
        id
        campaign_id
      }
    }
  }
`;

export const UpdateSearchMutation = gql`
  mutation UpdateSearch($id: Int!, $updates: search_set_input) {
    update_search(where: { id: { _eq: $id } }, _set: $updates) {
      returning {
        id
      }
    }
  }
`;
