import React, { useState, useEffect, useContext } from "react";
import { Button, ButtonGroup, IconButton, Image, Tooltip, useToast } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Plug } from "@icon-park/react";
import TwitchLogo from "../../assets/dark-twitch-logo.svg";
import YouTubeLogo from "../../assets/youtube-outline.svg";
import { getRandomBase64 } from "../../utils/random";
import { useMutation } from "@apollo/client";
import { useAuthToken } from "../../hooks/useAuthToken";
import { DisconnectTwitchMutation } from "../../api/actions";
import { AccountContext } from "../../context/AccountContext";
import * as amplitude from "@amplitude/analytics-browser";

interface Props {
  platform: "twitch" | "youtube";
  username: string | null;
  redirectUrl: string;
  size?: "sm" | "md" | "lg";
}

const PlatformButton: React.FC<Props> = ({
  platform,
  username,
  redirectUrl,
  size = "md",
}: Props) => {
  const { refreshAccount, accountLoading } = useContext(AccountContext);
  const { token } = useAuthToken();
  const toast = useToast();

  const twitchResponseCode = localStorage.getItem("twitch-oauth.code");
  const twitchResponseErrorDesc = localStorage.getItem("twitch-oauth.error");
  const [isWaiting, setIsWaiting] = useState<boolean>(
    platform === "twitch" && twitchResponseCode !== null
  );
  const isConnecting = isWaiting || accountLoading;
  const [hovered, setHovered] = useState(false);
  const isConnected = username !== null;
  const platformName =
    platform === "youtube" ? "YouTube" : platform.charAt(0).toUpperCase() + platform.slice(1);
  const colorScheme = platform === "twitch" ? "purple" : "red";
  const tooltipLabel = isConnected ? `Disconnect ${platformName}` : `Connect ${platformName}`;

  const authorizeTwitch = async (responseCode: string) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/backend/twitch/authorize`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: responseCode }),
        method: "POST",
      });
      if (!response.ok) throw new Error("Failed to authorize Twitch account");
      refreshAccount();
    } catch (error) {
      console.error(error);
      toast({
        title: "Error authorizing Twitch account",
        description: "Failed to authorize Twitch account.",
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        position: "bottom",
      });
    }
    setIsWaiting(false);
  };

  useEffect(() => {
    if (platform === "twitch" && token && twitchResponseCode !== null) {
      localStorage.removeItem("twitch-oauth.code");
      authorizeTwitch(twitchResponseCode);
    }
  }, [platform, token, twitchResponseCode]);

  useEffect(() => {
    if (platform === "twitch" && twitchResponseErrorDesc !== null) {
      localStorage.removeItem("twitch-oauth.error");
      toast({
        title: "Error connecting to Twitch",
        description: twitchResponseErrorDesc,
        status: "error",
        duration: 5000,
        isClosable: true,
        variant: "solid",
        position: "bottom",
      });
    }
  }, [platform, twitchResponseErrorDesc]);

  const handlePlatformConnect = () => {
    setIsWaiting(true);
    if (platform === "twitch") {
      const client_id = process.env.REACT_APP_TWITCH_CLIENT_ID;
      const redirect_uri = process.env.REACT_APP_TWITCH_REDIRECT_URI;
      const claims = JSON.stringify({
        userinfo: {
          preferred_username: null,
        },
      });
      const scope = encodeURIComponent("openid");
      const state = getRandomBase64(20);

      localStorage.setItem("twitch-oauth.state", state);
      localStorage.setItem("twitch-oauth.redirect", redirectUrl);
      window.location.href = `https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&claims=${claims}&scope=${scope}&state=${state}`;
    } else {
      setIsWaiting(false);
    }
  };

  const [disconnectTwitchAPI] = useMutation(DisconnectTwitchMutation, {
    onCompleted() {
      refreshAccount();
      setIsWaiting(false);
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      setIsWaiting(false);
    },
  });

  const handlePlatformDisconnect = () => {
    setIsWaiting(true);
    if (platform === "twitch") {
      disconnectTwitchAPI();
    } else {
      setIsWaiting(false);
    }
  };

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <Tooltip label={tooltipLabel}>
      <ButtonGroup
        isAttached
        onClick={() => {
          if (isConnected) {
            handlePlatformDisconnect();
          } else {
            handlePlatformConnect();
          }
          handleAmplitudeTrack("Platform Button Clicked", {
            Platform: platform,
            Action: isConnected ? "Disconnect" : "Connect",
          });
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        size={size}
        role={"group"}
      >
        <Button
          leftIcon={
            <Image
              src={platform === "twitch" ? TwitchLogo : YouTubeLogo}
              h={platform === "twitch" ? 4 : 6}
              w={"100%"}
            />
          }
          colorScheme={colorScheme}
          isDisabled={isConnecting}
          _groupHover={{ bg: `${colorScheme}.300` }}
        >
          {isConnected ? username : `Connect ${platformName}`}
        </Button>
        <IconButton
          className={"attached-button"}
          aria-label={tooltipLabel}
          icon={
            isConnected ? (
              hovered ? (
                <CloseIcon boxSize={3.5} />
              ) : (
                <CheckIcon boxSize={4} />
              )
            ) : (
              <Plug />
            )
          }
          isLoading={isConnecting}
          colorScheme={colorScheme}
          variant={"outline"}
          _groupHover={{ bg: "whiteAlpha.200", borderColor: `${colorScheme}.300` }}
        />
      </ButtonGroup>
    </Tooltip>
  );
};

export default PlatformButton;
