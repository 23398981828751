import { Box, Badge, Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import { AddIcon, CheckIcon, CloseIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import { ChartLine, Message, Redo, ScreenRotation, SortThree } from "@icon-park/react";
import { Text, Link, UnorderedList, ListItem } from "../../components/KnowledgeBase/KbElements";
import { TriangleAlert } from "lucide-react";

const KbMoments: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"results-moments"} py={2}>
          Moments
        </LinkedHeading>
        <Text>
          Moments are exciting, interesting, noteworthy, or otherwise valuable parts of a video that
          have a start and end. They’re a core aspect of Rivr—the platform is designed to help you
          find the ones that matter most to you.
        </Text>
        <Text>
          Using the{" "}
          <Link
            onClick={() =>
              navigate(
                `${RivrLocations.KnowledgeBase}/${KBSubPages.Timeline}?${searchParams.toString()}`
              )
            }
          >
            Timeline
          </Link>
          , you can find and <Link href={"#results-moments-creating"}>create Moments</Link> that can
          be <Link href={"#results-moments-downloading"}>downloaded</Link> and{" "}
          <Link href={"#results-moments-vertical"}>reformatted</Link>.
        </Text>

        <LinkedHeading as={"h2"} id={"results-moments-creating"} pt={6} pb={4}>
          Creating and tagging new Moments
        </LinkedHeading>

        <Text>
          To make a Moment, start by clicking anywhere on the Timeline. After you've found your
          Moment, click the add Moment{" "}
          <KbButton isIconButton={true} icon={<AddIcon />} colorScheme={"green"} /> button in the
          top-left corner of the Timeline, this will create a new Moment in the My Moments list.
          Next, you can title and tag your Moment by clicking on its title, or the{" "}
          <KbButton isIconButton={true} icon={<AddIcon />} colorScheme={"gray"} /> tags button on
          the Moment card. To change the length of your Moment, select it, then click and drag the
          handles on either end of the Moment region. The top of the Moment region will indicate the
          length of your selected clip.
        </Text>
        <Text>
          Moments must have a title, but tags are optional. We recommend tagging your Moments so
          that you can keep them organized.
        </Text>
        <LinkedHeading as={"h2"} id={"results-moments-list"} pt={6} pb={4}>
          My Moments
        </LinkedHeading>
        <Text>
          The My Moments list is located at the top-left of the Results page and tracks all of the
          Moments you've created from your video. It allows you to edit, delete, download, and
          reformat your Moments. Clicking on a Moment in your My Moments list highlights that Moment
          in the list and on the Timeline. Once selected, you can edit the length of your Moment
          using the Moment region. To deselect a Moment, click on it again or in an open area of the
          My Moments list.
        </Text>

        <LinkedHeading as={"h3"} id={"results-moments-deleting"} pt={6} pb={4}>
          Deleting Moments
        </LinkedHeading>
        <Text>
          To delete a Moment, hover over the Moment and click the delete{" "}
          <KbButton isIconButton={true} icon={<DeleteIcon />} colorScheme={"gray"} /> button.
          Deleting a Moment cannot be undone.
        </Text>
        <Text>
          To delete many Moments at once, select the Moments you wish to delete using the
          checkboxes, or use the <Badge>Select All</Badge> option. Then, click the delete{" "}
          <KbButton isIconButton={true} icon={<DeleteIcon />} colorScheme={"gray"} /> button at the
          top-right of the My Moments list. Deleting multiple Moments cannot be undone.
        </Text>

        <LinkedHeading as={"h3"} id={"results-moments-downloading"} pt={6} pb={4}>
          Downloading Moments
        </LinkedHeading>
        <Text>
          To download a Moment, hover over the Moment and click the download{" "}
          <KbButton isIconButton={true} icon={<DownloadIcon />} colorScheme={"gray"} /> button.
        </Text>
        <Text>
          To download many Moments at once, select the Moments you wish to download using the
          checkboxes, or use the <Badge>Select All</Badge> option. Then, click the download{" "}
          <KbButton isIconButton={true} icon={<DownloadIcon />} colorScheme={"gray"} /> button at
          the top-right of the My Moments list.
        </Text>
        <Text>
          When downloading Moments, a progress indicator will appear at the top-right of the screen.
          Ensure that your browser isn’t blocking multiple downloads—this setting is often enabled
          by default. Downloaded Moments are MP4 files named with the Moment title and timestamp
          from the video.
        </Text>

        <LinkedHeading as={"h3"} id={"results-moments-sorting-features"} pt={6} pb={4}>
          Sorting Moments by Feature
        </LinkedHeading>
        <Text>
          At the top of the My Moments list are Moment Features. Use these to sort your My Moments
          list by the feature you are most interested in.
        </Text>

        <LinkedHeading as={"h2"} id={"results-moments-recommended"} pt={6} pb={4}>
          Recommended Moments
        </LinkedHeading>
        <Text>
          To access Recommended Moments, click the Recommended tab at the top-left of the Results
          page. Recommended Moments can be sorted by rank or timestamp using the{" "}
          <KbButton isIconButton={true} icon={<SortThree />} colorScheme={"gray"} /> button. When
          sorting by rank, the Recommended Moments are in order based on the total amount of Signal
          activity.
        </Text>
        <Text>
          Recommended Moments can be accepted or rejected or modified. When accepting by clicking
          the accept <KbButton isIconButton={true} icon={<CheckIcon />} colorScheme={"gray"} />{" "}
          button, the recommendation creates a new Moment in your My Moments list where it can be
          renamed, tagged, and modified. The accepted recommendation will be removed from the
          recommendations list. Deleting the Moment you created from it will restore it in the
          recommendations list.{" "}
        </Text>
        <Text>
          When rejecting by clicking the reject{" "}
          <KbButton isIconButton={true} icon={<CloseIcon />} colorScheme={"gray"} /> button, the
          recommendation is removed from the Timeline and moved to the bottom of the Recommended
          Moments list. Rejected recommendations can be undone and re-added to the list by hover
          over the recommendation and clicking the undo{" "}
          <KbButton isIconButton={true} icon={<Redo />} colorScheme={"gray"} /> button.
        </Text>
        <Alert status={"info"} borderRadius={"md"} mb={8}>
          <AlertIcon />
          <AlertDescription>
            Recommended Moments are not guaranteed to be good content. What makes content good is
            subjective and specific to you and your audience. They simply point out heavy activity
            and outliers in the Signals. While Recommended Moments can be a great starting point, we
            highly suggest navigating the Signal data, chat, and speech to identify Moments that
            best suit your content style.
          </AlertDescription>
        </Alert>

        <LinkedHeading as={"h3"} id={"results-moments-recommended-modified"} pt={6} pb={4}>
          Modified Recommendations
        </LinkedHeading>
        <Text>
          Recommendations can be modified before accepted. Modified recommendations display a{" "}
          <KbButton isIconButton={true} icon={<TriangleAlert size={16} />} colorScheme={"yellow"} />{" "}
          symbol next to its title. When accepting a modified recommendation, the new Moment added
          to your My Moments list will contain any changes you made to the original recommendation.
          You may restore a recommendation to its original state at any time by clicking the{" "}
          <KbButton isIconButton={true} icon={<Redo />} colorScheme={"gray"} /> button.
        </Text>

        <LinkedHeading as={"h3"} id={"results-moments-recommended-features"} pt={6} pb={4}>
          Filtering Recommendations by Feature
        </LinkedHeading>
        <Text>
          At the top of the Recommendations list are Moment Features. Use these to filter the list
          by the feature you are most interested in. Recommendations will always try to show up to
          the top 10 Moments based on the features you select.
        </Text>

        <LinkedHeading as={"h2"} id={"results-moments-vertical"} pt={6} pb={4}>
          Vertical Clip Editor
        </LinkedHeading>
        <Text>
          The Vertical Clip Editor is a handy tool that allows you to reformat your Moments, making
          them fit for posting on TikTok, YouTube Shorts, and other platforms. It comes with several
          features that enable creative flexibility in how the final video will look.
        </Text>
        <Text>
          To reformat a Moment using the Vertical Clip Editor, hover over a Moment and click the
          Vertical Clip Editor{" "}
          <KbButton isIconButton={true} icon={<ScreenRotation />} colorScheme={"gray"} /> button.
        </Text>
        <Text>
          When you’ve finished configuring your vertical clip, click the{" "}
          <KbButton isIconButton={false} text={"Finish"} colorScheme={"green"} /> button to download
          it.
        </Text>

        <LinkedHeading as={"h3"} id={"results-moments-vertical-templates"} pt={6} pb={3}>
          Templates
        </LinkedHeading>
        <Text>There are currently three templates to pick from:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>Full: One content area fully scaled to take up the entire area.</ListItem>
          <ListItem>Split: Two content areas with customizable facecam ratios.</ListItem>
          <ListItem>
            Freeform: Two fully customizable content areas with the option to disable the second
            content area.
          </ListItem>
        </UnorderedList>
        <Text>
          When using Freeform, any space not covered by a content area will be blurred for a
          professional look and feel.
        </Text>

        <LinkedHeading as={"h4"} id={"results-moments-vertical-facecam"} pt={6} pb={3}>
          Facecam size
        </LinkedHeading>
        <Text>When using Split, there are three facecam size options:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>16:9: The facecam content area will be shaped to a 16:9 aspect ratio.</ListItem>
          <ListItem>4:3: The facecam content area will be shaped to a 4:3 aspect ratio.</ListItem>
          <ListItem>
            50%: The facecam content area will be shaped to take up 50% of the total vertical space.
          </ListItem>
        </UnorderedList>

        <LinkedHeading as={"h4"} id={"results-moments-vertical-position"} pt={6} pb={3}>
          Facecam position
        </LinkedHeading>
        <Text>
          When using Split, the facecam content area can be placed at the top or bottom of the
          vertical space.
        </Text>

        <LinkedHeading as={"h4"} id={"results-moments-vertical-safezone"} pt={6} pb={3}>
          Safe Zone
        </LinkedHeading>
        <Text>
          When using any template, you can enable Safe Zones to visualize areas of the video that
          may be obscured by the interfaces of popular vertical social platforms.
        </Text>
        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Dec. 11, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "The Timeline",
              description: "How to navigate and understand Signals",
              icon: ChartLine,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Timeline}`,
            },
            {
              title: "Chat & Speech",
              description: "How to search chat and speech transcripts",
              icon: Message,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.ChatSpeech}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbMoments;
