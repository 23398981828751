import { useState, useRef, useMemo } from "react";
import {
  Button,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tooltip,
  SimpleGrid,
  Center,
  Box,
  Divider,
  Text,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Badge,
  MenuGroup,
  FormControl,
  FormHelperText,
  MenuDivider,
  VStack,
} from "@chakra-ui/react";
import { DeleteIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useMutation } from "@apollo/client";
import { DeleteSearchMutation } from "../../api/search";
import { usePagination } from "../../hooks/usePagination";
import { getSearchDisplayStatus, RivrSearch } from "../../models/rivrSearch";
import { SearchHistoryItem } from "./SearchHistoryItem/SearchHistoryItem";
import { Close, Filter, SortThree } from "@icon-park/react";
import Cookies from "js-cookie";
import { durationToSeconds, offsetDate } from "../../utils/time";
import * as amplitude from "@amplitude/analytics-browser";

interface Props {
  searchList: RivrSearch[];
  getSearches: () => void;
}

export const SearchHistory = ({ searchList, getSearches }: Props) => {
  const DEFAULT_STATUS_SELECTION = ["analyzed", "reviewing", "completed"];
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const onCloseDialog = () => setIsConfirmDialogOpen(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [dateFilterStart, setDateFilterStart] = useState("");
  const [dateFilterEnd, setDateFilterEnd] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState(DEFAULT_STATUS_SELECTION);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortMethod, setSortMethod] = useState("published");
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectedSearches, setSelectedSearches] = useState<Set<number>>(new Set());

  const handleDateFilterStart = (date: string) => {
    setDateFilterStart(date);
    handleAmplitudeTrack("Adjust History Date Filter", {
      FilterType: "Start",
      Date: date,
    });
  };

  const handleDateFilterEnd = (date: string) => {
    setDateFilterEnd(date);
    handleAmplitudeTrack("Adjust History Date Filter", {
      FilterType: "End",
      Date: date,
    });
  };

  const handleStatusSelect = (selection: string | string[]) => {
    typeof selection === "string"
      ? setSelectedStatuses([selection])
      : setSelectedStatuses(
          selection.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          })
        );
  };

  const handleSortMethod = (option: string | string[]) => {
    const method = typeof option === "string" ? option : option[0];
    setSortMethod(method);
    handleAmplitudeTrack("Change History Sort Method", {
      SortMethod: method,
    });
  };

  const handleSortDirection = (direction: string | string[]) => {
    const dir = typeof direction === "string" ? direction : direction[0];
    setSortDirection(dir);
    handleAmplitudeTrack("Change History Sort Direction", {
      SortDirection: dir,
    });
  };

  const [deleteSearchAPI] = useMutation(DeleteSearchMutation, {
    onCompleted() {
      getSearches();
      setIsSelectionMode(false);
      setSelectedSearches(new Set());
    },
    onError({ graphQLErrors, networkError }) {
      console.log("Error deleting searches:", graphQLErrors, networkError);
    },
  });

  const deleteSelectedSearches = () => {
    selectedSearches.forEach((id) => {
      deleteSearchAPI({ variables: { id, deleted_at: new Date() } });
    });
  };

  const sortSearchList = useMemo(() => {
    return (a: RivrSearch, b: RivrSearch) => {
      // Sort archived videos to the end of the list
      if (a.archivedAt !== undefined && b.archivedAt === undefined) {
        return 1;
      } else if (a.archivedAt === undefined && b.archivedAt !== undefined) {
        return -1;
      }

      if (sortMethod === "status") {
        const statusOrder: { [key: string]: number } = {
          completed: 1,
          stopped: 2,
          reviewing: 3,
        };
        const orderA = statusOrder[a.workflowStatus === undefined ? a.status : a.workflowStatus];
        const orderB = statusOrder[b.workflowStatus === undefined ? b.status : b.workflowStatus];

        // Sort descending by date published if status is the same
        if ((orderA || 4) === (orderB || 4)) {
          // Put videos missing metadata (i.e. publishing date) at the end
          const valueB = b.metadata?.publishedAt.valueOf() || 0;
          const valueA = a.metadata?.publishedAt.valueOf() || 0;
          return valueB - valueA;
        } else {
          return sortDirection === "asc"
            ? (orderA || 4) - (orderB || 4)
            : (orderB || 4) - (orderA || 4);
        }
      }
      if (sortMethod === "published") {
        // Sort any videos missing metadata (i.e. publishing date) to the end of the list
        const valueB = b.metadata?.publishedAt.valueOf() || 0;
        const valueA = a.metadata?.publishedAt.valueOf() || 0;
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      }
      if (sortMethod === "processed") {
        const valueB = b.createdAt.valueOf() || 0;
        const valueA = a.createdAt.valueOf() || 0;
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      }
      if (sortMethod === "title") {
        if (
          a.videoTitle === b.videoTitle ||
          a.videoTitle === undefined ||
          b.videoTitle === undefined
        ) {
          const valueB = b.metadata?.publishedAt.valueOf() || 0;
          const valueA = a.metadata?.publishedAt.valueOf() || 0;
          return valueB - valueA;
        } else {
          return sortDirection === "asc"
            ? a.videoTitle.toLowerCase().localeCompare(b.videoTitle.toLowerCase())
            : b.videoTitle.toLowerCase().localeCompare(a.videoTitle.toLowerCase());
        }
      }
      if (sortMethod === "moments") {
        if (
          a.momentsCount === b.momentsCount ||
          a.momentsCount === undefined ||
          b.momentsCount === undefined
        ) {
          const valueB = b.metadata?.publishedAt.valueOf() || 0;
          const valueA = a.metadata?.publishedAt.valueOf() || 0;
          return valueB - valueA;
        } else {
          return sortDirection === "asc"
            ? a.momentsCount - b.momentsCount
            : b.momentsCount - a.momentsCount;
        }
      }
      if (sortMethod === "duration") {
        if (durationToSeconds(a.videoDuration) === durationToSeconds(b.videoDuration)) {
          const valueB = b.metadata?.publishedAt.valueOf() || 0;
          const valueA = a.metadata?.publishedAt.valueOf() || 0;
          return valueB - valueA;
        } else {
          return sortDirection === "asc"
            ? durationToSeconds(a.videoDuration) - durationToSeconds(b.videoDuration)
            : durationToSeconds(b.videoDuration) - durationToSeconds(a.videoDuration);
        }
      }
      return 0;
    };
  }, [sortMethod, sortDirection]);

  const filterSearchList = useMemo(() => {
    return (item: RivrSearch) => {
      if (item.metadata === undefined) {
        return false; // make sure broken searches are not displayed
      }
      if (selectedStatuses.includes(getSearchDisplayStatus(item)) === false) return false;
      const filterStartDateUTC = offsetDate(dateFilterStart);
      if (
        dateFilterStart &&
        item.metadata &&
        item.metadata.publishedAt.valueOf() >= filterStartDateUTC.valueOf() === false
      )
        return false;
      const filterEndDateUTC = offsetDate(dateFilterEnd);
      filterEndDateUTC.setDate(filterEndDateUTC.getDate() + 1); // make the end date inclusive
      if (
        dateFilterEnd &&
        item.metadata &&
        item.metadata.publishedAt.valueOf() < filterEndDateUTC.valueOf() === false
      )
        return false;
      return true;
    };
  }, [dateFilterStart, dateFilterEnd, selectedStatuses]);

  const resetSearchFilters = () => {
    setDateFilterStart("");
    setDateFilterEnd("");
    setSelectedStatuses(DEFAULT_STATUS_SELECTION);
    handleAmplitudeTrack("Reset History Filters");
  };

  const {
    elements,
    totalElements,
    page,
    totalPages,
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
    from,
    to,
  } = usePagination(searchList, sortSearchList, filterSearchList, 15);

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <>
      <Center px={6} py={12}>
        <Box w={"100%"}>
          <HStack justifyContent={"space-between"} align={"end"} py={2}>
            <VStack spacing={0} alignItems={"left"}>
              <Text fontSize={"xl"}>Discover</Text>
              <Text color={"gray.300"} fontSize={"sm"}>
                Analyzed videos, ready for you to start discovering your best Moments.
              </Text>
            </VStack>
            <HStack>
              <Box>
                <Menu closeOnSelect={false} placement={"bottom-end"}>
                  <MenuButton
                    as={IconButton}
                    colorScheme={"gray"}
                    icon={<Filter theme={"filled"} />}
                    size={"sm"}
                  />
                  <MenuList
                    zIndex={2}
                    maxH={"60vh"}
                    overflowY={"auto"}
                    sx={{ scrollbarWidth: "thin" }}
                  >
                    <MenuGroup title={"Date published"}>
                      <HStack px={4} pb={2}>
                        <FormControl>
                          <Input
                            className={"amp-unmask"}
                            type={"date"}
                            size={"sm"}
                            borderRadius={"md"}
                            value={dateFilterStart}
                            onChange={(e) => handleDateFilterStart(e.target.value)}
                          />
                          <FormHelperText>From</FormHelperText>
                        </FormControl>
                        <FormControl>
                          <Input
                            className={"amp-unmask"}
                            type={"date"}
                            size={"sm"}
                            borderRadius={"md"}
                            value={dateFilterEnd}
                            onChange={(e) => handleDateFilterEnd(e.target.value)}
                          />
                          <FormHelperText>To</FormHelperText>
                        </FormControl>
                      </HStack>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuOptionGroup
                      value={selectedStatuses}
                      title={"Status"}
                      type={"checkbox"}
                      onChange={(values) => handleStatusSelect(values)}
                    >
                      <MenuItemOption value={"analyzed"}>Analyzed</MenuItemOption>
                      {isAdmin && <MenuItemOption value={"reviewing"}>Reviewing</MenuItemOption>}
                      {isAdmin && <MenuItemOption value={"completed"}>Completed</MenuItemOption>}
                      {isAdmin && <MenuItemOption value={"archived"}>Archived</MenuItemOption>}
                    </MenuOptionGroup>
                    <MenuDivider />
                    <MenuGroup>
                      <Button
                        size={"sm"}
                        colorScheme={"gray"}
                        variant={"ghost"}
                        onClick={resetSearchFilters}
                        mx={3}
                        fontWeight={"normal"}
                      >
                        Reset filters
                      </Button>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Box>
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton as={IconButton} icon={<SortThree />} size={"sm"} />
                  <MenuList minWidth={"260px"} zIndex={2}>
                    <MenuOptionGroup
                      value={sortDirection}
                      title={"Order"}
                      type={"radio"}
                      onChange={handleSortDirection}
                    >
                      <MenuItemOption value={"asc"}>Ascending</MenuItemOption>
                      <MenuItemOption value={"desc"}>Descending</MenuItemOption>
                    </MenuOptionGroup>
                    <MenuDivider />
                    <MenuOptionGroup
                      value={sortMethod}
                      title={"Sort by"}
                      type={"radio"}
                      onChange={handleSortMethod}
                    >
                      <MenuItemOption value={"published"}>Date published</MenuItemOption>
                      {isAdmin && (
                        <MenuItemOption value={"processed"}>Date analyzed</MenuItemOption>
                      )}
                      {isAdmin && <MenuItemOption value={"status"}>Status</MenuItemOption>}
                      <MenuItemOption value={"title"}>Title</MenuItemOption>
                      <MenuItemOption value={"moments"}>Moments</MenuItemOption>
                      <MenuItemOption value={"duration"}>Duration</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Box>
              <Tooltip
                label={isSelectionMode ? "Delete selected" : "Delete videos"}
                placement={"bottom-end"}
              >
                <Box pos={"relative"}>
                  <IconButton
                    aria-label={"Delete videos"}
                    onClick={() => {
                      if (isSelectionMode) {
                        setIsConfirmDialogOpen(true);
                      } else {
                        setIsSelectionMode(true);
                      }
                    }}
                    size={"sm"}
                    colorScheme={isSelectionMode ? "green" : "gray"}
                    icon={<DeleteIcon />}
                    isDisabled={selectedSearches.size === 0 && isSelectionMode}
                  />
                  {isSelectionMode && (
                    <Badge
                      bg={"gray.600"}
                      borderRadius={"full"}
                      pos={"absolute"}
                      top={-1.5}
                      right={-1.5}
                      fontSize={"0.65em"}
                      minWidth={4}
                      textAlign={"center"}
                      zIndex={1}
                      variant={"solid"}
                      color={"gray.50"}
                    >
                      {selectedSearches.size}
                    </Badge>
                  )}
                </Box>
              </Tooltip>
              {isSelectionMode && (
                <Tooltip label={"Cancel selection"} placement={"bottom-end"}>
                  <IconButton
                    size={"sm"}
                    aria-label={"Cancel selection for delete"}
                    colorScheme={"red"}
                    icon={<Close />}
                    onClick={() => {
                      setIsSelectionMode(false);
                      setSelectedSearches(new Set());
                    }}
                  />
                </Tooltip>
              )}
            </HStack>
          </HStack>

          <Divider borderBottomWidth={2} borderColor={"whiteAlpha.400"} mb={4} />
          {elements.length > 0 ? (
            <SimpleGrid columns={{ md: 2, lg: 3, xl: 4, "3xl": 5, "4xl": 5 }} spacing={4} my={4}>
              {elements.map((item) => (
                <SearchHistoryItem
                  key={item.id}
                  search={item}
                  onDelete={getSearches}
                  isSelectionMode={isSelectionMode}
                  isSelected={selectedSearches.has(item.id)}
                  onSelect={(id: number, selected: boolean) => {
                    setSelectedSearches((prev) => {
                      const newSet = new Set(prev);
                      if (selected) {
                        newSet.add(id);
                      } else {
                        newSet.delete(id);
                      }
                      return newSet;
                    });
                  }}
                />
              ))}
            </SimpleGrid>
          ) : (
            <Center pb={4}>
              <Text my={16} color={"gray.300"}>
                {searchList.length === 0
                  ? "Analyzed videos will appear here"
                  : "No videos match your filter settings"}
              </Text>
            </Center>
          )}
          {elements.length > 0 && (
            <HStack justifyContent={"space-between"} spacing={2}>
              <Box>
                <Text fontSize={"sm"} color={"gray.400"}>
                  {totalElements > 0
                    ? `Showing ${from} - ${to} of ${totalElements} ${
                        totalElements === 1 ? "video" : "videos"
                      }`
                    : "Showing 0 videos"}
                </Text>
              </Box>

              <HStack>
                <Text
                  fontSize={"sm"}
                  color={"gray.400"}
                  pr={2}
                >{`Page ${page} of ${totalPages}`}</Text>
                <IconButton
                  size={"sm"}
                  variant={"outline"}
                  aria-label={"Previous history page"}
                  icon={<ChevronLeftIcon />}
                  onClick={previousPage}
                  disabled={!hasPreviousPage}
                />
                <IconButton
                  size={"sm"}
                  variant={"outline"}
                  aria-label={"Next history page"}
                  icon={<ChevronRightIcon />}
                  onClick={nextPage}
                  disabled={!hasNextPage}
                />
              </HStack>
            </HStack>
          )}
        </Box>
      </Center>
      <AlertDialog
        isOpen={isConfirmDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDialog}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize={"lg"}>Delete selected videos</AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete the selected videos and their Moments? This cannot be
              undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onCloseDialog}
                colorScheme={"gray"}
                variant={"ghost"}
              >
                Cancel
              </Button>
              <Button
                colorScheme={"red"}
                onClick={() => {
                  deleteSelectedSearches();
                  onCloseDialog();
                }}
                ml={2}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
