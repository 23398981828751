import { useState } from "react";
import axios from "axios";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Input,
  Text,
  Image,
  VStack,
  AbsoluteCenter,
  useToast,
} from "@chakra-ui/react";
import RivrLogo from "../../assets/rivr-logo.svg";
import "./EmailCapture.css";

const EmailCapture = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState("");
  const toast = useToast();

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      toast({
        title: "Invalid Email",
        description: "Please enter a valid email address",
        status: "error",
        duration: 7000,
        isClosable: false,
      });
      return;
    }

    const data = new FormData();
    data.append("entry.220913192", email);

    try {
      await axios.post(
        "https://docs.google.com/forms/u/1/d/e/1FAIpQLSf2WeqFyQrx-kHLB4735Md9OYqKvXazXeSsCo9iVPm48lF-9Q/formResponse",
        data
      );
    } catch (err) {
      console.error(err);
    } finally {
      setEmail("");
      onClose();
    }
  };

  return (
    <>
      <Button
        size="sm"
        variant="ghost"
        position="fixed"
        bottom="1rem"
        left="1rem"
        onClick={onOpen}
        zIndex={"tooltip"}
        className={"email-capture-btn"}
      />

      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered={true}
        size="full"
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="scale"
        className={"email-capture-modal"}
      >
        <ModalOverlay bg={"gray.900"} />
        <ModalContent className={"email-capture"} zIndex={"tooltip"}>
          <ModalBody backgroundColor="gray.900">
            <AbsoluteCenter
              maxW="400px"
              backgroundColor="gray.900"
              px={12}
              py={14}
              borderRadius={12}
            >
              <VStack spacing={6} justifyContent="" alignContent={"center"} alignItems={"center"}>
                <Image src={RivrLogo} w="100%" alt="Rivr Logo" />
                <VStack w="100%" spacing={1}>
                  <Text fontSize="xl" fontWeight="bold" align={"center"}>
                    Welcome to Rivr @ GCX
                  </Text>
                  <Text align={"center"}>Enter your email to continue</Text>
                </VStack>
                <VStack w="100%" spacing={3}>
                  <Input
                    className={"amp-unmask"}
                    placeholder="rivr@iscool.com"
                    size={"lg"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    w="full"
                    colorScheme="green"
                  />
                  <Button w="full" size={"lg"} colorScheme={"green"} mr={3} onClick={handleSubmit}>
                    Let's Go!
                  </Button>
                  <Text fontSize={"xs"} color={"gray.500"} textAlign={"center"} pt={3}>
                    By submitting your email you agree to receive communications from Rivr.
                  </Text>
                </VStack>
              </VStack>
            </AbsoluteCenter>
            <Button
              size="sm"
              variant="ghost"
              position="fixed"
              bottom="1rem"
              left="1rem"
              zIndex={"tooltip"}
              onClick={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailCapture;
