import { Tabs, TabList, Tab, TabPanels, TabPanel, Badge } from "@chakra-ui/react";
import "./MomentPanel.css";
import { Moment, RecommendedMoment } from "../../models/moment";
import { UserMoments } from "./UserMoments/UserMoments";
import { RecommendedMoments } from "./RecommendedMoments/RecommendedMoments";
import { SelectedMoment } from "../../models/selectedMoment";
import * as amplitude from "@amplitude/analytics-browser";
import { Loading } from "../Loading/Loading";

type Props = {
  moments: Moment[];
  recommendedMoments: RecommendedMoment[];
  modifiedRecommendedMoments: RecommendedMoment[];
  restoreRecommendedMoment: (moment: RecommendedMoment) => void;
  toggleMoment: (id: string) => void;
  totalMoments: number;
  focusedCallback: (selectedMoment: SelectedMoment | null) => void;
  focusLastMoment: () => void;
  focusedItem: string | null;
  deleteMoments: (ids: string[]) => void;
  updateMoment: (moment: Moment, update: Partial<Moment>) => void;
  searchId: string;
  videoInfo: any;
  recommendedMomentsLoading: boolean;
  acceptMoments: (recommended: RecommendedMoment[]) => void;
  momentTabIndex: number;
  setMomentTabIndex: (index: number) => void;
  pauseMedia: () => void;
  recommendedAmount: number;
  setRecommendedAmount: (amount: number) => void;
  minRecommendedAmount: number;
  maxRecommendedAmount: number;
  signalLoading: boolean;
  isOrgMember: boolean;
};

export const MomentPanel = (props: Props) => {
  const {
    moments,
    recommendedMoments,
    modifiedRecommendedMoments,
    restoreRecommendedMoment,
    toggleMoment,
    focusedCallback,
    focusLastMoment,
    focusedItem,
    deleteMoments,
    updateMoment,
    searchId,
    videoInfo,
    recommendedMomentsLoading,
    acceptMoments,
    momentTabIndex,
    setMomentTabIndex,
    pauseMedia,
    recommendedAmount,
    setRecommendedAmount,
    minRecommendedAmount,
    maxRecommendedAmount,
    signalLoading,
    isOrgMember,
  } = props;

  const handleAmplitudeTrack = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Tabs
      index={momentTabIndex}
      onChange={setMomentTabIndex}
      width="100%"
      display="flex"
      flexDirection="column"
      isFitted={true}
    >
      <TabList flexShrink="0">
        <Tab
          fontWeight={"medium"}
          _hover={{ bg: "whiteAlpha.200" }}
          _selected={{ bg: "whiteAlpha.200", color: "blue.300", borderBottomColor: "blue.300" }}
          borderTopRadius={"md"}
          onClick={() => handleAmplitudeTrack("My Moments Tab Clicked")}
        >
          My Moments
        </Tab>
        <Tab
          fontWeight={"medium"}
          _hover={{ bg: "whiteAlpha.200" }}
          _selected={{ bg: "whiteAlpha.200", color: "blue.300", borderBottomColor: "blue.300" }}
          borderTopRadius={"md"}
          onClick={() => handleAmplitudeTrack("Recommended Tab Clicked")}
        >
          Recommended
          <Badge ml={1.5} colorScheme={"blue"} size={"xs"}>
            Beta
          </Badge>
        </Tab>
      </TabList>
      <TabPanels flexGrow="1" minH={0}>
        <TabPanel padding="0" height="100%">
          <UserMoments
            moments={moments}
            focusedCallback={(id) => focusedCallback(id === null ? null : { kind: "user", id })}
            focusedItem={focusedItem}
            deleteMoments={deleteMoments}
            updateMoment={updateMoment}
            searchId={searchId}
            videoInfo={videoInfo}
            pauseMedia={pauseMedia}
            isOrgMember={isOrgMember}
          />
        </TabPanel>
        <TabPanel padding="0" height="100%">
          {!signalLoading ? (
            <RecommendedMoments
              moments={recommendedMoments}
              modifiedMoments={modifiedRecommendedMoments}
              focusedCallback={(id) =>
                focusedCallback(id === null ? null : { kind: "recommended", id })
              }
              focusLastMoment={focusLastMoment}
              focusedItem={focusedItem}
              momentsLoading={recommendedMomentsLoading}
              acceptMoments={acceptMoments}
              toggleMoment={toggleMoment}
              restoreMoment={restoreRecommendedMoment}
              videoInfo={videoInfo}
              recommendedAmount={recommendedAmount}
              setRecommendedAmount={setRecommendedAmount}
              minRecommendedAmount={minRecommendedAmount}
              maxRecommendedAmount={maxRecommendedAmount}
              isOrgMember={isOrgMember}
            />
          ) : (
            <Loading />
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
