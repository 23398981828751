import { durationToSeconds } from "./time";

// RETROFIT: Gets the first of the current month
export function getCurrentCycleStartDate(): Date {
  const result = new Date(Date.now());
  result.setDate(1);
  result.setHours(0, 0, 0, 0);
  return result;
}

export function calculateVideoHours(videoDurations: string[]): number {
  return videoDurations.reduce((totalHours: number, duration: string) => {
    const seconds = durationToSeconds(duration);
    // Videos up to 1 hour, 29 minutes, and 59 seconds long equal 1 VOD hour
    return seconds <= 5399 ? totalHours + 1 : totalHours + Math.round(seconds / 3600);
  }, 0);
}
