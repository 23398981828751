import { Box, Code, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import { CheckCircleIcon, SettingsIcon } from "@chakra-ui/icons";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import { Analysis, Flag } from "@icon-park/react";

import { Text, Link } from "../../components/KnowledgeBase/KbElements";

const KbOrganization: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"my-organization"} py={2}>
          My Organization
        </LinkedHeading>
        <LinkedHeading as={"h2"} id={"org-manage"} pt={6} pb={4}>
          Management
        </LinkedHeading>
        <Text>
          You can manage various aspects of your Rivr organization on the{" "}
          <Link onClick={() => navigate(`${RivrLocations.Account}?${searchParams.toString()}`)}>
            Account
          </Link>{" "}
          page under the Organization tab, accessible via the{" "}
          <KbButton isIconButton={true} icon={<SettingsIcon />} colorScheme={"gray"} /> menu at the
          top-right of Rivr.
        </Text>
        <LinkedHeading as={"h2"} id={"org-roles"} pt={6} pb={4}>
          Roles
        </LinkedHeading>
        <Text>
          Organizations currently support two roles: Owners and Members. Owners have full access to
          manage every aspect of the Rivr platform, while Members cannot view or manage billing
          information or modify organization details.
        </Text>
        <TableContainer>
          <Table variant={"simple"} colorScheme={"green"} mb={8}>
            <Thead>
              <Tr>
                <Th>Role</Th>
                <Th>Manage campaigns</Th>
                <Th>Analyze videos</Th>
                <Th>Download content</Th>
                <Th>Manage organization</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <Code>Owner</Code>
                </Td>
                <Td>
                  <CheckCircleIcon color={"green.300"} />
                </Td>
                <Td>
                  <CheckCircleIcon color={"green.300"} />
                </Td>
                <Td>
                  <CheckCircleIcon color={"green.300"} />
                </Td>
                <Td>
                  <CheckCircleIcon color={"green.300"} />
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Code>Member</Code>
                </Td>
                <Td>
                  <CheckCircleIcon color={"green.300"} />
                </Td>
                <Td>
                  <CheckCircleIcon color={"green.300"} />
                </Td>
                <Td>
                  <CheckCircleIcon color={"green.300"} />
                </Td>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <LinkedHeading as={"h2"} id={"org-billing"} pt={6} pb={4}>
          Billing and plans
        </LinkedHeading>
        <Text>
          In the billing and plans tab of the{" "}
          <Link onClick={() => navigate(`${RivrLocations.Account}?${searchParams.toString()}`)}>
            Account
          </Link>{" "}
          page, organization owners can manage their Rivr plan, update payment methods, and view
          billing history.
        </Text>

        <LinkedHeading as={"h3"} fontSize={" xl"} id={"org-billing-utilization"} pt={6} pb={4}>
          Usage-based billing
        </LinkedHeading>
        <Text>
          Every Rivr business plan includes a monthly allotment of Video hours for analysis, with
          additional video hours subject to usage-based invoicing. Plan allotments reset monthly
          with your organization's billing cycle. You can view your current allotment and
          utilization in the top-right corner of Rivr.
        </Text>
        <Text>
          Video hours are rounded to the nearest hour. For example, a video length of 03:30:00 will
          be rounded up to 4 hours, and a video length of 06:28:00 will be rounded down to 6 hours.
          Any video under one hour is considered 1 hour.
        </Text>

        <LinkedHeading as={"h2"} id={"org-team"} pt={6} pb={4}>
          Teams (seats)
        </LinkedHeading>
        <Text>
          You can view the members of your team and their roles by going to the Team tab of the
          Account settings page. Currently, seats are administered through your account manager.
          Please contact them to add, remove, or assign roles to team members.
        </Text>

        <LinkedHeading as={"h2"} id={"org-multiorg"} pt={6} pb={4}>
          Multiple organizations
        </LinkedHeading>
        <Text>
          Rivr allows single accounts to own and manage multiple organizations, each with its own
          plans, billing, and teams. If you need to manage multiple organizations, please contact
          your account manager.
        </Text>
        <Text>
          To switch between your organizations, use the organization switcher in the top-left corner
          of Rivr.
        </Text>

        <LinkedHeading as={"h2"} id={"org-removal"} pt={6} pb={4}>
          Organization removal
        </LinkedHeading>
        <Text>
          We respect your right to request the full removal of your account, organization, and its
          associated data. To request organization removal, go to the Organization tab on the{" "}
          <Link onClick={() => navigate(`${RivrLocations.Account}?${searchParams.toString()}`)}>
            Account
          </Link>{" "}
          page and click the{" "}
          <KbButton
            isIconButton={false}
            text="Delete organization"
            colorScheme="red"
            variant={"outline"}
          />{" "}
          button. Once complete, a confirmation email will be sent to the address listed in the
          organization details. Please allow up to 72 hours for this process. Note that your
          account, organization, and any associated data cannot be restored once deleted.
        </Text>
        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 12, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "Campaigns",
              description: "How to create and manage campaigns",
              icon: Flag,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.OrgCampaigns}`,
            },
            {
              title: "Analyze",
              description: "How to analyze videos at scale",
              icon: Analysis,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Analyze}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbOrganization;
