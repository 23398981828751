import { Box, VStack, Heading, Text, HStack, Badge, Divider, Button, Flex } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { PaymentIcon } from "react-svg-credit-card-payment-icons";
import { useQuery } from "@apollo/client";
import PlanCard from "../../PlanCard/PlanCard";
import {
  LatestUserSubscriptionEventQuery,
  LatestUserSubscriptionMetadataEventQuery,
} from "../../../api/subscription-event";
import { Loading } from "../../Loading/Loading";
import { getPlanByIds } from "../../../models/plan";
import { AccountContext } from "../../../context/AccountContext";
import { PaymentPortalQuery } from "../../../api/actions";
import { formatDateForDisplay } from "../../../utils/time";
import { TriangleAlert } from "lucide-react";
import * as amplitude from "@amplitude/analytics-browser";

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export const Billing = () => {
  const { account } = useContext(AccountContext);

  const hasAccess = account.subscription_status === "active";
  const hasSub = account.subscription_status !== "none" && account.subscription_id !== null;

  const { data: latestEvent } = useQuery(LatestUserSubscriptionEventQuery, {
    variables: { account_id: account.id },
    skip: !hasSub,
  });
  const { data: latestMetadataEvent } = useQuery(LatestUserSubscriptionMetadataEventQuery, {
    variables: { account_id: account.id },
    skip: !hasSub,
  });
  const { data: urls } = useQuery(PaymentPortalQuery, {
    variables: {},
    skip: !hasSub,
  });

  const subscription = latestEvent?.subscription_event[0];
  const subscriptionMetadata = latestMetadataEvent?.subscription_event[0];
  const subStatus = subscription?.status as string;
  const customerPortal = urls?.getPaymentPortal.customerPortal;
  const paymentMethod = urls?.getPaymentPortal.paymentMethod;

  const subRenewDate = subscriptionMetadata?.renews_at
    ? formatDateForDisplay(new Date(subscriptionMetadata.renews_at as string))
    : null;
  const subEndDate = subscription?.ends_at
    ? formatDateForDisplay(new Date(subscription.ends_at as string))
    : null;

  const selectedPlan = useMemo(
    () =>
      subscriptionMetadata
        ? getPlanByIds(subscriptionMetadata.product_id, subscriptionMetadata.variant_id)
        : undefined,
    [subscriptionMetadata]
  );

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  if (hasSub && (!subscription || !subscriptionMetadata || !urls))
    return <Loading variant={"large"} />;

  return (
    <Box w={"100%"} height={"100%"} alignItems={"flex-start"}>
      <VStack alignItems={"flex-start"} spacing={12} pt={6}>
        <HStack
          alignItems={"center"}
          w={"100%"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          rowGap={6}
          spacing={0}
        >
          <VStack alignItems={"flex-start"}>
            <Heading as={"h1"} fontSize={"3xl"}>
              Billing and Plans
            </Heading>
            <Text color={"gray.300"}>
              View your payment information, billing history, and make changes to your plan.
            </Text>
          </VStack>
        </HStack>
        <Divider />
        {hasSub && selectedPlan !== undefined ? (
          <>
            <HStack
              w={"100%"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              flexWrap={"wrap"}
              gap={6}
            >
              <VStack alignItems={"flex-start"} w={"50%"} spacing={1}>
                <Text fontSize={"lg"}>Your plan</Text>
                <Text color={"gray.300"} fontSize={"sm"}>
                  Manage your plan.
                </Text>
              </VStack>
              <HStack
                bg={"whiteAlpha.200"}
                p={4}
                borderRadius={"md"}
                borderWidth={1}
                gap={6}
                flexGrow={1}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <HStack gap={4} align={"flex-start"}>
                  <HStack>
                    <Text>Plan:</Text>
                    <Badge
                      fontSize={"md"}
                      colorScheme={selectedPlan.planDetails.badgeColor}
                      px={1.5}
                      letterSpacing={"wider"}
                    >
                      {selectedPlan.planDetails.planName}
                    </Badge>
                  </HStack>
                  <VStack spacing={1} align={"flex-start"}>
                    <HStack>
                      <Text>Status:</Text>
                      {hasAccess ? (
                        <Badge
                          fontSize={"md"}
                          display={"flex"}
                          colorScheme={subStatus === "cancelled" ? "yellow" : "green"}
                          px={1.5}
                          alignItems={"center"}
                          letterSpacing={"wider"}
                        >
                          {subStatus === "cancelled" && (
                            <Box mr={1.5}>
                              <TriangleAlert size={16} />
                            </Box>
                          )}{" "}
                          Active
                        </Badge>
                      ) : (
                        <Badge fontSize={"md"} colorScheme={"red"} px={1.5} letterSpacing={1}>
                          Inactive
                        </Badge>
                      )}
                    </HStack>
                    {hasAccess && subStatus === "active" && subRenewDate && (
                      <Text fontSize={"xs"} color={"gray.300"}>
                        Renews on {subRenewDate}
                      </Text>
                    )}
                    {hasAccess && subStatus === "cancelled" && (
                      <Text fontSize={"xs"} color={"gray.300"}>
                        Ends on {subEndDate}
                      </Text>
                    )}
                  </VStack>
                </HStack>

                <Button
                  colorScheme={"blue"}
                  as={"a"}
                  href={customerPortal}
                  target={"_blank"}
                  rel={"noreferrer"}
                  onClick={() =>
                    handleAmplitudeTrack("Billing Management", { event: "Manage Plan" })
                  }
                >
                  Manage plan
                </Button>
              </HStack>
            </HStack>
            <Flex w={"100%"} justify={"flex-end"}>
              <PlanCard
                planType={selectedPlan.planType}
                planAvailable={true}
                showSubscribe={false}
                subscriptionExists={true}
                displayVariant={selectedPlan.planVariant}
              />
            </Flex>
            <Divider />
            <HStack
              w={"100%"}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              rowGap={6}
              spacing={0}
              alignItems={"flex-start"}
            >
              <VStack alignItems={"flex-start"} w={"50%"} spacing={1}>
                <Text fontSize={"lg"}>Payment method</Text>
                <Text color={"gray.300"} fontSize={"sm"}>
                  Manage your default payment method.
                </Text>
              </VStack>
              <HStack
                bg={"whiteAlpha.200"}
                p={4}
                borderRadius={"md"}
                justifyContent={"space-between"}
                borderWidth={1}
                flexGrow={1}
              >
                <HStack>
                  <Box p={2} bg={"whiteAlpha.100"} borderRadius={"md"}>
                    <PaymentIcon
                      type={capitalize(subscriptionMetadata?.card_brand) as any}
                      format={"flatRounded"}
                      width={40}
                    />
                  </Box>
                  <VStack spacing={0} alignItems={"flex-start"} pl={2}>
                    <Text fontSize={"sm"}>
                      {capitalize(subscriptionMetadata?.card_brand)} ****{" "}
                      {subscriptionMetadata?.card_last_four}
                    </Text>
                  </VStack>
                </HStack>
                <a href={paymentMethod} target={"_blank"} rel={"noreferrer"}>
                  <Button
                    colorScheme={"blue"}
                    onClick={() =>
                      handleAmplitudeTrack("Billing Management", { event: "Manage Payment" })
                    }
                  >
                    Manage payment
                  </Button>
                </a>
              </HStack>
            </HStack>
            <Divider />
            <VStack alignItems={"flex-start"} w={"100%"} spacing={6}>
              <HStack w="100%" justifyContent={"space-between"} pb={4} alignItems={"flex-start"}>
                <VStack alignItems={"flex-start"} spacing={1}>
                  <Text fontSize={"lg"}>Billing history</Text>
                  <Text color={"gray.300"} fontSize={"sm"}>
                    View your billing history.
                  </Text>
                </VStack>
                <a href={customerPortal} target={"_blank"} rel={"noreferrer"}>
                  <Button
                    colorScheme={"blue"}
                    onClick={() =>
                      handleAmplitudeTrack("Billing Management", { event: "View History" })
                    }
                  >
                    View history
                  </Button>
                </a>
              </HStack>
            </VStack>
          </>
        ) : (
          <Flex w={"100%"} justify={"center"}>
            <PlanCard
              planType="EA"
              planAvailable={true}
              showSubscribe={true}
              subscriptionExists={false}
            />
          </Flex>
        )}
      </VStack>
    </Box>
  );
};
