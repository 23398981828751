import { Badge, Box } from "@chakra-ui/react";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import { SmallAddIcon } from "@chakra-ui/icons";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import { Flag, Lightning, MoreOne } from "@icon-park/react";

import { Text, Link, UnorderedList, ListItem } from "../../components/KnowledgeBase/KbElements";

const KbAnalyze: React.FC = () => {
  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"analyze"} py={2}>
          Analyze
        </LinkedHeading>
        <Text>
          The Analyze page is where organizations can start analyzing new videos as well as view and
          manage existing videos in their campaigns.
        </Text>

        <LinkedHeading as={"h2"} id={"analyze-videos"} pt={6} pb={4}>
          Analyzing videos
        </LinkedHeading>
        <Text>
          To start analyzing videos, click the{" "}
          <KbButton
            isIconButton={false}
            text={"Add videos"}
            leftIcon={<SmallAddIcon />}
            colorScheme={"green"}
          />{" "}
          button in the top-left corner of the Analyze page. A window will appear, allowing you to
          enter one or more video URLs. When using multiple URLs, separate them with a comma (,).
          Once ready, click the <KbButton isIconButton={false} text={"Go"} colorScheme={"green"} />{" "}
          button to start analyzing. Videos will appear in the main area of the Analyze page.
        </Text>

        <LinkedHeading as={"h2"} id={"analyze-manage"} pt={6} pb={4}>
          Managing videos
        </LinkedHeading>

        <LinkedHeading as={"h3"} id={"analyze-manage-channels"} pt={6} pb={4}>
          Channels (Channel panel)
        </LinkedHeading>
        <Text>
          You can choose to view all videos in a campaign or filter the page to display videos from
          specific channels by clicking the channel names in the channel panel, located on the left
          side of the Analyze page.
        </Text>
        <Text>
          The channel panel is always present on every page, as it is the primary control for
          filtering information by specific channels.
        </Text>

        <LinkedHeading as={"h3"} id={"analyze-manage-workflow"} pt={6} pb={4}>
          Workflow statuses
        </LinkedHeading>
        <Text>
          Workflow statuses help you track which videos are being reviewed, completed, or are no
          longer needed in the Analyze view. You can manually change the status of a video by
          clicking the more options{" "}
          <KbButton isIconButton={true} icon={<MoreOne size={"20px"} />} colorScheme={"gray"} />{" "}
          button and selecting a status.
        </Text>
        <Text>Some statuses are automatic:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>
            <Badge colorScheme={"yellow"} letterSpacing={0.5}>
              Analyzing
            </Badge>{" "}
            - The video is currently being analyzed.
          </ListItem>
          <ListItem>
            <Badge colorScheme={"green"} letterSpacing={0.5}>
              Analyzed
            </Badge>{" "}
            - The video has finished analyzing.
          </ListItem>
          <ListItem>
            <Badge colorScheme={"red"} letterSpacing={0.5}>
              Error
            </Badge>{" "}
            - An error occured while analyzing the video.
          </ListItem>
        </UnorderedList>
        <Text>Other statuses can be manually applied:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>
            <Badge colorScheme={"purple"} letterSpacing={0.5}>
              Reviewing
            </Badge>{" "}
            - The video has manually been marked as in-review.
          </ListItem>
          <ListItem>
            <Badge colorScheme={"blue"} letterSpacing={0.5}>
              Completed
            </Badge>{" "}
            - The video has manually been marked as completed.
          </ListItem>
          <ListItem>
            <Badge colorScheme={"gray"} letterSpacing={0.5}>
              Archived
            </Badge>{" "}
            - The video is hidden by default and sent to the end of the video list.
          </ListItem>
        </UnorderedList>

        <LinkedHeading as={"h3"} id={"analyze-manage-sort-filter"} pt={6} pb={4}>
          Sorting and filtering
        </LinkedHeading>
        <Text>
          In addition to filtering by channel using the Channel panel, videos in the Analyze view
          can be sorted by workflow status, date published, channel name, video duration, and the
          number of Moments created. Videos can also be filtered by date published, workflow status,
          and language.
        </Text>
        <Text>
          Sorting and filtering choices are saved in the URL, allowing you to share the current page
          with other organization members without losing your sorting and filtering settings.
        </Text>

        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 12, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "Discover",
              description: "How to find Moments and search Mentions at scale",
              icon: Lightning,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Discover}`,
            },
            {
              title: "Campaigns",
              description: "How to create and manage campaigns",
              icon: Flag,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.OrgCampaigns}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbAnalyze;
