import { useEffect, useState } from "react";

export function useTriggerInit(effect: () => void, values: unknown[]) {
  const [previousValues, setPreviousValues] = useState(values);
  useEffect(effect, []);
  for (let i = 0; i < values.length; ++i) {
    if (previousValues[i] !== values[i]) {
      setPreviousValues(values);
      effect();
      return;
    }
  }
}
