import { Box, Image, HStack, Alert, AlertIcon } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import RivBot from "../../assets/rivr-bot.png";
import US from "../../assets/flags/us.svg";
import CA from "../../assets/flags/ca.svg";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { Search } from "@icon-park/react";
import { Text, Link } from "../../components/KnowledgeBase/KbElements";

const KbIntroduction: React.FC = () => {
  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"Rivr"} py={2}>
          Rivr
        </LinkedHeading>

        <Text>Rivr is a first-of-its-kind platform for in-media search and content creation.</Text>
        <Text>
          Leveraging advanced technology, Rivr makes searching <em>within</em> video as easy as
          searching <em>for</em> video, helping you quickly pinpoint key Moments and gain insights
          into your audience. The platform also includes tools for editing, reformatting, and
          exporting, making it easier than ever to share your content with the world.
        </Text>
        <Text>
          Currently in its early access phase, Rivr is focused on streamlining the discovery of
          great Moments in VODs (video on demand). As development continues, expect more features,
          including enhanced search capabilities, expanded content creation options, and powerful
          new media analysis tools.
        </Text>
        <Text>
          Check out the{" "}
          <Link href={"https://rivr.stream/roadmap"} isExternal>
            roadmap
          </Link>{" "}
          for details on upcoming features.
        </Text>
        <LinkedHeading as={"h2"} id={"botnivision"} pt={6} pb={4}>
          Botni.Vision
        </LinkedHeading>
        <Text>
          Rivr is owned and operated by{" "}
          <Link href={"https://botni.vision"} isExternal>
            Botni.Vision, Inc.
          </Link>
          , based in Montreal, Canada. Our distributed team brings together diverse expertise in
          game development, publishing, deep tech, machine learning, computer vision, advertising,
          audio/video production, content creation, and more.
        </Text>
        <HStack justifyContent={"start"} pb={4} spacing={4}>
          <Image boxSize={"40px"} h={"20px"} src={CA} alt={"Canada"} title={"Canada"} />
          <Image boxSize={"40px"} src={US} alt={"United States"} title={"United States"} />
        </HStack>
        <LinkedHeading as={"h2"} id={"dot"} pt={6} pb={4}>
          Dot
        </LinkedHeading>
        <Text>
          Dot is our company mascot. You’ll spot them in our marketing materials and social posts,
          often signaling something exciting just around the corner.
        </Text>

        <Image
          boxSize={"3rem"}
          src={RivBot}
          transition={"0.25s"}
          _hover={{ transform: "translateY(-2px)" }}
          alt={"Dot"}
          title={"Dot"}
        />

        <Alert status={"info"} borderRadius={4} my={6}>
          <AlertIcon />
          Fun fact: "R" in Morse code is dot-dash-dot (• – •), one of several inspirations for Dot.
        </Alert>

        <LinkedHeading as={"h2"} id={"copyright"} pt={6} pb={4}>
          Copyright notice
        </LinkedHeading>
        <Text>
          © {new Date().getFullYear()} Rivr & Botni.Vision, Inc. All Rights Reserved. All
          trademarks, logos, and service marks displayed on this website are the property of Rivr &
          Botni.Vision, Inc. or their respective owners. You are not permitted to use these
          trademarks without the prior written consent of Rivr & Botni.Vision, Inc. Any unauthorized
          use of the materials may violate copyright, trademark, and other applicable laws. All
          content provided on this website, including text, graphics, logos, images, and software,
          is the exclusive property of Rivr & Botni.Vision, Inc. and is protected by the United
          States, Canada, and international copyright laws.
        </Text>
        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Dec. 11, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "Getting started",
              description: "How to get started with Rivr and manage your account",
              icon: CheckIcon,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.GettingStarted}`,
            },
            {
              title: "Search",
              description: "How to analyze your VODs for your best Moments",
              icon: Search,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Search}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbIntroduction;
