import browser from "../assets/browser.svg";
import googledrive from "../assets/google-drive.svg";

export const getApps = () => {
  const apps = [
    {
      title: "Browser",
      image: browser,
      kinde_key: "browser",
      description: "Download directly to the browser",
    },
    {
      title: "Google Drive",
      image: googledrive,
      kinde_key: "google_drive",
      description: "Send results to Google Drive.",
      successConnectedMessage:
        "You have successfully connected to Google Drive",
    },
  ];

  const connectedApps = getConnectedApps();

  return apps.map((app) => {
    const isAppConnected = connectedApps.find(
      (connectedApp) => connectedApp === app.kinde_key
    );

    // If the kinde_key is "browser", we always consider it as connected
    return {
      ...app,
      connected:
        app.kinde_key === "browser" ? true : isAppConnected ? true : false,
    };
  });
};

export const getConnectedApps = () => {
  const connectedApps = localStorage.getItem("connectedApps");
  return connectedApps ? JSON.parse(connectedApps) : [];
};

export const setConnectedApp = (app, data) => {
  const connectedApps = getConnectedApps();
  const newConnectedApps = [...connectedApps, app];
  localStorage.setItem("connectedApps", JSON.stringify(newConnectedApps));
  localStorage.setItem(app, JSON.stringify(data));
};

export const removeConnectedApp = (app) => {
  const connectedApps = getConnectedApps();
  const updateConnectedApps = connectedApps.filter((item) => item !== app);
  localStorage.setItem("connectedApps", JSON.stringify(updateConnectedApps));
  localStorage.removeItem(app);
  localStorage.removeItem(`${app}_session_id`);
};
