import { VStack, Text, HStack, Input, Divider, Badge } from "@chakra-ui/react";
import { useMemo, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import InsightsStats from "./InsightsStats";
import InsightsCharts from "./InsightsCharts";
import { RivrSearch } from "../../../models/rivrSearch";
import { CampaignURLParams } from "../../../models/navigation";

type CampaignInsightsProps = {
  searches: RivrSearch[];
};

const CampaignInsights: React.FC<CampaignInsightsProps> = ({ searches }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";
  const selectedChannels = searchParams.getAll(CampaignURLParams.SelectedChannels) || [];

  // Extracting dfs and dfe (start and end dates) from the URL params
  const initialStartDate = searchParams.get("dfs") || "";
  const initialEndDate = searchParams.get("dfe") || "";

  const [startDate, setStartDate] = useState<string>(initialStartDate);
  const [endDate, setEndDate] = useState<string>(initialEndDate);

  // Update URL when the date inputs are changed
  useEffect(() => {
    if (startDate) {
      searchParams.set("dfs", startDate);
    } else {
      searchParams.delete("dfs");
    }
    if (endDate) {
      searchParams.set("dfe", endDate);
    } else {
      searchParams.delete("dfe");
    }
    setSearchParams(searchParams);
  }, [startDate, endDate, searchParams, setSearchParams]);

  // Filter searches based on selected channels and date range
  const filteredSearches = useMemo(() => {
    if (selectedChannels.length === 0 && (!startDate || !endDate)) return searches;

    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    return searches.filter((s) => {
      const publishedDate = s.metadata?.publishedAt ? new Date(s.metadata.publishedAt) : null;
      const isWithinDateRange = publishedDate
        ? (!start || publishedDate >= start) && (!end || publishedDate <= end)
        : true;
      const isChannelSelected =
        selectedChannels.length === 0 || selectedChannels.includes(s.metadata?.userName || "");
      return isChannelSelected && isWithinDateRange;
    });
  }, [searches, selectedChannels, startDate, endDate]);

  return selectedCampaign === "" ? (
    <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
      <Text fontSize="md" color="gray.300" textAlign="center">
        Select a campaign
      </Text>
    </HStack>
  ) : (
    <VStack
      h="100%"
      w="100%"
      alignItems="flex-start"
      overflowY="auto"
      p={4}
      className="campaign-insights"
    >
      <VStack alignItems="flex-start" w="100%" spacing={4} h={"100%"}>
        <HStack w="100%" justifyContent="space-between">
          <HStack>
            <Text cursor="default" fontWeight="semibold" fontSize="xl">
              Insights
            </Text>
            <Badge colorScheme={"blue"} letterSpacing={1}>
              Beta
            </Badge>
          </HStack>
          <HStack justify="end" w="100%">
            <Text size="sm">Date:</Text>
            <Input
              size="sm"
              borderRadius="md"
              maxW="min-content"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <Text>-</Text>
            <Input
              size="sm"
              borderRadius="md"
              maxW="min-content"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </HStack>
        </HStack>
        <Divider />
        <VStack
          w={"100%"}
          h={"100%"}
          align={"start"}
          spacing={6}
          flex={1}
          className="stats-chart-wrapper"
        >
          <InsightsStats searches={filteredSearches} />
          <InsightsCharts searches={filteredSearches} />
        </VStack>
      </VStack>
    </VStack>
  );
};

export default CampaignInsights;
