export type MentionsSearch = {
  id: string;
  title: string;
  keywords: string[];
  createdAt: string;
  updatedAt: string;
};

export function mentionsSearchFromQueryResult(search: any): MentionsSearch {
  return {
    id: search.id,
    title: search.title,
    keywords: search.search === "" ? [] : search.search.split(" OR "),
    createdAt: search.created_at,
    updatedAt: search.updated_at,
  };
}
