import { useContext, useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Link,
  Input,
  Text,
  Button,
  VStack,
  HStack,
  Textarea,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useFeedbackModal } from "../../context/FeedbackModalContext";
import { AccountContext } from "../../context/AccountContext";
import { useSearchParams } from "react-router-dom";
import { CampaignURLParams } from "../../models/navigation";

const FeedbackForm = () => {
  const { isOpen, onClose } = useFeedbackModal();
  const { account, memberships } = useContext(AccountContext);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [searchParams] = useSearchParams();
  const selectedOrg = searchParams.get(CampaignURLParams.SelectedOrganization) || "";

  const selectedMembership = useMemo(() => {
    return selectedOrg !== ""
      ? memberships.find((m) => m.organization.id === selectedOrg)
      : memberships[0];
  }, [selectedOrg, memberships]);

  const emojis = ["😡", "🙁", "😐", "🙂", "😄"];

  const orgFeedbackUrl =
    "https://discord.com/api/webhooks/1250879647240028211/IAkE5JgDJb5QgvwInq0hXgPNlzBIGcS2HoDUOlcHxHTTR3kFsHgZR37fxbnMgaMClQVU?wait=true";
  const generalFeedbackUrl =
    "https://discord.com/api/webhooks/1152274639289073755/3lLkJcHr66i-Xprc4pXS27AHd8BDkS4G7Zl-1jnCGQdRwWmP27R2hnxmMqpFyCayX3-Y?wait=true";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);
    if (!subject || !message || !selectedEmoji) {
      setError("All fields are required.");
      setIsLoading(false);
      return;
    }

    const contentWithEmoji =
      selectedMembership !== undefined
        ? `**UserID:** ${account.id}\n**Org:** ${selectedMembership.organization.name}\n**OrgID:** ${selectedMembership.organization.id}\n**Email:** ${account.email}\n\nHow do you feel?: ${selectedEmoji}\n\nFeedback: ${message}`
        : `**UserID:** ${account.id}\n**Email:** ${account.email}\n\nHow do you feel?: ${selectedEmoji}\n\nFeedback: ${message}`;

    const url = selectedMembership !== undefined ? orgFeedbackUrl : generalFeedbackUrl;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          selectedMembership !== undefined
            ? { content: contentWithEmoji }
            : { content: contentWithEmoji, thread_name: subject }
        ),
      });

      if (!response.ok) {
        throw new Error("Failed to submit feedback, please try again.");
      }
      setSubject("");
      setMessage("");
      setSuccessMessage("Feedback submitted successfully.");
      setError(null);
      setSelectedEmoji(null);
    } catch (err) {
      setSuccessMessage(null);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    setError(null);
    setSelectedEmoji(null);
    setSuccessMessage(null);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={true} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader pb={1}>Share feedback</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <VStack alignItems={"flex-start"} spacing={4}>
                <Text color="gray.300" fontSize={"sm"}>
                  The comments you share here are for the Rivr team—and we appreciate them!
                </Text>
                {memberships.length === 0 && (
                  <Text color="gray.300" fontSize={"sm"}>
                    We may post your submission anonymously to our Discord's{" "}
                    <Link
                      color="purple.300"
                      href="https://discord.com/channels/908444171575103508/1092576689344741517"
                    >
                      #feedback channel
                    </Link>{" "}
                    if it warrants broader discussion.
                  </Text>
                )}
                <FormControl isRequired>
                  <FormLabel>How do you feel?</FormLabel>
                  <HStack w="100%">
                    {emojis.map((emoji, index) => (
                      <Button
                        w="20%"
                        size="lg"
                        key={index}
                        variant={"outline"}
                        onClick={() => setSelectedEmoji(emoji)}
                        colorScheme={emoji === selectedEmoji ? "green" : "gray"}
                        bg={emoji === selectedEmoji ? "teal.900" : ""}
                      >
                        {emoji}
                      </Button>
                    ))}
                  </HStack>
                </FormControl>
                <FormControl isRequired isInvalid={!subject && error}>
                  <FormLabel>Subject</FormLabel>
                  <Input
                    className={"amp-unmask"}
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired isInvalid={!message && error}>
                  <FormLabel>What would you like to share with us?</FormLabel>
                  <Textarea
                    className={"amp-unmask"}
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </FormControl>
                {error && (
                  <Alert status="error" variant="solid" borderRadius={"md"}>
                    <AlertIcon />
                    {error}
                  </Alert>
                )}
                {successMessage && (
                  <Alert status="success" variant="solid" borderRadius={"md"}>
                    <AlertIcon />
                    {successMessage}
                  </Alert>
                )}
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={"green"}
              type="submit"
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FeedbackForm;
