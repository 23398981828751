import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Onboarding from "./Onboarding";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  useDisclosure,
} from "@chakra-ui/react";
import { AccountContext } from "../../context/AccountContext";
import Confetti from "react-confetti";
import * as amplitude from "@amplitude/analytics-browser";
import BillingCta from "../BillingCta/BillingCta";
import Cookies from "js-cookie";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Rocket } from "lucide-react";

export const OnboardingModal = () => {
  const { getFlag } = useKindeAuth();
  const { account } = useContext(AccountContext) || {};
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [runConfetti, setRunConfetti] = useState(false);

  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const isBetaUser = getFlag("beta-user", false).value as boolean;

  const hasSearchAccess = isAdmin || isBetaUser || account.subscription_status === "active";

  const {
    isOpen: isTrialOnboardingCTAOpen,
    onClose: onTrialOnboardingCTAClose,
    onOpen: onTrialOnboardingCTAOpen,
  } = useDisclosure();

  const completedOnboarding = account.onboarding_response !== null;
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === "/search" &&
      account.memberships.length === 0 &&
      !completedOnboarding
    ) {
      setIsOpen(true);
      handleAmplitudeTrack("Onboarding Initialized");
    }
  }, [completedOnboarding, account.memberships.length, location.pathname]);

  useEffect(() => {
    if (step === 4) {
      setRunConfetti(true);
      const timer = setTimeout(() => {
        setRunConfetti(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [step]);

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <>
      <BillingCta
        isOpen={isTrialOnboardingCTAOpen}
        onClose={onTrialOnboardingCTAClose}
        optionalIcon={Rocket}
        optionalHeading={"Unlock your full potential"}
        optionalText={
          "Gain access to exclusive opportunities like promotions, spotlights, and the full power of Rivr."
        }
      />
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size={"2xl"}
        isCentered
        closeOnEsc={false}
        closeOnOverlayClick={false}
        autoFocus
        blockScrollOnMount
      >
        <ModalOverlay />
        <ModalContent borderRadius={"md"}>
          <Box
            pos={"fixed"}
            top={0}
            left={0}
            w={"100vw"}
            h={"100vh"}
            pointerEvents={"none"}
            userSelect={"none"}
          >
            {runConfetti && (
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                numberOfPieces={500}
                gravity={0.05}
                recycle={false}
              />
            )}
          </Box>
          <ModalHeader bg={"gray.900"} borderTopRadius={"md"} py={6}>
            <Progress
              value={(step / 4) * 100}
              size={"sm"}
              colorScheme={"green"}
              borderRadius={"md"}
            />
          </ModalHeader>
          <ModalBody bg={"gray.800"} p={8} borderBottomRadius={"md"}>
            <Onboarding
              setIsOpen={setIsOpen}
              step={step}
              setStep={setStep}
              onOnboardingComplete={!hasSearchAccess ? onTrialOnboardingCTAOpen : undefined}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OnboardingModal;
