import { useState } from "react";
import {
  HStack,
  Text,
  Box,
  Skeleton,
  Center,
  Spinner,
  Flex,
  Image,
  IconButton,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import { originTwitch, originYouTube } from "../../models/urls";
import TwitchLogo from "../../assets/twitch-logo.svg";
import YouTubeLogo from "../../assets/youtube-logo.svg";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { GenericPlayer } from "./GenericPlayer";
import { CircleAlert } from "lucide-react";

type Props = {
  videoInfo: any;
  seekTime: { seconds: number } | null;
  pausePlayer: { pause: boolean };
  updatePlayTime: (seconds: number) => void;
};

export const MediaPlayer = (props: Props) => {
  const { videoInfo, seekTime, pausePlayer, updatePlayTime } = props;

  const [playerError, setPlayerError] = useState<any>(null);
  const [retryKey, setRetryKey] = useState(0);
  const handlePlayerError = (error: any) => {
    setPlayerError(error);
  };

  return (
    <>
      <HStack minH={9} maxH={9} w={"100%"} justifyContent={"space-between"}>
        {videoInfo ? (
          <>
            <HStack>
              {videoInfo.origin_type === originTwitch ? (
                <Image src={TwitchLogo} alt="Twitch Logo" title="Twitch" h="18px" mr={1} />
              ) : videoInfo.origin_type === originYouTube ? (
                <Image src={YouTubeLogo} alt="YouTube Logo" title="YouTube" h="18px" mr={1} />
              ) : null}
              <Text fontSize="lg" fontWeight="medium" noOfLines={1} title={videoInfo.video_title}>
                {videoInfo.video_title}
              </Text>
            </HStack>
            <Tooltip
              label={
                videoInfo.origin_type === originTwitch
                  ? "View on Twitch"
                  : videoInfo.origin_type === originYouTube
                  ? "View on YouTube"
                  : "View original video"
              }
            >
              <IconButton
                size={"xs"}
                variant={"ghost"}
                as={"a"}
                href={videoInfo.url}
                target={"_blank"}
                aria-label={
                  videoInfo.origin_type === originTwitch
                    ? "View on Twitch"
                    : videoInfo.origin_type === originYouTube
                    ? "View on YouTube"
                    : "View original video"
                }
                icon={<ExternalLinkIcon />}
              />
            </Tooltip>
          </>
        ) : (
          <Skeleton height="20px" w="50%" />
        )}
      </HStack>

      <Box
        h={"100%"}
        backgroundColor="gray.700"
        p={3}
        borderWidth={1}
        borderRadius={"md"}
        className={"player-wrapper"}
      >
        {!videoInfo ? (
          <Center flexDirection="column" justifyContent="center" height="100%">
            <Flex alignItems="center">
              <Text mr="2" fontSize="lg" fontWeight="medium" textAlign={"center"}>
                Analyzing media...
              </Text>
              <Spinner size="sm" />
            </Flex>
          </Center>
        ) : playerError ? (
          <Center flexDirection="column" justifyContent="center" height="100%" gap={2}>
            <Box color={"red.400"}>
              <CircleAlert size={48} color="currentColor" />
            </Box>
            <Text textAlign={"center"} w={"fit-content"} sx={{ textWrap: "balance" }}>
              {"An error occurred while playing the video"}
            </Text>
            <Text
              textAlign={"center"}
              w={"fit-content"}
              sx={{ textWrap: "balance" }}
              color={"red.400"}
              fontSize={"xs"}
              px={6}
            >
              {playerError.message}
            </Text>
            <Button
              mt={2}
              size={"sm"}
              w={"fit-content"}
              onClick={() => {
                setPlayerError(null);
                setRetryKey((prevKey) => prevKey + 1);
              }}
            >
              Reload
            </Button>
          </Center>
        ) : (
          <GenericPlayer
            key={retryKey}
            seekTime={seekTime}
            pausePlayer={pausePlayer}
            videoInfo={{
              videoId: videoInfo.video_id,
              videoType: videoInfo.video_type,
              url: videoInfo.url,
              originType: videoInfo.origin_type,
            }}
            updatePlayTime={updatePlayTime}
            onError={handlePlayerError}
          />
        )}
      </Box>
    </>
  );
};
