export type RivrLanguage = {
  name: string;
  code: string;
};

export function getLanguageDisplayName(code: string, displayLanguage = "en"): string {
  const languageDisplayNames = new Intl.DisplayNames([displayLanguage], { type: "language" });

  return languageDisplayNames.of(code) ?? "none";
}
