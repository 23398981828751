import React, { useEffect, useState, ReactNode, useContext } from "react";
import { Box, Center, HStack, Link as ChakraLink, VStack } from "@chakra-ui/react";
import { AccountContext } from "../../context/AccountContext";
import KbNavigation from "./KbNavigation";
import LinkedHeading from "./KbLinkedHeading";

interface KbLayoutProps {
  children: ReactNode;
}

const KbLayout = ({ children }: KbLayoutProps) => {
  const [headers, setHeaders] = useState<React.ReactElement[]>([]);
  const { memberships } = useContext(AccountContext);
  const isOrgMember = memberships.length > 0;

  const getPaddingLeft = (headerAs: string) => {
    const levels: { [key: string]: number } = { h2: 4, h3: 8, h4: 14, h5: 18 };
    return levels[headerAs] || "0";
  };

  const findHeadings = (elements: ReactNode): React.ReactElement[] => {
    const headings: React.ReactElement[] = [];
    React.Children.forEach(elements, (element) => {
      if (React.isValidElement(element)) {
        if (element.type === LinkedHeading) {
          headings.push(element);
        }
        if (element.props && element.props.children) {
          headings.push(...findHeadings(element.props.children));
        }
      }
    });
    return headings;
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: string) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    const headerArray = findHeadings(children);
    setHeaders(headerArray);
  }, [children]);

  return (
    <Box w={"100%"} height={"100%"} position={"relative"} overflow={"hidden"}>
      <HStack
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        h={"100%"}
        position={"relative"}
      >
        <Box
          className="sidebar-container"
          w={"300px"}
          h={"100%"}
          overflowY={"auto"}
          sx={{ scrollbarWidth: "thin" }}
          maxH={"100%"}
          borderRightWidth={1}
          bg={"gray.900"}
        >
          <KbNavigation isOrgMember={isOrgMember} />
        </Box>

        <Center
          className={"content-ql-container"}
          w={"100%"}
          alignItems={"flex-start"}
          h={"100%"}
          overflowY={"auto"}
          sx={{ scrollbarWidth: "thin" }}
        >
          <Box py={12} className={"content-container"} maxW={"1400px"} w={"100%"} px={6}>
            {children}
          </Box>
          <Box
            className="ql-container"
            w="100%"
            maxW="270px"
            pl={5}
            position="sticky"
            top="0"
            overflow={"hidden"}
          >
            <VStack
              alignItems="flex-start"
              spacing={3}
              fontSize="sm"
              borderLeft="1px solid"
              borderLeftColor={"gray.600"}
              pl="4"
              py="6"
              mt="12"
            >
              {headers.map((header, index) => (
                <ChakraLink
                  key={index}
                  w="full"
                  href={`#${header.props.id}`}
                  _hover={{ color: "purple.300", transform: "translateX(2px)" }}
                  _focus={{ color: "purple.300", transform: "translateX(2px)" }}
                  pl={getPaddingLeft(header.props.as)}
                  onClick={(e) => handleLinkClick(e, header.props.id)}
                >
                  {header.props.children}
                </ChakraLink>
              ))}
            </VStack>
          </Box>
        </Center>
      </HStack>
    </Box>
  );
};

export default KbLayout;
