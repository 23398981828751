import { motion } from "framer-motion";

const AnimatedDownloadIcon = ({ isAnimating }) => {
  const arrowVariants = {
    initial: { y: -2, opacity: 1 },
    animate: {
      y: [-2, 2],
      opacity: [1, 0],
      transition: {
        duration: 1,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "loop",
      },
    },
  };

  return (
    <svg viewBox="0 0 14 14" width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.path
        fill="currentColor"
        d="M11.2857,6.05714 L10.08571,4.85714 L7.85714,7.14786 L7.85714,1 L6.14286,1 L6.14286,7.14786 L3.91429,4.85714 L2.71429,6.05714 L7,10.42857 L11.2857,6.05714 Z"
        variants={arrowVariants}
        initial="initial"
        animate={isAnimating ? "animate" : "initial"}
      />
      <path
        fill="currentColor"
        d="M1,11.2857 L1,13 L13,13 L13,11.2857 L1,11.2857 Z"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AnimatedDownloadIcon;
