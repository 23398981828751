import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  ModalFooter,
  Text,
  Link,
  Alert,
  AlertIcon,
  Box,
  HStack,
  Heading,
  Icon,
} from "@chakra-ui/react";

import PlanCard from "../PlanCard/PlanCard";
import { OrganizationMembership } from "../../models/rivrOrganization";
import { useContext, ReactNode, ElementType } from "react";
import { AccountContext } from "../../context/AccountContext";

type BillingCtaProps = {
  membership?: OrganizationMembership;
  isOpen: boolean;
  onClose: () => void;

  optionalIcon?: ElementType;
  optionalHeading?: string | ReactNode;
  optionalText?: string | ReactNode;
};

const BillingCta = ({
  membership,
  isOpen,
  onClose,
  optionalIcon,
  optionalHeading,
  optionalText,
}: BillingCtaProps) => {
  const { account } = useContext(AccountContext);

  const role = membership?.role;
  const subscriptionExists =
    membership !== undefined
      ? membership.organization.subscriptionStatus !== "none" &&
        membership.organization.subscriptionId !== null
      : account.subscription_status !== "none" && account.subscription_id !== null;

  const hasOptionalContent =
    optionalIcon !== undefined || optionalHeading !== undefined || optionalText !== undefined;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered motionPreset={"scale"} autoFocus>
      <ModalOverlay />
      <ModalContent sx={{ maxWidth: "max-content" }}>
        <ModalHeader>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <VStack gap={4} alignItems={"center"} pt={2}>
            {membership === undefined && hasOptionalContent && (
              <HStack w={"100%"} justifyContent={"space-evenly"} align={"center"} gap={12}>
                <VStack w={"100%"} maxW={80} h={"100%"} align={"start"} justify={"start"} gap={2}>
                  {optionalIcon && (
                    <Box color={"blue.300"}>
                      <Icon
                        as={optionalIcon}
                        boxSize={12}
                        strokeWidth={1.5}
                        color={"currentcolor"}
                      />
                    </Box>
                  )}
                  {optionalHeading && <Heading fontSize={"4xl"}>{optionalHeading}</Heading>}
                  {optionalText && (
                    <Text fontSize={"lg"} color={"gray.300"} sx={{ textWrap: "pretty" }} pt={2}>
                      {optionalText}
                    </Text>
                  )}
                </VStack>
                <PlanCard
                  planType={"EA"}
                  planAvailable={true}
                  showSubscribe={true}
                  subscriptionExists={subscriptionExists}
                />
              </HStack>
            )}

            {membership === undefined && !hasOptionalContent && (
              <PlanCard
                planType={"EA"}
                planAvailable={true}
                showSubscribe={true}
                subscriptionExists={subscriptionExists}
              />
            )}

            {membership !== undefined && role === "owner" && (
              <PlanCard
                orgId={membership.organization.id}
                planType={"Enterprise"}
                planAvailable={true}
                showSubscribe={true}
                subscriptionExists={subscriptionExists}
              />
            )}

            {membership !== undefined && role !== "owner" && (
              <>
                <Alert
                  mt={2}
                  maxW={"30rem"}
                  status={"error"}
                  textAlign={"center"}
                  borderRadius={"md"}
                  justifyContent={"center"}
                >
                  <AlertIcon />
                  No active subscription
                </Alert>
                <Text textAlign={"center"} sx={{ textWrap: "balance" }}>
                  Please contact your organization owner to reactivate your Rivr subscription.
                </Text>
              </>
            )}

            {subscriptionExists && role === "owner" && (
              <Text fontSize={"xs"} color={"gray.300"}>
                If you believe there is an error with your subscription, please{" "}
                <Link textDecor={"underline"} href={"mailto:sales@rivr.stream"} target={"_blank"}>
                  contact sales
                </Link>
                .
              </Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};

export default BillingCta;
