export async function hexDump(file: Blob) {
  // Used primarily to store images for thumbnails in database
  // See https://stackoverflow.com/a/76241976
  function hex(buffer: ArrayBuffer) {
    return Array.from(new Uint8Array(buffer))
      .map((x) => x.toString(16).padStart(2, "0"))
      .join("");
  }

  return new Promise<string>((res) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result !== "string" && reader.result) {
        res(hex(reader.result));
      }
    };
    reader.readAsArrayBuffer(file);
  });
}

export const hexToBase64 = (hexInput: string): string =>
  // Used primarily to read images for thumbnails from database
  // See https://stackoverflow.com/a/76241976
  btoa(
    (hexInput.replace("\\x", "").match(/\w{2}/g) ?? [])
      .map((a: string) => String.fromCharCode(parseInt(a, 16)))
      .join("")
  );
