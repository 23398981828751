import { useState } from "react";
import { useMutation } from "@apollo/client";
import { InsertCampaignWatcherMutation } from "../../api/campaign-watcher";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  HStack,
  VStack,
  IconButton,
  Select,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

interface KeyCampaignLinkerProps {
  campaigns: { id: string; title: string }[];
  selectedOrganization: string;
}

const KeyCampaignLinker: React.FC<KeyCampaignLinkerProps> = ({
  campaigns,
  selectedOrganization,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [campaignId, setCampaignId] = useState("");
  const [twitchCategoryIds, setTwitchCategoryIds] = useState<string[]>([""]);
  const [loading, setLoading] = useState(false);

  const [insertCampaignWatcher] = useMutation(InsertCampaignWatcherMutation);
  const toast = useToast();

  const handleInputChange = (index: number, value: string) => {
    const newTwitchCategoryIds = [...twitchCategoryIds];
    newTwitchCategoryIds[index] = value;
    setTwitchCategoryIds(newTwitchCategoryIds);
  };

  const handleAddInput = () => {
    setTwitchCategoryIds([...twitchCategoryIds, ""]);
  };

  const handleRemoveInput = (index: number) => {
    if (twitchCategoryIds.length > 1) {
      const newTwitchCategoryIds = twitchCategoryIds.filter((_, i) => i !== index);
      setTwitchCategoryIds(newTwitchCategoryIds);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!campaignId) {
      toast({
        title: "Invalid campaign ID",
        description: "Please select a campaign ID.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    for (const twitchCategoryId of twitchCategoryIds) {
      if (!/^\d+$/.test(twitchCategoryId)) {
        toast({
          title: "Invalid Twitch Category ID",
          description: "The Twitch Category ID must only contain numbers.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }

    setLoading(true);

    try {
      await Promise.all(
        twitchCategoryIds.map((twitchCategoryId) =>
          insertCampaignWatcher({
            variables: {
              campaign_id: campaignId,
              twitch_game_id: twitchCategoryId,
            },
          })
        )
      );

      toast({
        title: "Campaign linked",
        description: "The campaign has been successfully linked to the Twitch Category ID(s).",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (err) {
      console.error(err);
      toast({
        title: "An error occurred",
        description: "Unable to link the campaign to the Twitch Category ID(s).",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        size={"sm"}
        onClick={onOpen}
        colorScheme="orange"
        minW={"fit-content"}
        isDisabled={!selectedOrganization}
      >
        Link to Watcher
      </Button>
      <Modal isOpen={isOpen} onClose={() => !loading && onClose()} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Link campaign to watcher</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <FormControl mb={4} isRequired>
                <FormLabel>Campaign</FormLabel>
                <Select
                  placeholder="Select a campaign"
                  value={campaignId}
                  onChange={(e) => setCampaignId(e.target.value)}
                >
                  {campaigns.map((campaign) => (
                    <option key={campaign.id} value={campaign.id}>
                      {campaign.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormLabel>Twitch category ID(s)</FormLabel>
              <VStack>
                {twitchCategoryIds.map((twitchCategoryId, index) => (
                  <HStack key={index} w="100%">
                    <Input
                      className={"amp-unmask"}
                      type="text"
                      value={twitchCategoryId}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      isRequired
                    />
                    {index !== 0 && (
                      <IconButton
                        icon={<MinusIcon />}
                        aria-label="Remove"
                        variant={"ghost"}
                        onClick={() => handleRemoveInput(index)}
                        isDisabled={twitchCategoryIds.length === 1}
                      />
                    )}
                  </HStack>
                ))}
                <IconButton
                  aria-label="Add Twitch ID row"
                  onClick={handleAddInput}
                  icon={<AddIcon />}
                  size="sm"
                  colorScheme="blue"
                  w={"100%"}
                />
              </VStack>
            </form>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button variant="ghost" onClick={onClose} isDisabled={loading}>
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={handleSubmit}
                isLoading={loading}
                isDisabled={!campaignId}
              >
                Link
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default KeyCampaignLinker;
