export type Action = {
  id?: number;
  title?: string;
  type: ActionType;
  output_destination: ActionDestination;
};

type ActionType = ClipAction;

type ClipAction = {
  kind: "Clip";
  config: ClipConfiguration;
};

type ClipConfiguration = HorizontalClip | VerticalClip;

type HorizontalClip = {
  kind: "16:9";
};

type VerticalClip = {
  kind: "9:16";
  content: ContentMapping;
  facecam?: ContentMapping;
};

type ContentMapping = {
  source: ContentBox;
  destination: ContentBox;
};

export type Dimensions = {
  width: number;
  height: number;
};

export type ContentBox = Dimensions & {
  x: number;
  y: number;
};

type ActionDestination = BrowserDownload | GoogleDrive;

type BrowserDownload = {
  kind: "Browser";
};

type GoogleDrive = {
  kind: "Google Drive";
};

function configFromForm(actionConfiguration: any): ClipConfiguration {
  switch (actionConfiguration.aspectRatio) {
    case "9:16":
      const content = actionConfiguration.box.contentCrop
        ? {
            source: {
              x: Number(actionConfiguration.box.contentCrop.x),
              y: Number(actionConfiguration.box.contentCrop.y),
              width: Number(actionConfiguration.box.contentCrop.width),
              height: Number(actionConfiguration.box.contentCrop.height),
            },
            destination: {
              x: Number(actionConfiguration.box.contentPreview.x),
              y: Number(actionConfiguration.box.contentPreview.y),
              width: Number(actionConfiguration.box.contentPreview.width),
              height: Number(actionConfiguration.box.contentPreview.height),
            },
          }
        : {
            source: {
              x: 50 - ((9 / 16) * (9 / 16) * 100) / 2,
              y: 0,
              width: (9 / 16) * (9 / 16) * 100,
              height: 100,
            },
            destination: {
              x: 0,
              y: 0,
              width: 100,
              height: 100,
            },
          };
      const config: VerticalClip = {
        kind: "9:16",
        content,
      };
      if (actionConfiguration.isFreeFacecam === "Freeform" && actionConfiguration.box.facecamCrop)
        config.facecam = {
          source: {
            x: Number(actionConfiguration.box.facecamCrop.x),
            y: Number(actionConfiguration.box.facecamCrop.y),
            width: Number(actionConfiguration.box.facecamCrop.width),
            height: Number(actionConfiguration.box.facecamCrop.height),
          },
          destination: {
            x: Number(actionConfiguration.box.facecamPreview.x),
            y: Number(actionConfiguration.box.facecamPreview.y),
            width: Number(actionConfiguration.box.facecamPreview.width),
            height: Number(actionConfiguration.box.facecamPreview.height),
          },
        };
      return config;
    default:
      return {
        kind: "16:9",
      };
  }
}

export function actionFromForm(formData: any): Action {
  const type: ActionType = {
    kind: "Clip",
    config: configFromForm(formData.actionConfiguration),
  };
  const output_destination = { kind: formData.outputDestination };
  return {
    title: formData.actionConfiguration.title,
    type,
    output_destination,
  };
}

export function formFromAction(action: Action): any {
  const formData: any = {
    actionType: action.type.kind,
    outputDestination: action.output_destination.kind,
    actionConfiguration: {
      title: action.title,
      aspectRatio: action.type.config.kind,
      isFreeFacecam:
        action.type.config.kind === "9:16" && action.type.config.facecam ? "Freeform" : "None",
      previewImage: null,
      box: {},
    },
  };
  if (action.type.config.kind === "9:16") {
    formData.actionConfiguration.box.contentCrop = action.type.config.content.source;
    formData.actionConfiguration.box.contentPreview = action.type.config.content.destination;
    if (action.type.config.facecam) {
      formData.actionConfiguration.box.facecamCrop = action.type.config.facecam.source;
      formData.actionConfiguration.box.facecamPreview = action.type.config.facecam.destination;
    }
  }
  return formData;
}

export const defaultAction: Action = {
  type: {
    kind: "Clip",
    config: {
      kind: "16:9",
    },
  },
  output_destination: {
    kind: "Browser",
  },
};
