import { AlertIcon, Alert, Center, Text, HStack, Button } from "@chakra-ui/react";
import { ArrowRight } from "@icon-park/react";

const DemoAlert = () => {
  return (
    <Center w={"100%"} pos={"sticky"} top={0} h={12}>
      <Alert
        w={"100%"}
        status={"error"}
        variant={"subtle"}
        justifyContent={"center"}
        bg={"rivr.red"}
        py={1.5}
        h={12}
      >
        <HStack>
          <AlertIcon mr={0} />
          <Text pr={2}>
            You're currently in the demo organization which does not allow certain actions.
          </Text>
          <Button
            minW={"max-content"}
            size={"sm"}
            variant={"outline"}
            rightIcon={<ArrowRight />}
            as={"a"}
            href={"mailto:sales@rivr.stream"}
            target={"_blank"}
          >
            Contact sales
          </Button>
        </HStack>
      </Alert>
    </Center>
  );
};

export default DemoAlert;
