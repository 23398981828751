import { Box, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import {
  AlignBottom,
  AlignHorizontally,
  AlignLeft,
  AlignRight,
  AlignTop,
  AlignVertically,
  Pound,
} from "@icon-park/react";
import { useState } from "react";
import { ContentBox } from "../../models/action";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  contentBox: ContentBox;
  setContentBox: (box: ContentBox) => void;
};

export const AlignmentControls = (props: Props) => {
  const buttonSize = 24;
  const buttonPadding = 4;
  const boxPadding = 4;
  const backgroundColor = "blackAlpha.500";
  const { contentBox, setContentBox } = props;
  const [showButtons, setShowButtons] = useState(false);

  const buttons = [
    {
      label: "Align horizontally",
      icon: AlignHorizontally,
      func: () => setContentBox({ ...contentBox, x: 0.5 - contentBox.width / 2 }),
    },
    {
      label: "Align vertically",
      icon: AlignVertically,
      func: () => setContentBox({ ...contentBox, y: 0.5 - contentBox.height / 2 }),
    },
    {
      label: "Align to top",
      icon: AlignTop,
      func: () => setContentBox({ ...contentBox, y: 0 }),
    },
    {
      label: "Align to bottom",
      icon: AlignBottom,
      func: () => setContentBox({ ...contentBox, y: 1 - contentBox.height }),
    },
    {
      label: "Align left",
      icon: AlignLeft,
      func: () => setContentBox({ ...contentBox, x: 0 }),
    },
    {
      label: "Align right",
      icon: AlignRight,
      func: () => setContentBox({ ...contentBox, x: 1 - contentBox.width }),
    },
  ];

  const toggleButtons = () => setShowButtons(!showButtons);

  const handleAmplitudeTrack = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Flex position={"absolute"} top={"100%"} left={"50%"} className={"selector-alignment"}>
      <Flex
        bg={backgroundColor}
        ml={`-${buttonSize / 2 + buttonPadding}px`}
        mt={`-${buttonSize + 2 * buttonPadding + boxPadding}px`}
        borderRadius={"full"}
        w={`${buttonSize + 2 * buttonPadding}px`}
        h={`${buttonSize + 2 * buttonPadding}px`}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Tooltip label={"Alignment"} placement={"top"}>
          <IconButton
            aria-label={"Alignment"}
            icon={<Pound />}
            size={"sm"}
            borderRadius={"full"}
            variant={"outline"}
            h={`${buttonSize}px`}
            minW={`${buttonSize}px`}
            colorScheme={"gray"}
            onClick={toggleButtons}
            bg={"transparent"}
            _hover={{ bg: "whiteAlpha.200" }}
          />
        </Tooltip>
      </Flex>
      <Box
        className={showButtons ? "buttons-visible" : "buttons-hidden"}
        pt={`${boxPadding + 2}px`}
        ml={`-${(buttonSize * buttons.length + buttonPadding * (buttons.length + 1)) / 2}px`}
        position={"absolute"}
      >
        <Flex
          gap={`${buttonPadding}px`}
          bg={backgroundColor}
          p={`${buttonPadding}px`}
          borderRadius={"full"}
        >
          {buttons.map((button) => (
            <Tooltip label={button.label} key={button.label}>
              <IconButton
                aria-label={button.label}
                icon={<button.icon theme={"filled"} />}
                pos={"relative"}
                cursor={"pointer"}
                onClick={() => {
                  button.func();
                  handleAmplitudeTrack(`${button.label} Clicked`);
                }}
                size={"sm"}
                h={`${buttonSize}px`}
                minW={`${buttonSize}px`}
                borderRadius={"full"}
                variant={"outline"}
                colorScheme={"gray"}
                bg={"transparent"}
                _hover={{ bg: "whiteAlpha.200" }}
              />
            </Tooltip>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};
