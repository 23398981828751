import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, HStack, Text, IconButton, useBreakpointValue, Tooltip } from "@chakra-ui/react";
import { Analysis, Flag, Lightning, Tips } from "@icon-park/react";
import {
  CampaignSubPages,
  CampaignURLParams,
  RivrLocations,
  updateURLParams,
} from "../../models/navigation";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import Cookies from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";

const CampaignsNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPage = searchParams.get(CampaignURLParams.SubPage) || CampaignSubPages.Campaigns;

  const { getFlag } = useKindeAuth();
  const isInsightsUser = getFlag("insights", false).value as boolean;
  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const inCampaigns = location.pathname.startsWith(RivrLocations.Campaigns);
  const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false });

  const handleSubPageSelect = (subPage: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.SubPage, subPage]];

    if (inCampaigns) {
      setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: false });
    } else {
      navigate(`${RivrLocations.Campaigns}?${updateURLParams(searchParams.toString(), newParams)}`);
    }
  };

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  const renderCampaignsButton = (subPage: string, label: string, icon: JSX.Element) => {
    if (isMobile) {
      return (
        <Tooltip placement={"bottom"} label={label}>
          <Link
            to={`${RivrLocations.Campaigns}?${updateURLParams(searchParams.toString(), [
              [CampaignURLParams.SubPage, subPage],
            ])}`}
            onClick={() => {
              handleAmplitudeTrack("Navigated", {
                Label: label,
              });
            }}
          >
            <IconButton
              aria-label={label}
              variant={"ghost"}
              size={"sm"}
              icon={icon}
              onClick={() => handleSubPageSelect(subPage)}
              isActive={inCampaigns && selectedPage === subPage}
              colorScheme={inCampaigns && selectedPage === subPage ? "blue" : "gray"}
              fontWeight={"medium"}
              cursor={"pointer"}
            />
          </Link>
        </Tooltip>
      );
    }

    return (
      <Link
        to={`${RivrLocations.Campaigns}?${updateURLParams(searchParams.toString(), [
          [CampaignURLParams.SubPage, subPage],
        ])}`}
        onClick={() => {
          handleAmplitudeTrack("Navigated", {
            Label: label,
          });
        }}
      >
        <Button
          variant={"ghost"}
          size={"sm"}
          fontSize={"sm"}
          onClick={() => handleSubPageSelect(subPage)}
          isActive={inCampaigns && selectedPage === subPage}
          colorScheme={inCampaigns && selectedPage === subPage ? "blue" : "gray"}
          leftIcon={icon}
          fontWeight={"medium"}
          cursor={"pointer"}
        >
          <Text>{label}</Text>
        </Button>
      </Link>
    );
  };

  return (
    <HStack>
      {renderCampaignsButton(CampaignSubPages.Campaigns, "Campaigns", <Flag />)}
      {renderCampaignsButton(CampaignSubPages.Analyze, "Analyze", <Analysis />)}
      {renderCampaignsButton(CampaignSubPages.Discover, "Discover", <Lightning />)}
      {(isInsightsUser || isAdmin) &&
        renderCampaignsButton(CampaignSubPages.Insights, "Insights", <Tips />)}
    </HStack>
  );
};

export default CampaignsNavigation;
