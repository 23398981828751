import { PropsWithChildren, useEffect, useState } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AccountContext } from "../context/AccountContext";
import { useLazyQuery, useMutation, useQuery, NetworkStatus } from "@apollo/client";
import {
  AccountByKindeIdQuery,
  CreateAccountMutation,
  UpdateAccountMutation,
} from "../api/account";
import { KindLoading } from "../components/KindLoading/KindLoading";
import { HasuraError } from "../components/HasuraError/HasuraError";
import { CompleteTutorialMutation } from "../api/tutorial-progress";
import {
  OrganizationMembership,
  OrganizationRole,
  orgFromQueryResult,
} from "../models/rivrOrganization";
import { OrganizationsQuery, OrganizationsByIdQuery } from "../api/organization";
import Cookies from "js-cookie";
import { useToast } from "@chakra-ui/react";

export const AccountProvider = ({ children }: PropsWithChildren) => {
  const { user } = useKindeAuth();
  const toast = useToast();

  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const [account, setAccount] = useState<any>(null);
  const [memberships, setMemberships] = useState<OrganizationMembership[]>([]);

  const accountQuery = useQuery(AccountByKindeIdQuery, {
    variables: { kinde_id: user?.id },
    notifyOnNetworkStatusChange: true,
  });
  const [organizationsQuery] = useLazyQuery(isAdmin ? OrganizationsQuery : OrganizationsByIdQuery, {
    onCompleted(data) {
      setMemberships(
        data.organization
          .map((o: any) => {
            return account.memberships.find((m: any) => m.organization_id === o.id) !== undefined
              ? {
                  organization: orgFromQueryResult(o),
                  role: account.memberships.find((m: any) => m.organization_id === o.id)
                    .role as OrganizationRole,
                }
              : { organization: orgFromQueryResult(o), role: "owner" as OrganizationRole };
          })
          .sort((a: any, b: any) => {
            if (a.organization.id === process.env.REACT_APP_DEMO_ORG_ID) {
              return -1;
            } else if (b.organization.id === process.env.REACT_APP_DEMO_ORG_ID) {
              return 1;
            }
            return a.organization.name.localeCompare(b.organization.name);
          })
      );
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
  });
  const [createAccount, accountMutation] = useMutation(CreateAccountMutation, {
    variables: { kinde_id: user?.id, email: user?.email },
  });
  const [finishTutorial] = useMutation(CompleteTutorialMutation);

  const [accountUpdateAPI] = useMutation(UpdateAccountMutation, {
    onCompleted() {
      accountQuery.refetch();
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
      toast({
        title: "Error updating account",
        description: "There was an error while updating your account settings.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  useEffect(() => {
    if (accountQuery.data?.account.length === 0) createAccount();
    else if (accountQuery.data?.account.length > 0) {
      setAccount(accountQuery.data.account[0]);
      organizationsQuery({
        variables: isAdmin
          ? {}
          : {
              ids: accountQuery.data.account[0].memberships.map((m: any) => m.organization_id),
            },
      });
    }
  }, [accountQuery.data]);

  useEffect(() => {
    if (accountMutation.data) setAccount(accountMutation.data.insert_account_one);
  }, [accountMutation.data]);

  const completeTutorial = (tutorial: string) => {
    setAccount({ ...account, tutorial_progress: [...account.tutorial_progress, { tutorial }] });
    finishTutorial({ variables: { account_id: account.id, tutorial } });
  };

  return account ? (
    <AccountContext.Provider
      value={{
        account,
        memberships,
        organizationsQuery,
        completeTutorial,
        accountUpdateAPI,
        refreshAccount: accountQuery.refetch,
        accountLoading: accountQuery.networkStatus === NetworkStatus.refetch,
      }}
    >
      {children}
    </AccountContext.Provider>
  ) : accountQuery.error || accountMutation.error ? (
    <HasuraError />
  ) : (
    <KindLoading />
  );
};
