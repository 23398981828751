import { gql } from "@apollo/client";

// Queries
// Get all organizations (use for admin)
export const OrganizationsQuery = gql`
  query Organizations {
    organization {
      id
      name
      image
      website
      email
      onboarded
      subscription_status
      subscription_id
    }
  }
`;

export const OrganizationsByIdQuery = gql`
  query OrganizationsById($ids: [uuid!]) {
    organization(where: { id: { _in: $ids } }) {
      id
      name
      image
      website
      email
      onboarded
      subscription_status
      subscription_id
    }
  }
`;

export const OrganizationMembersByIdQuery = gql`
  query OrganizationMembersById($id: uuid!) {
    organization(where: { id: { _eq: $id } }) {
      id
      members {
        account {
          id
          email
          name
        }
        role
      }
    }
  }
`;

// Mutations
// Update org with desired parameters
export const UpdateOrganizationMutation = gql`
  mutation UpdateOrganization($id: uuid, $updates: organization_set_input) {
    update_organization(where: { id: { _eq: $id } }, _set: $updates) {
      returning {
        id
      }
    }
  }
`;
