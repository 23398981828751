import _ from "lodash";
import { dateToSeconds } from "./time";

export const simplify = (x: number[], y: number[], maxAngle = 0.001) => {
  if (x.length < 3) return [x, y];
  const xOut = [x[0]];
  const yOut = [y[0]];
  let start = 0;
  let lastPoint = [x[1], y[1]];
  for (let i = 2; i < x.length; ++i) {
    const slope = (y[i] - y[i - 1]) / (x[i] - x[i - 1]);
    const totalSlope = (y[i] - y[start]) / (x[i] - x[start]);
    if (Math.abs(Math.atan((slope - totalSlope) / (1 + slope * totalSlope))) > maxAngle) {
      xOut.push(lastPoint[0]);
      yOut.push(lastPoint[1]);
      start = i - 1;
    }
    lastPoint = [x[i], y[i]];
  }
  xOut.push(lastPoint[0]);
  yOut.push(lastPoint[1]);
  return [xOut, yOut];
};

export const getPeakInInterval = (
  data: { x: Date[]; y: number[] },
  start: number,
  end: number,
  mode: "min" | "max" = "max"
): number | undefined => {
  let index = 0;
  while (true) {
    if (index === data.x.length) return;
    if (dateToSeconds(data.x[index]) > start) break;
    ++index;
  }
  let firstValue = data.y[index];
  if (index > 0) {
    const x = [dateToSeconds(data.x[index - 1]), dateToSeconds(data.x[index])];
    const y = [data.y[index - 1], data.y[index]];
    firstValue = y[0] + ((start - x[0]) * (y[1] - y[0])) / (x[1] - x[0]);
  }
  const values = [firstValue];
  while (true) {
    if (index === data.x.length) return;
    if (dateToSeconds(data.x[index]) > end) break;
    values.push(data.y[index]);
    ++index;
  }
  let lastValue = data.y[index];
  if (index > 0) {
    const x = [dateToSeconds(data.x[index - 1]), dateToSeconds(data.x[index])];
    const y = [data.y[index - 1], data.y[index]];
    lastValue = y[0] + ((end - x[0]) * (y[1] - y[0])) / (x[1] - x[0]);
  }
  values.push(lastValue);
  if (mode === "max") return _.max(values);
  return _.min(values);
};
