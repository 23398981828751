import { Badge, Box, HStack, IconButton, Text, Tooltip, VStack } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { Afferent, Time } from "@icon-park/react";
import { Moment } from "../../models/moment";
import { Action } from "../../models/action";
import { ResultsURLParams, RivrLocations, updateURLParams } from "../../models/navigation";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

interface Props {
  download: any;
  downloaderRequest: (
    moments: Moment[],
    searchId: string,
    actions?: Action[],
    forVerticalFormat?: boolean
  ) => Promise<void>;
  isOrgMember: boolean;
}

const DownloadsItem: React.FC<Props> = ({ download, downloaderRequest, isOrgMember }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const title = download.clip_request.moment.title;
  const clipLength =
    download.clip_request.moment.end_time - download.clip_request.moment.start_time;
  const channelName =
    download.clip_request.search.user_name ?? download.clip_request.search.channel;
  const clipType = download.clip_request.action.type.config.kind;

  const viewLink = `${RivrLocations.Results}/${
    download.clip_request.moment.search_id
  }?${updateURLParams(searchParams.toString(), [
    [ResultsURLParams.SelectedMoment, download.clip_request.moment.id],
    [ResultsURLParams.SelectedTimestamp, ""],
    [ResultsURLParams.SelectedPanel, ""],
  ])}`;

  const formatClipLength = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return minutes > 0 ? `${minutes}m ${remainingSeconds}s` : `${remainingSeconds}s`;
  };

  const isLocation = (locations: string[]) =>
    locations.some((l) => location.pathname.startsWith(l));

  const createDownloaderRequest = () => {
    downloaderRequest([download.clip_request.moment], download.clip_request.moment.search_id, [
      download.clip_request.action,
    ]);
  };

  const handleAmplitudeTrack = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Box
      w={"100%"}
      h={"auto"}
      bg={"whiteAlpha.100"}
      borderRadius={"md"}
      borderWidth={1}
      px={3}
      py={2}
      _hover={{ bg: "whiteAlpha.300" }}
      transition={"0.2s"}
      position={"relative"}
      role={"group"}
    >
      <HStack w={"100%"}>
        <VStack align={"start"} spacing={1} w={"100%"}>
          <Text noOfLines={1} title={title}>
            {title}
          </Text>
          <HStack>
            <HStack
              spacing={1}
              color={"gray.300"}
              title={`Clip length: ${formatClipLength(clipLength)}`}
            >
              <Time size={14} />
              <Text fontSize={"xs"}>{formatClipLength(clipLength)}</Text>
            </HStack>
            <Badge
              size={"xs"}
              letterSpacing={1}
              title={`Clip type: ${clipType}`}
              fontWeight={"normal"}
            >
              {clipType}
            </Badge>
            {isOrgMember && (
              <Badge
                size={"xs"}
                letterSpacing={"wider"}
                title={`Channel: ${channelName}`}
                maxW={"20ch"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                fontWeight={"normal"}
                textTransform={"none"}
              >
                {channelName}
              </Badge>
            )}
          </HStack>
        </VStack>
        <HStack display={"none"} _groupHover={{ display: "flex" }} spacing={2}>
          <Tooltip label={"Download"} placement={"bottom-end"}>
            <IconButton
              aria-label={"Download"}
              icon={<DownloadIcon boxSize={4} />}
              size={"sm"}
              variant={"outline"}
              onClick={() => {
                createDownloaderRequest();
                handleAmplitudeTrack("Download Clip from Panel");
              }}
            />
          </Tooltip>
          <Tooltip label={"View in Results"} placement={"bottom-end"}>
            <IconButton
              aria-label={"View in Results"}
              icon={<Afferent size={16} />}
              variant={"outline"}
              size={"sm"}
              transform={"rotateY(180deg)"}
              onClick={() => {
                if (
                  isLocation([`${RivrLocations.Results}/${download.clip_request.moment.search_id}`])
                ) {
                  setSearchParams(
                    updateURLParams(searchParams.toString(), [
                      [ResultsURLParams.SelectedMoment, download.clip_request.moment.id],
                      [ResultsURLParams.SelectedTimestamp, ""],
                      [ResultsURLParams.SelectedPanel, ""],
                    ])
                  );
                } else {
                  navigate(viewLink);
                }
                handleAmplitudeTrack("Nav to Results from Downloads");
              }}
            />
          </Tooltip>
        </HStack>
      </HStack>
    </Box>
  );
};

export default DownloadsItem;
