import { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Button,
  VStack,
  Text,
  AspectRatio,
  HStack,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { ArrowLeft, ArrowRight } from "@icon-park/react";
import {
  IntroTour,
  SyncTour,
  AnalyzeTour,
  ResultsTour,
  ChatTour,
  TimelineTour,
  MomentsTour,
  FeaturesTour,
  VerticalTour,
  TourType,
} from "./TourContent";
import { useTutorial } from "../../hooks/useTutorial";
import * as amplitude from "@amplitude/analytics-browser";

interface TourModalProps {
  isOpen: boolean;
  onClose: () => void;
  tourType: TourType;
}

const TourModal: React.FC<TourModalProps> = ({ isOpen, onClose, tourType }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const tutorial = useTutorial(tourType);

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  useEffect(() => {
    setCurrentStep(0);
  }, [tourType, isOpen]);

  const tourSteps =
    tourType === "sync"
      ? SyncTour
      : tourType === "analyze"
      ? AnalyzeTour
      : tourType === "results"
      ? ResultsTour
      : tourType === "chat"
      ? ChatTour
      : tourType === "timeline"
      ? TimelineTour
      : tourType === "moments"
      ? MomentsTour
      : tourType === "features"
      ? FeaturesTour
      : tourType === "vertical"
      ? VerticalTour
      : IntroTour;

  const handleNext = () => {
    if (currentStep < tourSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleComplete = () => {
    tutorial.completeTutorial();
    onClose();
    handleAmplitudeTrack("Tutorial Completed", { Tour: tourType as string });
  };

  const handleModalClose = () => {
    onClose();
  };

  let content;
  let headline = "";

  const currentStepData = tourSteps[currentStep];

  if (currentStepData) {
    switch (currentStepData.type) {
      case "video":
        headline = currentStepData.videoHeadline;
        content = (
          <Box position="relative">
            <AspectRatio ratio={16 / 9} borderRadius={"md"} overflow={"hidden"} zIndex={1}>
              <iframe
                width={"100%"}
                src={currentStepData.content}
                title="Rivr Walkthrough"
                allowFullScreen
                allow={"autoplay"}
                key={currentStep}
              />
            </AspectRatio>
            <VStack align={"start"} w={"100%"} gap={6}>
              <Box fontSize={"md"} color={"gray.300"} sx={{ textWrap: "pretty" }} mt={4}>
                {currentStepData.videoText}
              </Box>
            </VStack>
          </Box>
        );
        break;
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      size={"4xl"}
      closeOnOverlayClick={true}
      closeOnEsc={true}
    >
      <ModalOverlay />
      <ModalContent overflow={"auto"} className={"test"} mt={"6rem"}>
        <ModalHeader display={"flex"} justifyContent={"space-between"} alignItems={"center"} pb={2}>
          {headline}
          <HStack align={"center"} justify={"end"}>
            {tourSteps.length > 1 && (
              <>
                <IconButton
                  aria-label={"Step back"}
                  icon={<ArrowLeft />}
                  onClick={handlePrevious}
                  isDisabled={currentStep === 0}
                  variant={"outline"}
                  size={"sm"}
                />
                <Text fontSize={"sm"} color={"gray.300"} minW={10} fontWeight={"normal"}>
                  {currentStep + 1} of {tourSteps.length}
                </Text>
                <IconButton
                  aria-label={"Step forward"}
                  icon={<ArrowRight />}
                  onClick={handleNext}
                  isDisabled={currentStep === tourSteps.length - 1}
                  variant={"outline"}
                  size={"sm"}
                />
              </>
            )}
            {tutorial.completed ? (
              <Button onClick={handleModalClose} minW={"fit-content"} size={"sm"} variant={"ghost"}>
                Close
              </Button>
            ) : (
              <Button
                onClick={handleComplete}
                minW={"fit-content"}
                colorScheme={"green"}
                size={"sm"}
                isDisabled={currentStep !== tourSteps.length - 1}
              >
                Complete
              </Button>
            )}
          </HStack>
        </ModalHeader>
        <ModalBody pb={5}>{content}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TourModal;
