import { Box, Center, Image, Button, Link, VStack } from "@chakra-ui/react";
import { CheckCorrect, Login as SignIn } from "@icon-park/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import RivrLogo from "../assets/rivr-logo.svg";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Navigate } from "react-router-dom";

import "./login.css";
import { RivrLocations } from "../models/navigation";

const Login = () => {
  const { login, register, isAuthenticated } = useKindeAuth();

  if (isAuthenticated) {
    let redirect = RivrLocations.Search;
    const loginRedirect = localStorage.getItem("loginRedirect");
    if (loginRedirect !== null) redirect = loginRedirect;
    return <Navigate replace to={redirect} />;
  }

  return (
    <Box w={"100%"} h={"100%"} position={"fixed"}>
      <Center w={"100%"} h={"100%"}>
        <Box className={"login-box"} borderRadius={"md"}>
          <VStack bg={"gray.700"} px={8} pb={4} pt={8} borderRadius={"md"} spacing={12} w={"100%"}>
            <Image src={RivrLogo} alt={"Rivr Logo"} maxW={"200px"} w={"100%"} />
            <VStack w={"100%"}>
              <Button
                rightIcon={<SignIn />}
                className={"btn-sign-in"}
                onClick={login}
                w={"full"}
                backgroundColor={"rivr.red"}
                _hover={{
                  bg: "#E43A19CC",
                }}
              >
                Sign In
              </Button>
              <Button
                rightIcon={<CheckCorrect />}
                className={"btn-sign-up"}
                onClick={register}
                w={"full"}
              >
                Sign Up
              </Button>
            </VStack>
            <Link
              className={"lnk-terms"}
              fontSize={"xs"}
              color={"gray.400"}
              href={"https://rivr.stream/privacy-terms-of-service/"}
              isExternal
            >
              Terms & Privacy Policy
              <ExternalLinkIcon ml={1} />
            </Link>
          </VStack>
        </Box>
      </Center>
    </Box>
  );
};

export default Login;
