import { gql } from "@apollo/client";

// Mutations
export const ToggleMomentMutation = gql`
  mutation ToggleMoment($id: uuid!, $rejected: Boolean!) {
    update_recommended_moment_by_pk(pk_columns: { id: $id }, _set: { rejected: $rejected }) {
      id
    }
  }
`;

export const UpdateRecommendedMomentMutation = gql`
  mutation UpdateRecommendedMoment($id: uuid!, $object: recommended_moment_set_input!) {
    update_recommended_moment_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;

// Subscriptions
export const RecommendedMomentsBySearchIDSubscription = gql`
  subscription RecommendedMomentsBySearchID($id: Int!) {
    recommended_moment_stream(
      cursor: { initial_value: { created_at: "2023-01-01" }, ordering: ASC }
      batch_size: 20000
      where: { search_id: { _eq: $id } }
    ) {
      id
      tags
      title
      moment_time
      annotations
      rejected
      created_at
      moments {
        deleted
      }
    }
  }
`;
