import { useContext } from "react";
import {
  Badge,
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  VStack,
  HStack,
  Text,
  Progress,
  useDisclosure,
} from "@chakra-ui/react";
import { DownloadsContext } from "../../context/DownloadsContext";
import AnimatedDownloadIcon from "./AnimatedDownloadIcon";
import DownloadsDrawer from "./DownloadsDrawer";
import { DownloadIcon } from "@chakra-ui/icons";
import { AccountContext } from "../../context/AccountContext";
import * as amplitude from "@amplitude/analytics-browser";

const Downloader: React.FC = () => {
  const { account, memberships } = useContext(AccountContext);
  const { downloadDone, downloadTotal, downloadProgress, downloaderRequest } =
    useContext(DownloadsContext);
  const { isOpen, onToggle } = useDisclosure();

  const isOrgMember = memberships.length > 0;
  const downloadsRemaining = downloadTotal - downloadDone;

  const handleClick = () => {
    onToggle();
    amplitude.track("Downloads Panel Opened");
  };

  return (
    <>
      <Box position="relative">
        <Popover
          strategy="fixed"
          placement="bottom-end"
          trigger="hover"
          openDelay={0}
          closeDelay={0}
        >
          {downloadsRemaining > 0 && (
            <Badge
              colorScheme="red"
              borderRadius="full"
              position="absolute"
              top={-1.5}
              right={-1.5}
              fontSize="0.65em"
              minWidth={4}
              textAlign="center"
              zIndex={1}
              variant="solid"
              color="gray.50"
            >
              {downloadsRemaining}
            </Badge>
          )}
          <PopoverTrigger>
            <IconButton
              size="sm"
              aria-label="Downloads"
              icon={
                downloadsRemaining > 0 ? (
                  <AnimatedDownloadIcon isAnimating />
                ) : (
                  <DownloadIcon boxSize={4} />
                )
              }
              onClick={handleClick}
              cursor={"pointer"}
            />
          </PopoverTrigger>
          {downloadsRemaining > 0 && (
            <PopoverContent w="auto" shadow="lg">
              <PopoverBody maxW="fit-content">
                <VStack w="100%" pb={2} pt={1} align="end" spacing={1.5}>
                  <HStack justifyContent="space-between" spacing={8}>
                    <Text fontSize="md" fontWeight="medium">
                      Generating clips...
                    </Text>
                    <Text id="txtCounts" fontSize="md" fontWeight="medium">
                      {downloadDone}/{downloadTotal}
                    </Text>
                  </HStack>
                  <VStack w="100%" gap={-2} pos={"relative"}>
                    <Progress
                      w="100%"
                      size="sm"
                      borderRadius="full"
                      value={downloadProgress}
                      colorScheme="green"
                    />
                    <Progress
                      w="100%"
                      size="sm"
                      borderRadius="full"
                      isIndeterminate
                      colorScheme="green"
                      mt={-2}
                    />
                  </VStack>
                </VStack>
              </PopoverBody>
            </PopoverContent>
          )}
        </Popover>
      </Box>

      <DownloadsDrawer
        isOpen={isOpen}
        onClose={onToggle}
        accountId={account.id}
        downloaderRequest={downloaderRequest}
        isOrgMember={isOrgMember}
      />
    </>
  );
};

export default Downloader;
