export type OrganizationRole = "owner" | "member";

type SubscriptionStatus = "none" | "active" | "inactive";

export type RivrOrganization = {
  id: string;
  image: string | null;
  name: string;
  website: string | null;
  email: string | null;
  subscriptionStatus: SubscriptionStatus;
  subscriptionId: string | null;
};

export type OrganizationMembership = {
  organization: RivrOrganization;
  role: OrganizationRole;
};

export function orgFromQueryResult(organization: any): RivrOrganization {
  const result: RivrOrganization = {
    id: organization.id,
    image: organization.image,
    name: organization.name,
    website: organization.website,
    email: organization.email,
    subscriptionStatus: organization.subscription_status,
    subscriptionId: organization.subscription_id,
  };

  return result;
}
