import { Box, Text, Divider } from "@chakra-ui/react";

type CustomTooltipProps = {
  active?: boolean;
  payload?: any[];
  label?: string;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const totalMentions = payload.reduce((sum, dataItem) => {
      const value = dataItem.value ?? dataItem.payload?.value ?? 0;
      return sum + value;
    }, 0);

    return (
      <Box
        bg={"blackAlpha.800"}
        borderWidth={1}
        p={2}
        borderRadius={"md"}
        boxShadow={"2xl"}
        width={"max-content"}
      >
        {label && <Text m={0}>{label}</Text>}
        <Box>
          {payload
            .slice()
            .reverse()
            .map((dataItem: any, index: number) => {
              const name = dataItem.name ?? dataItem.payload?.name ?? "N/A";
              const value = dataItem.value ?? dataItem.payload?.value ?? 0;

              return (
                <Text key={index} color={dataItem.color} m={0}>
                  {name}: {value.toLocaleString()}
                </Text>
              );
            })}
          {payload.length > 1 && (
            <>
              <Divider borderColor={"whiteAlpha.700"} my={1} />
              <Text>Total: {totalMentions.toLocaleString()}</Text>
            </>
          )}
        </Box>
      </Box>
    );
  }
  return null;
};

export default CustomTooltip;
