import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  Area,
  Brush,
} from "recharts";
import { Box, Text, VStack } from "@chakra-ui/react";
import "./InsightsChart.css";

type InsightsChartVideoProps = {
  data: {
    date: string;
    videos: number;
    hours: number;
    views: number;
    channels: number;
  }[];
  syncId: string;
  formatDateLabel: (date: string) => string;
  CustomTooltip: React.FC<any>;
  barVisibility: {
    videos: boolean;
    channels: boolean;
    hours: boolean;
    views: boolean;
    hover: string | null;
  };
  setBarVisibility: React.Dispatch<
    React.SetStateAction<{
      videos: boolean;
      channels: boolean;
      hours: boolean;
      views: boolean;
      hover: string | null;
    }>
  >;
};

const InsightsChartVideo: React.FC<InsightsChartVideoProps> = ({
  data,
  syncId,
  formatDateLabel,
  CustomTooltip,
  barVisibility,
  setBarVisibility,
}) => {
  const handleLegendClick = (
    data: any,
    index: number,
    event: React.MouseEvent<Element, MouseEvent>
  ) => {
    const dataKey = data.dataKey;
    if (dataKey && ["videos", "channels", "hours", "views"].includes(dataKey)) {
      setBarVisibility((prev) => ({
        ...prev,
        [dataKey as keyof typeof barVisibility]: !prev[dataKey as keyof typeof barVisibility],
      }));
    }
  };

  const handleMouseEnter = (data: any, index: number) => {
    setBarVisibility((prev) => ({
      ...prev,
      hover: data.dataKey,
    }));
  };

  const handleMouseLeave = () => {
    setBarVisibility((prev) => ({
      ...prev,
      hover: null,
    }));
  };

  let yAxisLeftLabel = "";
  let yAxisLeftDy = 0;

  if (barVisibility.hours) {
    yAxisLeftLabel = "Video hours";
    yAxisLeftDy = 44;
  } else if (barVisibility.videos) {
    yAxisLeftLabel = "Videos";
    yAxisLeftDy = 28;
  } else {
    yAxisLeftLabel = "Channels";
    yAxisLeftDy = 36;
  }

  return (
    <VStack w={"100%"} h={"100%"} flexGrow={1} className={"stat-video"} align={"start"} spacing={4}>
      <VStack align={"start"} spacing={0}>
        <Text fontSize="lg">Campaign Summary</Text>
        <Text fontSize="sm" color="gray.300">
          Number of participating channels, videos, video hours, and views per day
        </Text>
      </VStack>

      <Box
        h={"100%"}
        w={"100%"}
        bg={"whiteAlpha.200"}
        borderRadius={"md"}
        p={4}
        borderWidth={1}
        minH={0}
      >
        <ResponsiveContainer width={"100%"} height={"100%"} minHeight={0}>
          <ComposedChart
            data={data}
            syncId={syncId}
            margin={{
              top: 10,
              left: 20,
              right: 20,
              bottom: 8,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#ffffff" strokeOpacity={0.2} />
            <XAxis
              dataKey="date"
              tick={{ fill: "#ffffff" }}
              tickFormatter={(date) => formatDateLabel(date)}
              interval={10}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              tick={{ fill: "#ffffff" }}
              allowDecimals={false}
              tickFormatter={(value) => value.toLocaleString()}
              label={{
                value: yAxisLeftLabel,
                angle: -90,
                position: "insideLeft",
                fill: "#ffffff",
                dx: -10,
                dy: yAxisLeftDy,
              }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tick={{ fill: "#ffffff" }}
              allowDecimals={false}
              tickFormatter={(value) =>
                value >= 1000000
                  ? `${(value / 1000000).toFixed(1)}m`
                  : value >= 1000
                  ? `${(value / 1000).toFixed(0)}k`
                  : value.toLocaleString()
              }
              label={{
                value: "Views",
                angle: 90,
                position: "insideRight",
                fill: "#ffffff",
                dx: 10,
                dy: 20,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              iconSize={12}
              onClick={handleLegendClick}
              wrapperStyle={{ cursor: "pointer" }}
              verticalAlign={"top"}
              height={32}
            />
            <defs>
              <linearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FC8181" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#FC8181" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Bar
              yAxisId="left"
              dataKey="channels"
              fill="#B794F4"
              name="Channels"
              stackId="1"
              hide={!barVisibility.channels}
              fillOpacity={Number(
                barVisibility.hover === "channels" || !barVisibility.hover ? 1 : 0.6
              )}
              onMouseEnter={(data, index) => handleMouseEnter(data, index)}
              onMouseLeave={handleMouseLeave}
            />
            <Bar
              yAxisId="left"
              dataKey="videos"
              fill="#68D391"
              name="Videos"
              stackId="b"
              hide={!barVisibility.videos}
              fillOpacity={Number(
                barVisibility.hover === "videos" || !barVisibility.hover ? 1 : 0.6
              )}
              onMouseEnter={(data, index) => handleMouseEnter(data, index)}
              onMouseLeave={handleMouseLeave}
            />
            <Bar
              yAxisId="left"
              dataKey="hours"
              fill="#F6AD55"
              name="Video hours"
              stackId="c"
              hide={!barVisibility.hours}
              fillOpacity={Number(
                barVisibility.hover === "hours" || !barVisibility.hover ? 1 : 0.6
              )}
              onMouseEnter={(data: any, index: number) => handleMouseEnter(data, index)}
              onMouseLeave={handleMouseLeave}
            />
            <Area
              yAxisId="right"
              type="monotone"
              dataKey="views"
              stroke="#FC8181"
              fill="url(#grad)"
              name="Views"
              strokeWidth={2}
              dot={true}
              activeDot={{ r: 8 }}
              hide={!barVisibility.views}
              fillOpacity={Number(
                barVisibility.hover === "views" || !barVisibility.hover ? 1 : 0.6
              )}
              onMouseLeave={handleMouseLeave}
            />
            <Brush
              travellerWidth={16}
              height={24}
              fill="rgba(255, 255, 255, 0.08)" // Dark background color for the brush area
              stroke="rgba(255, 255, 255, 0.24)" // Darker outline color for the brush
              tickFormatter={() => ""} // Removes the displayed values
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </VStack>
  );
};

export default InsightsChartVideo;
