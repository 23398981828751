import { useState, useEffect } from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import "./ChatPanel.css";
import { Message, parseChatFile, parseVTTFile } from "../../models/message";
import { FetchState, MessageList } from "./MessageList";
import { useSearchParams } from "react-router-dom";
import { ResultsURLParams } from "../../models/navigation";
import * as amplitude from "@amplitude/analytics-browser";

interface Props {
  videoInfo: any;
  playTime: number;
  seek: (seconds: number) => void;
  isCollapsed: boolean;
  isChatReady: boolean;
  isSpeechReady: boolean;
  onChatError: () => void;
  onSpeechError: () => void;
}

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const ChatPanel = ({
  videoInfo,
  playTime,
  seek,
  isCollapsed,
  isChatReady,
  isSpeechReady,
  onChatError,
  onSpeechError,
}: Props) => {
  const maxTries = 6;
  const retryDelay = 5000;

  const isSpeechAvailable = videoInfo && videoInfo.asr_transcript;

  const [searchParams] = useSearchParams();
  const selectedPanelTab = searchParams.get(ResultsURLParams.SelectedPanel);

  const [tabIndex, setTabIndex] = useState(selectedPanelTab === "speech" ? 1 : 0);
  const [chatMessages, setChatMessages] = useState<Message[]>([]);
  const [chatState, setChatState] = useState<FetchState>("loading");
  const [speechMessages, setSpeechMessages] = useState<Message[]>([]);
  const [speechState, setSpeechState] = useState<FetchState>("loading");

  const videoRoot = videoInfo
    ? `${process.env.REACT_APP_MEDIA_BUCKET}/${videoInfo.origin_type.toLowerCase()}/${
        videoInfo.video_id
      }`
    : "";

  const fetchChat = async () => {
    let tries = 0;
    while (tries < maxTries) {
      try {
        const url = `${videoRoot}/chat/chat.txt`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch chat");
        }
        const data = await response.text();
        const messages = parseChatFile(data);
        setChatMessages(messages);
        setChatState("success");
        return;
      } catch (error) {
        console.error(error);
      }
      ++tries;
      if (tries < maxTries) await sleep(retryDelay);
    }
    setChatState("error");
    onChatError();
  };

  useEffect(() => {
    if (isChatReady && videoRoot !== "") fetchChat();
  }, [isChatReady, videoRoot]);

  const fetchSpeech = async () => {
    let tries = 0;
    while (tries < maxTries) {
      try {
        const url = `${videoRoot}/asr_transcript/asr_transcript.vtt`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch speech");
        }
        const data = await response.text();
        const messages = parseVTTFile(data, "Speaker");
        setSpeechMessages(messages);
        setSpeechState("success");
        return;
      } catch (error) {
        console.error(error);
      }
      ++tries;
      if (tries < maxTries) await sleep(retryDelay);
    }
    setSpeechState("error");
    onSpeechError();
  };

  useEffect(() => {
    if (isSpeechReady && videoRoot !== "") fetchSpeech();
  }, [isSpeechReady, videoRoot]);

  useEffect(() => {
    if (selectedPanelTab) {
      if (selectedPanelTab === "speech" && isSpeechAvailable) setTabIndex(1);
    }
  }, [selectedPanelTab, isSpeechAvailable]);

  const handleAmplitudeTrack = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Tabs
      index={tabIndex}
      onChange={setTabIndex}
      w={"100%"}
      display={"flex"}
      flexDir={"column"}
      isFitted={true}
    >
      <TabList flexShrink={"0"}>
        <Tab
          fontWeight={"medium"}
          _hover={{ bg: "whiteAlpha.200" }}
          _selected={{ bg: "whiteAlpha.200", color: "blue.300", borderBottomColor: "blue.300" }}
          borderTopRadius={"md"}
          onClick={() => handleAmplitudeTrack("Chat Search Tab Clicked")}
        >
          Chat Search
        </Tab>
        {isSpeechAvailable && (
          <Tab
            fontWeight={"medium"}
            _hover={{ bg: "whiteAlpha.200" }}
            _selected={{ bg: "whiteAlpha.200", color: "blue.300", borderBottomColor: "blue.300" }}
            borderTopRadius={"md"}
            onClick={() => handleAmplitudeTrack("Speech Search Tab Clicked")}
          >
            Speech Search
          </Tab>
        )}
      </TabList>
      <TabPanels flexGrow={"1"} maxH={"calc(100% - 42px)"}>
        <TabPanel px={0} pt={4} pb={0} h={"100%"} position={"relative"}>
          <MessageList
            messages={chatMessages}
            fetchState={chatState}
            messageType="chat"
            playTime={playTime}
            seek={seek}
            isCollapsed={isCollapsed || tabIndex !== 0}
          />
        </TabPanel>
        {isSpeechAvailable && (
          <TabPanel px={0} pt={4} pb={0} h={"100%"} position={"relative"}>
            <MessageList
              messages={speechMessages}
              fetchState={speechState}
              messageType="speech"
              playTime={playTime}
              seek={seek}
              isCollapsed={isCollapsed || tabIndex !== 1}
            />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};
