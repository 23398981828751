import { Box } from "@chakra-ui/react";
import { Rnd } from "react-rnd";
import { ContentBox, Dimensions } from "../../models/action";
import { Ref } from "react";
import { AlignmentControls } from "./AlignmentControls";

type Props = {
  contentBox: ContentBox;
  setContentBox: (box: ContentBox) => void;
  previewDims: Dimensions;
  color: string;
  canvasRef: Ref<HTMLCanvasElement>;
  disabled: boolean;
};

export const PreviewSelector = (props: Props) => {
  const { contentBox, setContentBox, previewDims, color, canvasRef, disabled } = props;

  const handle = <Box bg={color} w="10px" h="10px" m="5px"></Box>;
  const resizeHandleComponent = disabled
    ? undefined
    : {
        bottomRight: handle,
      };

  return (
    <Rnd
      bounds="parent"
      lockAspectRatio={true}
      enableResizing={!disabled}
      disableDragging={disabled}
      size={{
        width: contentBox.width * previewDims.width,
        height: contentBox.height * previewDims.height,
      }}
      position={{
        x: contentBox.x * previewDims.width,
        y: contentBox.y * previewDims.height,
      }}
      onDragStop={(e, d) =>
        setContentBox({
          ...contentBox,
          x: d.x / previewDims.width,
          y: d.y / previewDims.height,
        })
      }
      onResize={(e, direction, ref, delta, position) => {
        setContentBox({
          width: ref.offsetWidth / previewDims.width,
          height: ref.offsetHeight / previewDims.height,
          x: position.x / previewDims.width,
          y: position.y / previewDims.height,
        });
      }}
      style={{
        border: `2px solid ${color}`,
      }}
      resizeHandleComponent={resizeHandleComponent}
      className={"selector"}
    >
      <canvas
        ref={canvasRef}
        width={contentBox.width * previewDims.width - 4}
        height={contentBox.height * previewDims.height - 4}
      ></canvas>
      {!disabled && <AlignmentControls contentBox={contentBox} setContentBox={setContentBox} />}
    </Rnd>
  );
};
