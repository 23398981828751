import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import knowledgebaseRoutes from "./utils/knowledgebase";
import mainRoutes from "./utils/main";
import "@fontsource/inter";
import ApolloWrapper from "./ApolloWrapper";
import { clarity } from "react-microsoft-clarity";

const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
  colors: {
    rivr: {
      100: "#586A7D",
      200: "#3A4754",
      300: "#28323B",
      400: "#1D242B",
      500: "#14191E",
      600: "#0F1317",
      red: "#E43A19",
    },
  },
  fonts: {
    heading: `'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;`,
    body: `'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;`,
  },
  breakpoints: {
    base: "0px",
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
    "3xl": "1900px",
    "4xl": "2400px",
  },
});

clarity.init(process.env.REACT_APP_MICROSOFT_CLARITY_PROJECT_ID);

function App() {
  localStorage.setItem("chakra-ui-color-mode", "dark");
  return (
    <KindeProvider
      clientId={process.env.REACT_APP_KINDE_CLIENT_ID}
      domain={process.env.REACT_APP_KINDE_DOMAIN}
      logoutUri={window.location.origin}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_KINDE_AUDIENCE}
      isDangerouslyUseLocalStorage={process.env.NODE_ENV === "development"}
    >
      <ChakraProvider theme={theme}>
        <ApolloWrapper>
          <Router>
            <Routes>
              {mainRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact} element={route.element} />
              ))}
              {knowledgebaseRoutes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
            </Routes>
          </Router>
        </ApolloWrapper>
      </ChakraProvider>
    </KindeProvider>
  );
}

export default App;
