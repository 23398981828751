// TourButton.tsx

import React, { useState, useEffect, useCallback } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  IconButton,
  Box,
  Button,
  Text,
  HStack,
} from "@chakra-ui/react";
import { CheckCircleIcon, QuestionIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import TourModal from "./TourModal";
import "./TourButton.css";
import { Round } from "@icon-park/react";
import { useTutorial } from "../../hooks/useTutorial";
import * as amplitude from "@amplitude/analytics-browser";
import { TourType } from "./TourContent";

const TourButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [tourType, setTourType] = useState<TourType>("intro");
  const [showToast, setShowToast] = useState(false);

  const location = useLocation();
  const currentPath = location.pathname;

  const introTutorial = useTutorial("intro");
  const syncTutorial = useTutorial("sync");
  const analyzeTutorial = useTutorial("analyze");
  const resultsTutorial = useTutorial("results");
  const chatTutorial = useTutorial("chat");
  const timelineTutorial = useTutorial("timeline");
  const momentsTutorial = useTutorial("moments");
  const featuresTutorial = useTutorial("features");
  const verticalTutorial = useTutorial("vertical");

  const searchTutorials = [introTutorial, syncTutorial, analyzeTutorial];
  const resultsTutorials = [
    resultsTutorial,
    chatTutorial,
    timelineTutorial,
    momentsTutorial,
    featuresTutorial,
    verticalTutorial,
  ];

  const searchTutorialsCompleted = searchTutorials.every((t) => t.completed);
  const resultsTutorialsCompleted = resultsTutorials.every((t) => t.completed);

  const handleOpenModal = useCallback((type: TourType) => {
    setTourType(type);
    setIsOpen(true);
    setShowToast(false);
    handleAmplitudeTrack("Tutorial Opened", { Tour: type as string });
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const isRelevantPath = currentPath === "/search" || currentPath.startsWith("/results/");

  useEffect(() => {
    if (
      (currentPath === "/search" && !searchTutorialsCompleted) ||
      (currentPath.startsWith("/results/") && !resultsTutorialsCompleted)
    ) {
      setShowToast(true);
    }
  }, [currentPath, searchTutorialsCompleted, resultsTutorialsCompleted]);

  const availableTutorials =
    currentPath === "/search"
      ? searchTutorials
      : currentPath.startsWith("/results/")
      ? resultsTutorials
      : [];

  const completedCount = availableTutorials.filter((tutorial) => tutorial.completed).length;

  const totalCount = availableTutorials.length;

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <Box>
      {isRelevantPath && (
        <>
          <HStack align={"center"} gap={1}>
            {showToast && (
              <Box
                background="yellow.200"
                px={4}
                py={2}
                borderRadius="full"
                boxShadow="lg"
                display="flex"
                alignItems="center"
              >
                <Text color="blackAlpha.900" fontSize={"sm"} fontWeight={"semibold"}>
                  New tutorial available
                </Text>
              </Box>
            )}

            <Popover placement="top-start">
              <PopoverTrigger>
                <IconButton
                  className={completedCount < totalCount ? "tour-button-pulse" : "tour-button"}
                  size="md"
                  colorScheme="yellow"
                  aria-label="Tutorial Menu"
                  icon={<QuestionIcon />}
                  borderRadius="full"
                  boxShadow="lg"
                  onClick={() => setShowToast(false)}
                />
              </PopoverTrigger>

              <PopoverContent w="min-content">
                <PopoverHeader fontWeight="bold">Tutorials</PopoverHeader>
                <PopoverBody px={0}>
                  {currentPath === "/search" && (
                    <>
                      <Button
                        justifyContent="start"
                        borderRadius={0}
                        onClick={() => handleOpenModal("intro")}
                        width="100%"
                        bg="transparent"
                        fontWeight="normal"
                      >
                        <HStack w="100%" justifyContent="space-between">
                          <Text mr={4}>Intro to Rivr</Text>
                          {introTutorial.completed ? (
                            <CheckCircleIcon color="green.200" />
                          ) : (
                            <Round strokeWidth={5} />
                          )}
                        </HStack>
                      </Button>
                      <Button
                        justifyContent="start"
                        borderRadius={0}
                        onClick={() => handleOpenModal("sync")}
                        width="100%"
                        bg="transparent"
                        fontWeight="normal"
                      >
                        <HStack w="100%" justifyContent="space-between">
                          <Text mr={4}>Account Syncing & Campaigns</Text>
                          {syncTutorial.completed ? (
                            <CheckCircleIcon color="green.200" />
                          ) : (
                            <Round strokeWidth={5} />
                          )}
                        </HStack>
                      </Button>
                      <Button
                        justifyContent="start"
                        borderRadius={0}
                        onClick={() => handleOpenModal("analyze")}
                        width="100%"
                        bg="transparent"
                        fontWeight="normal"
                      >
                        <HStack w="100%" justifyContent="space-between">
                          <Text mr={4}>Analyzing Videos</Text>
                          {analyzeTutorial.completed ? (
                            <CheckCircleIcon color="green.200" />
                          ) : (
                            <Round strokeWidth={5} />
                          )}
                        </HStack>
                      </Button>
                    </>
                  )}

                  {currentPath.startsWith("/results/") && (
                    <>
                      <Button
                        justifyContent="start"
                        borderRadius={0}
                        onClick={() => handleOpenModal("results")}
                        width="100%"
                        bg="transparent"
                        fontWeight="normal"
                      >
                        <HStack w="100%" justifyContent="space-between">
                          <Text mr={4}>Results Overview</Text>
                          {resultsTutorial.completed ? (
                            <CheckCircleIcon color="green.200" />
                          ) : (
                            <Round strokeWidth={5} />
                          )}
                        </HStack>
                      </Button>
                      <Button
                        justifyContent="start"
                        borderRadius={0}
                        onClick={() => handleOpenModal("chat")}
                        width="100%"
                        bg="transparent"
                        fontWeight="normal"
                      >
                        <HStack w="100%" justifyContent="space-between">
                          <Text mr={4}>Chat & Speech</Text>
                          {chatTutorial.completed ? (
                            <CheckCircleIcon color="green.200" />
                          ) : (
                            <Round strokeWidth={5} />
                          )}
                        </HStack>
                      </Button>
                      <Button
                        justifyContent="start"
                        borderRadius={0}
                        onClick={() => handleOpenModal("timeline")}
                        width="100%"
                        bg="transparent"
                        fontWeight="normal"
                      >
                        <HStack w="100%" justifyContent="space-between">
                          <Text mr={4}>The Timeline</Text>
                          {timelineTutorial.completed ? (
                            <CheckCircleIcon color="green.200" />
                          ) : (
                            <Round strokeWidth={5} />
                          )}
                        </HStack>
                      </Button>
                      <Button
                        justifyContent="start"
                        borderRadius={0}
                        onClick={() => handleOpenModal("moments")}
                        width="100%"
                        bg="transparent"
                        fontWeight="normal"
                      >
                        <HStack w="100%" justifyContent="space-between">
                          <Text mr={4}>Making Moments</Text>
                          {momentsTutorial.completed ? (
                            <CheckCircleIcon color="green.200" />
                          ) : (
                            <Round strokeWidth={5} />
                          )}
                        </HStack>
                      </Button>
                      <Button
                        justifyContent="start"
                        borderRadius={0}
                        onClick={() => handleOpenModal("features")}
                        width="100%"
                        bg="transparent"
                        fontWeight="normal"
                      >
                        <HStack w="100%" justifyContent="space-between">
                          <Text mr={4}>Features & Recommendations</Text>
                          {featuresTutorial.completed ? (
                            <CheckCircleIcon color="green.200" />
                          ) : (
                            <Round strokeWidth={5} />
                          )}
                        </HStack>
                      </Button>
                      <Button
                        justifyContent="start"
                        borderRadius={0}
                        onClick={() => handleOpenModal("vertical")}
                        width="100%"
                        bg="transparent"
                        fontWeight="normal"
                      >
                        <HStack w="100%" justifyContent="space-between">
                          <Text mr={4}>Vertical Clips</Text>
                          {verticalTutorial.completed ? (
                            <CheckCircleIcon color="green.200" />
                          ) : (
                            <Round strokeWidth={5} />
                          )}
                        </HStack>
                      </Button>
                    </>
                  )}
                </PopoverBody>
                <PopoverFooter>
                  <HStack w="100%" justifyContent="space-between" alignItems="center" px={1}>
                    <Text maxW="fit-content" color="gray.300" fontSize="sm">
                      {completedCount}/{totalCount} completed
                    </Text>
                    {completedCount === totalCount && <CheckCircleIcon color="green.200" />}
                  </HStack>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </HStack>

          <TourModal isOpen={isOpen} onClose={handleCloseModal} tourType={tourType} />
        </>
      )}
    </Box>
  );
};

export default React.memo(TourButton);
