import { useState, useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import { PlayerProps } from "../PlayerProps";

export const TwitchRivrPlayer = (props: PlayerProps) => {
  const { videoInfo, seekTime, pausePlayer, updatePlayTime } = props;
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isAPIReady, setIsAPIReady] = useState(false);
  const playerID = "twitch-player";
  const playerRef = useRef<any>();

  useEffect(() => {
    if (
      isPlayerReady &&
      seekTime &&
      seekTime.seconds >= 0 &&
      seekTime.seconds < playerRef.current.getDuration()
    )
      playerRef.current.seek(seekTime.seconds);
  }, [seekTime, isPlayerReady]);

  useEffect(() => {
    if (isPlayerReady && pausePlayer.pause) playerRef.current.pause();
  }, [pausePlayer, isPlayerReady]);

  useEffect(() => {
    if (isAPIReady && (videoInfo.videoId || videoInfo.videoType === "LIVE")) {
      const options: any = {
        width: "100%",
        height: "100%",
      };
      if (videoInfo.videoType === "VOD") options.video = videoInfo.videoId;
      else options.channel = videoInfo.url.split("/").slice(-1)[0];
      if (playerRef.current) {
        if (options.video) playerRef.current.setVideo(options.video);
        else playerRef.current.setChannel(options.channel);
      } else {
        playerRef.current = new (window as any).Twitch.Player(playerID, options);
        playerRef.current.addEventListener((window as any).Twitch.Player.READY, () => {
          setTimeout(() => setIsPlayerReady(true), 500);
        });
      }
    }
  }, [videoInfo, isAPIReady]);

  useEffect(() => {
    if (!isPlayerReady) return;

    const interval = setInterval(() => {
      updatePlayTime(playerRef.current.getCurrentTime());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [isPlayerReady]);

  useEffect(() => {
    const script = document.createElement("script");
    script.onload = () => {
      setIsAPIReady(true);
    };
    document.body.appendChild(script);
    script.async = true;
    script.src = "https://player.twitch.tv/js/embed/v1.js";
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <Box id={playerID} height={"100%"}></Box>;
};
