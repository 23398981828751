import { gql } from "@apollo/client";

// Queries
export const LatestOrgSubscriptionEventQuery = gql`
  query LatestOrgSubscriptionEvent($organization_id: uuid!) {
    subscription_event(
      where: {
        _and: {
          organization_id: { _eq: $organization_id }
          event_type: { _neq: "subscription_updated" }
        }
      }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      status
      ends_at
    }
  }
`;

export const LatestUserSubscriptionEventQuery = gql`
  query LatestUserSubscriptionEvent($account_id: uuid!) {
    subscription_event(
      where: {
        _and: { account_id: { _eq: $account_id }, event_type: { _neq: "subscription_updated" } }
      }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      status
      ends_at
    }
  }
`;

export const LatestOrgSubscriptionMetadataEventQuery = gql`
  query LatestOrgSubscriptionMetadataEvent($organization_id: uuid!) {
    subscription_event(
      where: {
        _and: {
          organization_id: { _eq: $organization_id }
          event_type: { _eq: "subscription_updated" }
        }
      }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      card_brand
      card_last_four
      renews_at
      product_id
      variant_id
    }
  }
`;

export const LatestUserSubscriptionMetadataEventQuery = gql`
  query LatestUserSubscriptionMetadataEvent($account_id: uuid!) {
    subscription_event(
      where: {
        _and: { account_id: { _eq: $account_id }, event_type: { _eq: "subscription_updated" } }
      }
      order_by: { updated_at: desc }
      limit: 1
    ) {
      card_brand
      card_last_four
      renews_at
      product_id
      variant_id
    }
  }
`;
