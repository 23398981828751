import { useEffect } from "react";
import { Box, AbsoluteCenter, Text, useToast } from "@chakra-ui/react";
import { useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { AccessTokenQuery } from "../api/kinde-oauth";
import { setConnectedApp, removeConnectedApp, getApps } from "../utils/connected-apps";

const GoogleDriveCallback = () => {
  const client = useApolloClient();
  const toast = useToast();
  const navigate = useNavigate();

  const showToast = (title, description, status) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 7000,
      isClosable: true,
    });
    navigate("/actions");
  };

  async function validateAuthSession() {
    const apps = getApps();
    const googleApp = apps.find((app) => app.kinde_key === "google_drive");
    const sessionId = localStorage.getItem("google_drive_session_id");

    if (!sessionId) {
      showToast("Error", "Your Session is expired.", "error");
      removeConnectedApp("google_drive");
      return;
    }

    const { data: googleDriveAccessToken } = await client.query({
      query: AccessTokenQuery,
      variables: {
        sessionId,
      },
    });

    if (!googleDriveAccessToken) {
      removeConnectedApp("google_drive");
      showToast("Error", "Your Session is expired.", "error");
    } else {
      setConnectedApp("google_drive", googleDriveAccessToken);
      if (window.opener) {
        window.opener.postMessage(googleApp.successConnectedMessage, window.location.origin);
        window.close();
      }
    }
  }

  useEffect(() => {
    validateAuthSession();
  }, []);

  return (
    <Box w="100%">
      <AbsoluteCenter>
        <Text fontSize="3xl" fontWeight={600}>
          Processing....
        </Text>
      </AbsoluteCenter>
    </Box>
  );
};
export default GoogleDriveCallback;
