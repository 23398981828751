import { Button, ButtonGroup } from "@chakra-ui/react";
import "../Campaigns.css";
import { useSearchParams } from "react-router-dom";
import { CampaignURLParams, updateURLParams } from "../../../models/navigation";
import { Endocrine, Message } from "@icon-park/react";
import * as amplitude from "@amplitude/analytics-browser";

const DiscoverViewSelect = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedDiscoverView = searchParams.get(CampaignURLParams.DiscoverSelectedView) || "moment";

  const handleDiscoverViewSelect = (view: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.DiscoverSelectedView, view]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: false });
    handleAmplitudeTrack("Discover View Selected", { SelectedView: view });
  };

  const handleAmplitudeTrack = (e: string, properties?: Record<string, any>) => {
    amplitude.track(e, properties);
  };

  return (
    <ButtonGroup isAttached size={"sm"} fontSize={"sm"}>
      <Button
        leftIcon={<Endocrine />}
        onClick={() => handleDiscoverViewSelect("moment")}
        isActive={selectedDiscoverView === "moment"}
        colorScheme={selectedDiscoverView === "moment" ? "blue" : "gray"}
        variant={selectedDiscoverView === "moment" ? "ghost" : "solid"}
        fontWeight={"normal"}
      >
        Moments
      </Button>
      <Button
        leftIcon={<Message />}
        onClick={() => handleDiscoverViewSelect("mention")}
        isActive={selectedDiscoverView === "mention"}
        colorScheme={selectedDiscoverView === "mention" ? "blue" : "gray"}
        variant={selectedDiscoverView === "mention" ? "ghost" : "solid"}
        fontWeight={"normal"}
      >
        Mentions
      </Button>
    </ButtonGroup>
  );
};

export default DiscoverViewSelect;
