import { Stat, StatNumber, HStack, StatHelpText, Divider } from "@chakra-ui/react";

interface KeyStatsProps {
  keysInOrg: number;
  keysInCampaign: number;
  keysClaimed: number;
  keysUnclaimed: number;
  campaignSelected: boolean;
}

const KeyStats: React.FC<KeyStatsProps> = ({
  keysInOrg,
  keysInCampaign,
  keysClaimed,
  keysUnclaimed,
  campaignSelected,
}) => {
  return (
    <HStack align={"start"} alignItems={"center"} justify={"start"}>
      <Stat
        bg={"gray.700"}
        px={3}
        py={2}
        pb={1}
        borderRadius={"md"}
        borderWidth={1}
        minW={"max-content"}
      >
        <StatNumber fontSize={"lg"}>{keysInOrg} keys</StatNumber>
        <StatHelpText fontSize={"xs"}>In organization</StatHelpText>
      </Stat>
      {campaignSelected && (
        <>
          <Divider orientation="vertical" borderWidth={1} h={16} />
          <Stat
            bg={"gray.700"}
            px={3}
            py={2}
            pb={1}
            borderRadius={"md"}
            borderWidth={1}
            minW={"max-content"}
          >
            <StatNumber fontSize={"lg"}>{keysInCampaign} keys</StatNumber>
            <StatHelpText fontSize={"xs"}>In campaign</StatHelpText>
          </Stat>
          <Stat
            bg={"gray.700"}
            px={3}
            py={2}
            pb={1}
            borderRadius={"md"}
            borderWidth={1}
            minW={"max-content"}
          >
            <StatNumber fontSize={"lg"}>{keysClaimed} claimed</StatNumber>
            <StatHelpText fontSize={"xs"}>In campaign</StatHelpText>
          </Stat>
          <Stat
            bg={"gray.700"}
            px={3}
            py={2}
            pb={1}
            borderRadius={"md"}
            borderWidth={1}
            minW={"max-content"}
          >
            <StatNumber fontSize={"lg"}>{keysUnclaimed} unclaimed</StatNumber>
            <StatHelpText fontSize={"xs"}>In campaign</StatHelpText>
          </Stat>
        </>
      )}
    </HStack>
  );
};

export default KeyStats;
