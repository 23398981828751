import { gql } from "@apollo/client";

// Queries
// Get all mention searches
export const MentionsSearchesQuery = gql`
  query MentionsSearches($organization_id: uuid!) {
    mentions_search(
      where: { organization_id: { _eq: $organization_id }, deleted_at: { _is_null: true } }
    ) {
      title
      search
      created_at
      deleted_at
      updated_at
      id
      organization_id
    }
  }
`;

// Mutations
// Insert new mentions search
export const CreateMentionsSearchMutation = gql`
  mutation CreateMentionsSearch(
    $organization_id: uuid!
    $title: String!
    $search: String!
    $created_at: timestamptz!
  ) {
    insert_mentions_search_one(
      object: {
        organization_id: $organization_id
        title: $title
        search: $search
        created_at: $created_at
        updated_at: $created_at
      }
    ) {
      id
    }
  }
`;

// Rename a mentions search
export const RenameMentionsSearchMutation = gql`
  mutation RenameMentionsSearch($id: uuid!, $title: String!, $updated_at: timestamptz!) {
    update_mentions_search(
      where: { id: { _eq: $id } }
      _set: { title: $title, updated_at: $updated_at }
    ) {
      returning {
        id
        title
      }
    }
  }
`;

// Set mentions search deleted at to desired state
export const SetMentionsSearchDeleteStatusMutation = gql`
  mutation SetMentionsSearchDeleteStatus(
    $id: uuid!
    $deleted_at: timestamptz!
    $updated_at: timestamptz!
  ) {
    update_mentions_search(
      where: { id: { _eq: $id } }
      _set: { deleted_at: $deleted_at, updated_at: $updated_at }
    ) {
      returning {
        id
        deleted_at
      }
    }
  }
`;

// Update a mentions search
export const UpdateMentionsSearchMutation = gql`
  mutation UpdateMentionsSearch($id: uuid!, $search: String!, $updated_at: timestamptz!) {
    update_mentions_search(
      where: { id: { _eq: $id } }
      _set: { search: $search, updated_at: $updated_at }
    ) {
      returning {
        id
        title
      }
    }
  }
`;
