import { gql } from "@apollo/client";

// Subscriptions
// Get Signals By SearchID Subscription
export const SignalsBySearchIDSubscription = gql`
  subscription SignalsBySearchID($id: Int!) {
    signals_stream(
      cursor: { initial_value: { id: 1 }, ordering: ASC }
      batch_size: 20000
      where: { search_id: { _eq: $id } }
    ) {
      from_seconds
      x_values
      y_values
      title
    }
  }
`;
