import { Flex, IconButton, Tooltip, VStack } from "@chakra-ui/react";
import { RepeatIcon, AddIcon } from "@chakra-ui/icons";
import { Square } from "@icon-park/react";
import { MediaPlayer } from "../MediaPlayer/MediaPlayer";
import { Timeline } from "../Timeline/Timeline";
import { Moment, RecommendedMoment } from "../../models/moment";
import { v4 as uuidv4 } from "uuid";
import { SelectedMoment } from "../../models/selectedMoment";
import Cookies from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";
import TimelineColorSchemer from "../Timeline/TimelineColorSchemer";
import { durationToSeconds } from "../../utils/time";

interface Props {
  videoInfo: any;
  stopSearch: () => void;
  isLoopingLoading: boolean;
  moments: Moment[];
  recommendedMoments: RecommendedMoment[];
  modifiedRecommendedMoments: RecommendedMoment[];
  videoID: string;
  handleFocusedChange: (selectedMoment: SelectedMoment | null) => void;
  selectedMoment: Pick<Moment, "id" | "start_time" | "end_time"> | null;
  updateSelectedMoment: (update: Pick<Moment, "start_time" | "end_time">) => void;
  addMoment: (moment: Moment) => void;
  defaultMomentDuration: number;
  pausePlayer: { pause: boolean };
  playTime: number;
  setPlayTime: (playTime: number) => void;
  seekTime: { seconds: number } | null;
  timelineZoom: { start: number; end: number } | null;
  graphSeekCallback: (seconds: number) => void;
  looping: boolean;
  setLooping: (looping: boolean) => void;
  signalLoading: boolean;
  signalError: boolean;
  signalData: Map<string, { x: Date[]; y: number[] }>;
}

export const MediaPanel = ({
  videoInfo,
  stopSearch,
  isLoopingLoading,
  moments,
  recommendedMoments,
  modifiedRecommendedMoments,
  videoID,
  handleFocusedChange,
  selectedMoment,
  updateSelectedMoment,
  addMoment,
  defaultMomentDuration,
  pausePlayer,
  playTime,
  setPlayTime,
  seekTime,
  timelineZoom,
  graphSeekCallback,
  looping,
  setLooping,
  signalLoading,
  signalError,
  signalData,
}: Props) => {
  const isAdmin = Cookies.get("xHasuraRole") === "admin";

  const createDefaultMoment = () => {
    let startTime = Math.max(0, Math.round(playTime - defaultMomentDuration / 2));
    let endTime = Math.min(
      startTime + defaultMomentDuration,
      durationToSeconds(videoInfo.video_duration)
    );
    const now = new Date();
    if (selectedMoment && selectedMoment.id === "temp") {
      startTime = selectedMoment.start_time;
      endTime = selectedMoment.end_time;
    }
    const moment = {
      title: "New Moment",
      tags: [],
      id: uuidv4(),
      search_id: Number(videoID),
      start_time: startTime,
      end_time: endTime,
      created_at: now,
      updated_at: now,
      deleted: false,
      annotations: {},
    };
    addMoment(moment);
  };

  const handleAmplitudeTrack = (e: string) => {
    amplitude.track(e);
  };

  const handleNewMoment = () => {
    createDefaultMoment();
    handleAmplitudeTrack("Moment Manually Created");
  };

  return (
    <VStack className={"player"} w={"100%"} h={"100%"} pl={4} alignItems={"stretch"} spacing={1}>
      <MediaPlayer
        seekTime={seekTime}
        pausePlayer={pausePlayer}
        videoInfo={videoInfo}
        updatePlayTime={setPlayTime}
      />
      <Flex
        id={"timeline-container"}
        background={"gray.700"}
        p={3}
        gap={3}
        borderRadius={"md"}
        borderWidth={1}
      >
        <VStack h={"100%"} w={"fit-content"} justifyContent={"space-between"}>
          <VStack>
            <Tooltip label={"Add Moment"} placement={"right"}>
              <IconButton
                aria-label={"Add Moment"}
                onClick={handleNewMoment}
                colorScheme={"green"}
                variant={"solid"}
                size={"sm"}
                icon={<AddIcon />}
                className={"btn-timeline-add-moment"}
                disabled={!videoInfo || videoInfo.status !== "stopped"}
              />
            </Tooltip>
            <Tooltip label={"Loop playback"} placement={"right"}>
              <IconButton
                aria-label={"Loop playback"}
                onClick={() => {
                  setLooping(!looping);
                  handleAmplitudeTrack(looping ? "Looping Disabled" : "Looping Enabled");
                }}
                colorScheme={looping ? "purple" : "gray"}
                variant={"solid"}
                size={"sm"}
                icon={<RepeatIcon />}
                className={"btn-timeline-loop-playback"}
              />
            </Tooltip>
            <TimelineColorSchemer signalData={signalData} />
          </VStack>
          {isAdmin && (
            <Tooltip label={"Stop analyzing"} placement={"right"}>
              <IconButton
                aria-label={"Stop analyzing"}
                mt={1}
                colorScheme={"gray"}
                variant={"solid"}
                size={"sm"}
                onClick={stopSearch}
                isLoading={isLoopingLoading}
                disabled={!videoInfo || videoInfo.status !== "in-progress"}
                icon={<Square theme={"filled"} />}
                className={"btn-timeline-stop-search"}
              />
            </Tooltip>
          )}
        </VStack>
        <Flex width={"100%"} borderRadius={"md"} background={"blackAlpha.900"}>
          <Timeline
            moments={moments}
            recommendedMoments={recommendedMoments}
            modifiedRecommendedMoments={modifiedRecommendedMoments}
            graphSeekCallback={graphSeekCallback}
            videoInfo={videoInfo}
            focusedCallback={handleFocusedChange}
            selectedMoment={selectedMoment}
            updateSelectedMoment={updateSelectedMoment}
            playTime={playTime}
            timelineZoom={timelineZoom}
            signalLoading={signalLoading}
            signalError={signalError}
            signalData={signalData}
            looping={looping}
          />
        </Flex>
      </Flex>
    </VStack>
  );
};
