import { PeopleMinusOne, PeoplePlusOne } from "@icon-park/react";
import MessageMinus from "../assets/message-minus-icon";
import MessagePlus from "../assets/message-plus-icon";

export type MentionType = "Speech" | "Chat";

export type RivrMention = {
  rank: number;
  channel: string;
  date: string;
  timestamp: number;
  type: MentionType;
  text: string;
  vod: string;
  searchId: number;
  url: string;
  sentiment: number | null;
  featRank?: number;
};

export function speakerMentionFromQueryResult(mention: any): RivrMention {
  return {
    rank: mention.rank,
    channel: mention.search[0].user_name,
    date: mention.search[0].published_at,
    timestamp: mention.start_time,
    type: "Speech",
    text: mention.line,
    vod: mention.search[0].video_title,
    searchId: mention.search[0].id,
    url: mention.search[0].url,
    sentiment: mention.sentiment,
  };
}

export function getMaxRank(mentions: RivrMention[]): number {
  return mentions.reduce((max, mention) => Math.max(max, mention.rank), 0);
}

export const mentionDisplaySettings: Record<string, any> = {
  "Chat positive": {
    title: "Chat positivity",
    color: "#48BB78",
    colorScheme: "green",
    icon: <MessagePlus />,
    featureThreshold: 0.4,
  },
  "Chat negative": {
    title: "Chat negativity",
    color: "#F56565",
    colorScheme: "red",
    icon: <MessageMinus />,
    featureThreshold: 0.4,
  },
  "ASR positive": {
    title: "Speaker positivity",
    color: "#48BB78",
    colorScheme: "green",
    icon: <PeoplePlusOne size={16} />,
    featureThreshold: 0.4,
  },
  "ASR negative": {
    title: "Speaker negativity",
    color: "#F56565",
    colorScheme: "red",
    icon: <PeopleMinusOne size={16} />,
    featureThreshold: 0.4,
  },
};

export const scoreAnnotations: Record<string, number> = {
  "Search rank": 1.0,
  Sentiment: 0.5,
};
