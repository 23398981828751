import { Text, VStack } from "@chakra-ui/react";
import KbButton from "../KnowledgeBase/KbButton";
import { Plus } from "@icon-park/react";

export interface TourStep {
  type: "video";
  content: string;
  videoHeadline: string;
  videoText: any;
}

export type TourType =
  | "intro"
  | "sync"
  | "analyze"
  | "results"
  | "chat"
  | "timeline"
  | "moments"
  | "features"
  | "vertical";

export const IntroTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/0LQgYWKPTMQ?autoplay=1",
    videoHeadline: "Intro to Rivr",
    videoText: (
      <VStack align={"start"} gap={4}>
        <Text>
          Welcome to Rivr! This video will show you how to use Rivr to find highlight content,
          search your chat, make vertical clips, and download clips for social sharing.
        </Text>
        <Text>
          If you want to read up for yourself, check out our Knowledge Base located in the top right
          corner of your screen. You can also join our public Discord to ask questions, meet the
          team, and show off the clips you've made using Rivr.
        </Text>
      </VStack>
    ),
  },
];

export const SyncTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/aotLkGw4wAc?autoplay=1",
    videoHeadline: "Account Syncing & Campaigns",
    videoText: (
      <VStack align={"start"} gap={4}>
        <Text>
          First, you'll want to connect your Twitch account to Rivr to see the latest videos from
          your stream. You can do this on the Search page as well as from your Account Settings.
        </Text>
        <Text>
          As a new user, you'll be able to try out Rivr with a few videos. When you're ready to
          subscribe, you'll unlock Rivr's full capabilities so you can easily find, create, and
          share clips from all of your streams.
        </Text>
        <Text>
          If you've been given a key to Rivr through a creator program or campaign, all you need to
          do is visit your account settings, sync your Twitch account, and enter in the key.
          Depending on the campaign you are a part of, you may not be able to manually add videos.
          Instead, relevant stream VODs will automatically be analyzed for you.
        </Text>
      </VStack>
    ),
  },
];

export const AnalyzeTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/R_ZTzvRLAOU?autoplay=1",
    videoHeadline: "Analyzing Videos",
    videoText: (
      <VStack align={"start"} gap={4}>
        <Text>
          Rivr currently supports publicly accessible Twitch VODs. To ensure your Twitch video will
          work, it cannot be sub-only and needs to be marked as published. Additional platforms will
          be supported in the future.
        </Text>
        <Text>
          Please note that in order to get the best experience out of Rivr, you should use it as
          soon as your stream is over, before the VOD is erased from Twitch.
        </Text>
        <Text>
          To analyze a video, click the Analyze button under any available video in the Ready to
          Analyze section. The video will jump to the front and display its progress. When it's
          ready for you to review, it'll move to the Discover section.
        </Text>
      </VStack>
    ),
  },
];

export const ResultsTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/fWhyfIOPyMY?autoplay=1",
    videoHeadline: "Results Overview",
    videoText: (
      <VStack align={"start"} gap={4}>
        <Text>
          Now that your video is done analyzing, let's jump in to really see what Rivr is all about.
        </Text>
        <Text>
          There are a few different sections to your results page. In the center you have your video
          replay, below that is your timeline that is used to visualize your stream in the form of
          signals. On the right side you have your chat replay and speech transcription. On the left
          are where your Moments live.
        </Text>
      </VStack>
    ),
  },
];

export const ChatTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/PfqLo9YXsGU?autoplay=1",
    videoHeadline: "Chat & Speech",
    videoText: (
      <VStack align={"start"} gap={4}>
        <Text>
          Your chat replay shows the chat messages that were received during the stream. You can
          search the chat for any text, usernames, LOL to find your funny moments, you can even
          create a command phrase such as "clip it" that your community can type into chat for you
          to search and find those exact moments you wanted to come back to. Click on any line of
          text to jump to that exact point in the video.
        </Text>
        <Text>Some users also have access to speech search.</Text>
      </VStack>
    ),
  },
];

export const TimelineTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/uzuTtXS-aEw?autoplay=1",
    videoHeadline: "The Timeline",
    videoText: (
      <VStack align={"start"} gap={4}>
        <Text>Let's examine the Rivr timeline.</Text>
        <Text>
          The Timeline controls video playback in a similar way to scrubbing timelines found in most
          editing software. What makes the Rivr Timeline unique is the addition of Signal data,
          which offers an innovative new way to understand video.
        </Text>
        <Text>
          The audio intensity signal represents changes in your video's audio levels. The chat
          intensity signal reflects highly interactive moments with your community. The chat
          positivity and negativity signals represent your chat's reactions. Similarly, the Speaker
          Sentiment signal indicates when you are speaking positively or negatively. Finally, there
          is the Hype signal. It is used to help identify your stream's most exciting moments.
        </Text>
        <Text>
          You can zoom in and out and pan across the timeline to look for these signal spikes.
        </Text>
      </VStack>
    ),
  },
];

export const MomentsTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/hpw4ecWWGh0?autoplay=1",
    videoHeadline: "Making Moments",
    videoText: (
      <VStack align={"start"} gap={4}>
        <Text>
          Clicking on the timeline will jump to that point. Once you've found something you want to
          make into a clip, click the green{" "}
          <KbButton isIconButton={true} icon={<Plus size={16} />} colorScheme={"green"} /> button. A
          visual overlay will appear to make a Moment. This is one of the core aspects of Rivr,
          allowing you to quickly make Moments that you can download and share on social media.
        </Text>
        <Text>
          Drag the edges or center of the Moment window to adjust its length or position, allowing
          you to fine-tune the start and end points. Turning on Loop playback will allow you to
          rewatch the last few seconds of your Moment to make those adjustments.
        </Text>
        <Text>
          When you're happy with it, give it a title and add some tags. You can edit any Moment
          you've made by simply clicking on the Moment card, displaying the Moment window on the
          timeline.
        </Text>
        <Text>You can download, reformat to vertical, or delete any Moment you've created.</Text>
      </VStack>
    ),
  },
];

export const FeaturesTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/T2-w6a-eC2w?autoplay=1",
    videoHeadline: "Moment Features & Recommended Moments",
    videoText: (
      <VStack align={"start"} gap={4}>
        <Text>
          When a Moment has significant signal activity, such as hype or chat positivity, we
          highlight these features on the Moment card. This gives you insight into what makes your
          Moment stand out. Use the Features buttons to organize your view, as features are key in
          reviewing your Recommended Moments.
        </Text>
        <Text>
          Rivr will recommend moments for you. This can be a handy starting point for locating
          points of interest across your video. You can see the standout features of each moment.
          Click on a recommended moment to watch it. If you like it, accept the recommendation by
          clicking the checkmark and it will move to the My Moments tab. Once it's there, you can
          rename it, tag it with keywords, adjust and download it.
        </Text>
        <Text>
          Rejecting a recommended moment will send it to the bottom of the list. If you change your
          mind, you can restore it.
        </Text>
        <Text>
          Recommended moments that you modify by adjusting the position of the Moment window will
          have an indicator and appear at the top of your recommendations. This is to remind you
          that the moment has deviated from an official recommendation that was discovered for you.
          You can always restore a modified recommended moment to its original state.
        </Text>
      </VStack>
    ),
  },
];

export const VerticalTour: TourStep[] = [
  {
    type: "video",
    content: "https://www.youtube.com/embed/_Brptd_avnE?autoplay=1",
    videoHeadline: "Vertical Clip Editor",
    videoText: (
      <VStack align={"start"} gap={4}>
        <Text>
          Click the reformat to vertical button on a moment to open our vertical clip editor. This
          is a simple editor designed to get your clips ready for vertical posting quickly.
        </Text>
        <Text>
          We have three templates: Full gives you a single layer that fills the entire vertical
          space Split has two layers with different size presets for the facecam. Freeform lets you
          completely adjust two layers in whatever sizes you want.
        </Text>
        <Text>
          In the vertical preview you can also resize each layer and customize the alignment. Rivr
          will automatically add a blur to the background of any empty space for that professional
          feel. Click the safe zone box to visualize the area that may be obscured by popular social
          platforms.
        </Text>
        <Text>
          When you're ready, click Finish to download the clip. You can redownload any clips you've
          made by clicking the download button in the top right corner next to the settings menu.
        </Text>
      </VStack>
    ),
  },
];
