import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from "@chakra-ui/react";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import { RepeatIcon } from "@chakra-ui/icons";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import { ColorFilter, Endocrine, Message } from "@icon-park/react";
import { Text, Link, UnorderedList, ListItem } from "../../components/KnowledgeBase/KbElements";

const KbTimeline: React.FC = () => {
  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"results-timeline"} py={2}>
          Timeline
        </LinkedHeading>
        <Text>
          The Timeline controls video playback in a similar way to scrubbing timelines found in most
          editing software. What sets the Rivr Timeline apart is the addition of Signals, which
          offer an innovative way to understand video.
        </Text>

        <LinkedHeading as={"h2"} id={"results-timeline-signals"} pt={6} pb={4}>
          Signals
        </LinkedHeading>
        <LinkedHeading as={"h3"} id={"results-timeline-signals-understanding"} pb={3}>
          Understanding Signals
        </LinkedHeading>
        <Text>
          Rivr breaks videos down into what we call Signals. Signals are raw data points related to
          your video, each representing different information that can help you identify Moments of
          interest or understand how and when your community responds to different events.
        </Text>
        <Text>
          Signals can have a value range of -1 to 1. Some Signals can only be positive, such as{" "}
          <Link href={"#results-timeline-signals-hype"}>Hype</Link> and{" "}
          <Link href={"#results-timeline-signals-chat-positivity"}>Chat Positivity</Link>. Others
          can be either positive or negative, such as{" "}
          <Link href={"#results-timeline-signals-audio-intensity"}>Audio Intensity</Link>, and some
          can only be negative, such as{" "}
          <Link href={"#results-timeline-signals-chat-negativity"}>Chat Negativity</Link>.{" "}
        </Text>
        <Text>
          While individual Signals can provide useful insights, it’s the alignment and intersection
          of multiple Signals that will help you discover great Moments in your videos.
        </Text>

        <LinkedHeading as={"h3"} id={"results-timeline-signals-hype"} pt={6} pb={3}>
          Hype
        </LinkedHeading>
        <Text>
          The Hype Signal identifies your most exciting Moments. It combines various pieces of
          information to guide your attention to key sections of your video. A spike in Hype data,
          especially when aligned with other Signals, generally indicates a spot worth reviewing.
        </Text>

        <LinkedHeading as={"h3"} id={"results-timeline-signals-audio-intensity"} pt={6} pb={3}>
          Audio Intensity
        </LinkedHeading>
        <Text>
          The Audio Intensity Signal reflects changes in your video’s audio levels. It can highlight
          the flow of conversation as well as sections of high or low action. Spikes in Audio
          Intensity might indicate Moments of interest, while dips could suggest a lull in the
          video. When combined with other Signals, it can quickly identify important Moments.
        </Text>

        <LinkedHeading as={"h3"} id={"results-timeline-signals-chat-positivity"} pt={6} pb={3}>
          Chat Positivity
        </LinkedHeading>
        <Text>
          The Chat Positivity Signal measures how positive the messages are in your chat. If your
          video doesn’t have embedded chat, this Signal will not be available on your Timeline.
        </Text>
        <LinkedHeading as={"h3"} id={"results-timeline-signals-chat-negativity"} pt={6} pb={3}>
          Chat Negativity
        </LinkedHeading>
        <Text>
          The Chat Negativity Signal measures how negative the messages are in your chat. If your
          video doesn’t have embedded chat, this Signal will not be available on your Timeline.
        </Text>

        <LinkedHeading as={"h3"} id={"results-timeline-signals-chat-intensity"} pt={6} pb={3}>
          Chat Intensity
        </LinkedHeading>
        <Text>
          The Chat Intensity Signal assesses the number of chat messages or emotes occurring within
          a short timeframe. If your video doesn’t have embedded chat, this Signal will not be
          available on your Timeline.
        </Text>
        <LinkedHeading as={"h3"} id={"results-timeline-signals-speaker-sentiment"} pt={6} pb={3}>
          Speaker Sentiment
        </LinkedHeading>
        <Text>
          The Speaker Sentiment Signal measures how positive or negative the main speaker in your
          video is being.
        </Text>
        <Alert status={"warning"} borderRadius={4} mb={4}>
          <AlertIcon />
          <AlertDescription>
            Rivr does not currently support seperating multiple speakers. Any dialog that is present
            in the video is subject to transcription and sentiment analysis. e.g. In-game dialog and
            dialog from watching content in your videos.
          </AlertDescription>
        </Alert>

        <LinkedHeading as={"h3"} id={"results-timeline-signals-colors"} pt={6} pb={3}>
          Changing Signal colors
        </LinkedHeading>
        <Text>
          By clicking the color scheme{" "}
          <KbButton isIconButton={true} icon={<ColorFilter />} colorScheme={"gray"} /> button, you
          can change the color scheme of the Signals in the Timeline. This may help those who
          experience color blindness or simply want a different look and feel.
        </Text>

        <Alert status={"info"} borderRadius={4} mb={4}>
          <AlertIcon />
          <AlertTitle>Tip:</AlertTitle>
          <AlertDescription>
            Try toggling Signals on and off to see how and where they interact with each other. You
            can do this by clicking on their titles.
          </AlertDescription>
        </Alert>

        <LinkedHeading as={"h2"} id={"results-timeline-controls"} pt={6} pb={4}>
          Controlling the Timeline
        </LinkedHeading>
        <LinkedHeading as={"h3"} id={"results-timeline-controls-bar"} pb={3}>
          Control bar
        </LinkedHeading>
        <Text>At the top of the Timeline is the control bar, offering several options:</Text>
        <UnorderedList spacing={2} pl={6} pb={4}>
          <ListItem>Zoom Select mode: Click and drag the Timeline to zoom.</ListItem>
          <ListItem>Panning mode: Click and drag the Timeline to pan left or right.</ListItem>
          <ListItem>Zoom In: Click this button to zoom in on the Timeline.</ListItem>
          <ListItem>Zoom Out: Click this button to zoom out on the Timeline.</ListItem>
          <ListItem>
            Zoom to Fit: Click this button to zoom the Timeline to fit all of the Signals in the
            available space.
          </ListItem>
        </UnorderedList>
        <LinkedHeading as={"h3"} id={"results-timeline-controls-pz"} pt={6} pb={3}>
          Panning and zooming
        </LinkedHeading>
        <Text>
          Panning mode is selected by default, allowing you to click and drag to move the Timeline
          left or right. To zoom in or out, you can either enable Zoom Select mode in the Control
          Bar or scroll up or down while hovering over the Timeline.
        </Text>
        <LinkedHeading as={"h3"} id={"results-timeline-legend"} pt={6} pb={3}>
          Signal legend
        </LinkedHeading>
        <Text>
          The Signal legend on the right side of the Timeline lists your video’s available Signals.
          Clicking any Signal title will toggle it on or off. Double-clicking a Signal title will
          turn all other Signals off, isolating the one you've selected.
        </Text>
        <LinkedHeading as={"h3"} id={"results-timeline-controls-playmarker"} pt={6} pb={3}>
          Play marker
        </LinkedHeading>
        <Text>
          Clicking anywhere on the Timeline moves the play marker to that position. When the Moment
          region is visible, you can click within it to move the play marker as well.
        </Text>
        <Text>
          You can also move the play marker by seeking to a new position in the media player.
          However, if playback looping is enabled, moving outside the Moment region will snap the
          play marker back to the beginning of the Moment region.
        </Text>
        <LinkedHeading as={"h3"} id={"results-timeline-controls-looping"} pt={6} pb={3}>
          Playback looping
        </LinkedHeading>
        <Text>
          Playback looping is disabled by default. When the play marker reaches the end of the
          Moment region, it will loop back to the beginning. To disable this, click the loop{" "}
          <KbButton isIconButton={true} icon={<RepeatIcon />} colorScheme={"purple"} /> button in
          the top-left corner of the Timeline.
        </Text>
        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Dec. 11, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "Moments",
              description: "How to analyze your VODs for your best Moments",
              icon: Endocrine,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Moments}`,
            },
            {
              title: "Chat & Speech",
              description: "How to search chat and speech transcripts",
              icon: Message,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.ChatSpeech}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbTimeline;
