import { Heading, Box, Text, useClipboard, HeadingProps, useToast } from "@chakra-ui/react";
import { useEffect } from "react";

interface LinkedHeadingProps extends HeadingProps {
  id: string;
  as?: "h1" | "h2" | "h3" | "h4"; // Limit the `as` prop to specific heading levels
}

const LinkedHeading: React.FC<LinkedHeadingProps> = ({ id, children, as = "h2", ...props }) => {
  const fullUrl = `${window.location.href.split("#")[0]}#${id}`;
  const { onCopy } = useClipboard(fullUrl);
  const toast = useToast();

  const handleClick = () => {
    onCopy();
    toast({
      description: "Link copied.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  // Set font sizes based on the heading level
  const fontSize = {
    h1: "4xl",
    h2: "2xl",
    h3: "xl",
    h4: "lg",
  }[as];

  // Scroll to the element if the URL hash matches the id
  useEffect(() => {
    if (window.location.hash === `#${id}`) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [id]);

  return (
    <Box
      position={"relative"}
      display={"block"}
      onClick={handleClick}
      cursor={"pointer"}
      role={"group"}
      mt={2}
      maxW={"fit-content"}
    >
      <Heading as={as} fontSize={fontSize} id={id} display={"inline-block"} {...props}>
        {children}
      </Heading>
      <Text
        as={"span"}
        ml={2}
        opacity={"0"}
        color={"gray.400"}
        _groupHover={{ opacity: "1" }}
        fontSize={fontSize}
        transition={"opacity 0.2s"}
      >
        #
      </Text>
    </Box>
  );
};

export default LinkedHeading;
