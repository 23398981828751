import {
  Box,
  VStack,
  Heading,
  Text,
  HStack,
  Divider,
  Flex,
  Thead,
  Table,
  Tbody,
  TableContainer,
  Tr,
  Th,
  useBreakpointValue,
  Td,
  Alert,
  AlertIcon,
  AlertDescription,
  FormControl,
  FormLabel,
  Input,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { OrganizationMembersByIdQuery } from "../../../api/organization";
import { Loading } from "../../Loading/Loading";
import { MoreOne } from "@icon-park/react";

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

type Props = {
  orgId: string;
  orgMemberLimit?: number;
};

export const OrgTeam = (props: Props) => {
  const { orgId } = props;

  const orgMemberLimit = 2;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const { data, loading } = useQuery(OrganizationMembersByIdQuery, {
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
    variables: { id: orgId },
    context: {
      role: "org-owner",
    },
  });

  const renderTableHeader = () => (
    <Tr>
      <Th cursor={"default"}>Member</Th>
      {!isMobile && (
        <>
          <Th cursor={"default"}>Email</Th>
          <Th cursor={"default"}>Role</Th>
        </>
      )}
    </Tr>
  );

  const renderTableRow = (member: any, index: number) => (
    <Tr key={index}>
      <Td>
        <VStack align="start" spacing={1}>
          <Text>{member.account.name}</Text>
          {isMobile && (
            <>
              <Text fontSize="sm">Email: {member.account.email}</Text>
              <Text fontSize="sm">Role: {capitalize(member.role)}</Text>
            </>
          )}
        </VStack>
      </Td>
      {isMobile && (
        <Td isNumeric>
          <IconButton
            size={"sm"}
            variant={"ghost"}
            aria-label={"Team member options"}
            icon={<MoreOne size={24} />}
            isDisabled
          />
        </Td>
      )}
      {!isMobile && (
        <>
          <Td>{member.account.email}</Td>
          <Td>{capitalize(member.role)}</Td>
          <Td isNumeric>
            <IconButton
              size={"sm"}
              variant={"ghost"}
              aria-label={"Team member options"}
              icon={<MoreOne size={24} />}
              isDisabled
            />
          </Td>
        </>
      )}
    </Tr>
  );

  const sortOrgMembers = (a: any, b: any) => {
    // Sorts org members by role, then by name
    const rolePriority: Record<string, number> = {
      owner: 1,
      member: 2,
    };
    if (a.role === b.role) {
      // Sort empty names to the end
      if (!a.account.name) return 1;
      else if (!b.account.name) return -1;
      else return a.account.name.localeCompare(b.account.name);
    } else return rolePriority[a.role] - rolePriority[b.role];
  };

  return (
    <Box w={"100%"} height={"100%"} alignItems={"flex-start"}>
      <VStack alignItems={"flex-start"} spacing={12} pt={6}>
        <HStack
          alignItems={"center"}
          w={"100%"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
          rowGap={6}
          spacing={0}
        >
          <VStack alignItems={"flex-start"}>
            <Heading as={"h1"} fontSize={"3xl"}>
              Team
            </Heading>
            <Text color={"gray.300"}>
              View your organization's members and assign seats and roles.
            </Text>
          </VStack>
          <Alert status={"warning"} borderRadius={"md"} w={"fit-content"}>
            <AlertIcon />
            <AlertDescription>
              This feature is currently in development. To add, remove, or change the roles of
              organization members, please contact your account manager.
            </AlertDescription>
          </Alert>
        </HStack>
        <Divider />
        <HStack
          w={"100%"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          flexWrap={"wrap"}
          spacing={0}
          rowGap={6}
          opacity={"0.66"}
          cursor={"not-allowed"}
        >
          <VStack alignItems={"flex-start"} spacing={1}>
            <Text fontSize={"lg"}>Invite a team member</Text>
            <Text color={"gray.300"} fontSize={"sm"}>
              Send an invite to a new team member.
            </Text>
          </VStack>
          <HStack align={"end"} justifyContent={"end"} flexWrap={"wrap"}>
            <FormControl w={"auto"} isRequired>
              <FormLabel>Name</FormLabel>
              <Input disabled w={80} type={"text"} />
            </FormControl>
            <FormControl w={"auto"} isRequired>
              <FormLabel>Email</FormLabel>
              <Input disabled w={80} type={"email"} />
            </FormControl>
            <Button disabled minW={"fit-content"}>
              Invite
            </Button>
          </HStack>
        </HStack>

        <Divider />

        <Flex w={"100%"} justify={"center"}>
          {loading ? (
            <Loading />
          ) : (
            <TableContainer w="100%">
              <VStack alignItems={"flex-start"} spacing={1} pb={6}>
                <Text fontSize={"lg"}>Your team members</Text>
                <Text color={"gray.300"} fontSize={"sm"}>
                  There are currently {data.organization[0].members.length}{" "}
                  {data.organization[0].members.length === 1 ? "member" : "members"} on your team.
                  You have {orgMemberLimit - data.organization[0].members.length} seats remaining.
                </Text>
              </VStack>

              <Table size="sm" variant="striped">
                <Thead>{renderTableHeader()}</Thead>
                <Tbody>
                  {data.organization[0].members
                    .sort(sortOrgMembers)
                    .map((member: any, index: number) => renderTableRow(member, index))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Flex>
      </VStack>
    </Box>
  );
};
