import { useState } from "react";
import { useMutation } from "@apollo/client";
import { InsertAccessKeyMutation } from "../../api/access-key";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  FormHelperText,
  Select,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";

interface KeyGeneratorProps {
  campaigns: { id: string; title: string }[];
  selectedOrganization: string;
  onKeysGenerated: () => void; // Add new prop
}

const KeyGenerator: React.FC<KeyGeneratorProps> = ({
  campaigns,
  selectedOrganization,
  onKeysGenerated,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [keyName, setKeyName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [activeDuration, setActiveDuration] = useState(1);
  const [keyCount, setKeyCount] = useState(1);
  const [loading, setLoading] = useState(false); // Manage loading state

  const [insertAccessKey] = useMutation(InsertAccessKeyMutation);
  const toast = useToast();

  const validateKeyName = (name: string) => /^[a-zA-Z0-9:-\s]*$/.test(name);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateKeyName(keyName)) {
      toast({
        title: "Invalid key name",
        description: "The key name can only contain letters, numbers, dashes, colons, and spaces.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!campaignId) {
      toast({
        title: "Invalid campaign_id",
        description: "Please select a campaign ID.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const activeDurationString = `${activeDuration} days`;

    setLoading(true); // Start loading

    try {
      for (let i = 0; i < keyCount; i++) {
        const generatedAccessKey = uuidv4();
        await insertAccessKey({
          variables: {
            key_name: keyName,
            campaign_id: campaignId,
            active_duration: activeDurationString,
            access_key: generatedAccessKey,
          },
        });
      }
      toast({
        title: "Keys generated",
        description: `${keyCount} campaign keys have been successfully generated.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onKeysGenerated(); // Call the function to reload table
      onClose();
    } catch (err) {
      toast({
        title: "An error occurred",
        description: "Unable to generate campaign keys.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <>
      <Button
        size={"sm"}
        onClick={onOpen}
        colorScheme="green"
        minW={"fit-content"}
        isDisabled={!selectedOrganization}
      >
        Generate Keys
      </Button>
      <Modal isOpen={isOpen} onClose={() => !loading && onClose()} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate campaigns keys</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <FormControl mb={4} isRequired>
                <FormLabel>Key name</FormLabel>
                <Input
                  className={"amp-unmask"}
                  type="text"
                  value={keyName}
                  onChange={(e) => setKeyName(e.target.value)}
                />
                <FormHelperText>Can have spaces, colon, dashes, letters and numbers</FormHelperText>
              </FormControl>
              <FormControl mb={4} isRequired>
                <FormLabel>Campaign</FormLabel>
                <Select
                  placeholder="Select a campaign"
                  value={campaignId}
                  onChange={(e) => setCampaignId(e.target.value)}
                >
                  {campaigns.map((campaign) => (
                    <option key={campaign.id} value={campaign.id}>
                      {campaign.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mb={4} isRequired>
                <FormLabel>Active duration (days)</FormLabel>
                <NumberInput
                  allowMouseWheel
                  value={activeDuration}
                  onChange={(value) => setActiveDuration(parseInt(value))}
                  min={1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
              <FormControl mb={4} isRequired>
                <FormLabel>Number of keys</FormLabel>
                <NumberInput
                  allowMouseWheel
                  value={keyCount}
                  onChange={(value) => setKeyCount(parseInt(value))}
                  min={1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button variant="ghost" onClick={onClose} isDisabled={loading}>
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={handleSubmit}
                isLoading={loading}
                isDisabled={!selectedOrganization}
              >
                Generate
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default KeyGenerator;
