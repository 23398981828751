import { useState, useEffect } from "react";
import { VStack, HStack, Text, Box, Flex } from "@chakra-ui/react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip, Label } from "recharts";
import { RivrMention, mentionDisplaySettings } from "../../../../../models/mention";
import CustomTooltip from "./CustomTooltip";

type Sentiment = "Positive" | "Negative" | "Neutral";

interface MentionDataItem {
  name: Sentiment;
  value: number;
}

interface MentionsSummaryProps {
  mentions: RivrMention[];
}

const COLORS: Record<Sentiment, string> = {
  Positive: "#48BB78",
  Negative: "#F56565",
  Neutral: "#4299e1",
};

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: {
  cx?: number;
  cy?: number;
  midAngle?: number;
  innerRadius?: number;
  outerRadius?: number;
  percent?: number;
}) => {
  if (
    cx === undefined ||
    cy === undefined ||
    midAngle === undefined ||
    innerRadius === undefined ||
    outerRadius === undefined ||
    percent === undefined
  ) {
    return null;
  }

  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent === 0) return null;

  return (
    <text
      x={x}
      y={y}
      fill={"#FFFFFF"}
      textAnchor={"middle"}
      dominantBaseline={"central"}
      fontSize={12}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const MentionsSummary: React.FC<MentionsSummaryProps> = ({ mentions }) => {
  const [displayData, setDisplayData] = useState<MentionDataItem[]>([]);
  const [visibility, setVisibility] = useState<Record<Sentiment, boolean>>({
    Positive: true,
    Negative: true,
    Neutral: true,
  });

  useEffect(() => {
    const sentimentData: Record<Sentiment, number> = {
      Positive: 0,
      Negative: 0,
      Neutral: 0,
    };

    // Get the thresholds from mentionDisplaySettings
    const positiveThreshold = mentionDisplaySettings["ASR positive"].featureThreshold;
    const negativeThreshold = -mentionDisplaySettings["ASR negative"].featureThreshold;

    mentions.forEach((mention) => {
      const sentiment = mention.sentiment;
      if (sentiment !== null && sentiment !== undefined) {
        if (sentiment >= positiveThreshold) {
          sentimentData.Positive++;
        } else if (sentiment <= negativeThreshold) {
          sentimentData.Negative++;
        } else {
          sentimentData.Neutral++;
        }
      } else {
        sentimentData.Neutral++;
      }
    });

    setDisplayData([
      { name: "Positive", value: sentimentData.Positive },
      { name: "Neutral", value: sentimentData.Neutral },
      { name: "Negative", value: sentimentData.Negative },
    ]);
  }, [mentions]);

  const handleLegendClick = (entry: MentionDataItem) => {
    const sentiment = entry.name;
    setVisibility((prev) => ({
      ...prev,
      [sentiment]: !prev[sentiment],
    }));
  };

  const preparedData = displayData
    .map((item) => ({
      ...item,
      value: visibility[item.name] ? item.value : 0,
    }))
    .filter((item) => item.value > 0);

  const totalVisibleCount = displayData.reduce((sum, item) => {
    return visibility[item.name] ? sum + item.value : sum;
  }, 0);

  const renderLegend = (props: any) => {
    return (
      <ul
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {displayData.map((entry, index) => {
          const isActive = visibility[entry.name];
          return (
            <li
              key={`item-${index}`}
              onClick={() => handleLegendClick(entry)}
              style={{
                marginRight: 20,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                opacity: isActive ? 1 : 0.5,
                fontSize: "0.8rem",
                color: COLORS[entry.name],
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  marginRight: 8,
                  width: 12,
                  height: 12,
                  backgroundColor: COLORS[entry.name],
                }}
              ></span>
              <span>{`${entry.name} (${entry.value.toLocaleString()})`}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderCenterLabel = () => {
    return (
      <text
        x={"50%"}
        y={"46%"}
        textAnchor={"middle"}
        dominantBaseline={"middle"}
        fontSize={"2rem"}
        fill={"#fff"}
        fontWeight={"semibold"}
      >
        {totalVisibleCount.toLocaleString()}
      </text>
    );
  };

  return (
    <VStack>
      <HStack w={"100%"} justifyContent={"center"} spacing={4}>
        <Box w={"100%"} h={"399px"} alignSelf={"center"}>
          {mentions.length === 0 ? ( // Check for empty state using mentions.length
            <Flex align={"center"} justify={"center"} color={"gray.300"} p={8} h={"100%"}>
              <Text>No mentions found for the provided keywords</Text>
            </Flex>
          ) : (
            <VStack spacing={1} h={"100%"} justifyContent={"center"}>
              <Text fontSize={"xl"} textAlign={"center"}>
                Speaker Mentions
              </Text>
              <ResponsiveContainer width={"100%"} height={330}>
                <PieChart>
                  <Pie
                    isAnimationActive={false}
                    data={preparedData}
                    cx={"50%"}
                    cy={"48%"}
                    innerRadius={80}
                    outerRadius={146}
                    dataKey={"value"}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    startAngle={90}
                    endAngle={-270}
                    stroke={"none"}
                  >
                    {preparedData.map((entry, idx) => (
                      <Cell key={`cell-${idx}`} fill={COLORS[entry.name]} />
                    ))}
                    <Label content={renderCenterLabel} position={"center"} />
                  </Pie>
                  <Legend
                    verticalAlign={"bottom"}
                    align={"center"}
                    height={16}
                    content={renderLegend}
                  />
                  <Tooltip content={<CustomTooltip />} />
                </PieChart>
              </ResponsiveContainer>
            </VStack>
          )}
        </Box>
      </HStack>
    </VStack>
  );
};

export default MentionsSummary;
